h1 {
    font-weight: @bold;
    font-size: 1.8em;
    margin: 0 0 1em 0;
    line-height: 1.44em;
}

h2 {
    font-weight: @bold;
    font-size: 1.4em;
    margin: 0 0 1em 0;
}

h3 {
    font-weight: @bold;
    font-size: 1.1em;
    line-height: 1.44em;
    margin: 0 0 1em 0;
}

h4 {
    font-weight: @normal;
    font-size: 1.1em;
    line-height: 1.44em;
    margin: 0 0 1em 0;
}

.medium-title {
    font-size: 2em !important;
    font-weight: @bold;
    color: @dark-blue;

    strong {
        font-weight: @light;
        display: block;
    }
}