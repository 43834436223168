.main-header {
    padding: 10px 25px;
    //position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 59px;

    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

    position: fixed;
    background-color: #fff;

    .common-squared-icon {
        height: 39px;
    }

    + .main {
        margin-top: 59px;
    }

    .common-dropdown {
        li:not(.dropdown-item) {
            background-color: #fff;
        }
    }

    &.wallet-header, &.warranties-header {
        background-image: linear-gradient(to bottom, #071b4f, #06143a);

        .profile-wrapper {
            height: 100%;
        }

        .brand-wrapper {

            width: auto;
            height: 100%;

            padding-left: 0;
            margin-top: 0;

            // temporary
            // background: white;

            img, svg {
                max-width: 100px;
                margin-top: 5px;
            }
        }

        .common-squared-icon {
            border:1px solid rgba(255, 255, 255, 0.2);
            height: 100%;

            color: white;

            svg {
                &:first-child {
                    path {
                        &:nth-child(1) {
                            fill: none;
                        }
                        &:nth-child(2) {
                            fill: none;
                            stroke: white;
                        }
                        &:nth-child(3) {
                            stroke: white;
                        }
                    }
                }
            }
        }
    }

    .brand-wrapper {
        width: calc(100% - 255px);
        padding-left: 250px;
        margin-top: 0px;
    }

    .profile-wrapper {
        float: right;

        .common-squared-icon {
            margin-right: 5px;
        }

        .common-squared-icon, .common-dropdown {
            display: inline-block;
            vertical-align: top;
        }

    }

    .burger-nav {
        position: absolute;

        display: none;

        right: 25px;
        top: 20px;

        width: 25px;
        height: 25px;

        span {
            position: absolute;
            width: 100%;
            height: 1px;

            right: 0;

            background-color: @light-grey;
            transition: all 0.25s cubic-bezier(.645, .045, .355, 1);

            &:nth-child(2) {
                width: 70%;
                top: 7px;
            }
            &:nth-child(3) {
                width: 40%;
                top: 14px;
            }
        }
    }

    .powered-by {
        display: none;
    }

    &.active {
        position: fixed;
        overflow: hidden;
        height: 100vh;
        //background-color: rgb(251, 252, 251);
        background-color: #fff;
        padding-top: 5px;

        .profile-wrapper, .powered-by {
            display: block;
            .common-appearance;
        }

        .powered-by {
            bottom: 15px;
        }

        .common-squared-icon {
            display: none;
        }

        .common-dropdown {
            display: block !important;
            box-shadow: none;

        }

        .burger-nav {
            span {
                &:first-child {
                    transform: translate3d(0,6px,0) rotate(45deg);
                    width: 70%;
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    transform: translate3d(0,-8px,0) rotate(-45deg);
                    width: 70%;
                }
            }
        }
    }

    .header-title {
        position: absolute;

        left: 50%;
        //top: 10px;
        top: 0;

        transform: translate3d(-50%,0,0);

        font-weight: @normal;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 1.6em;
        color: white;

        img {
            width: 40px;
            top: -2px;
            opacity: 0.2;
        }

        @media only screen and (max-width : 850px) {
            left: 35%;
        }
        @media only screen and (max-width : 665px) {
            left: 50%;
            font-size: 1.3em;
            top: 15px;

            img {
                top: -7px;
            }
        }
        @media only screen and (max-width : 450px) {
            left: 50%;
        }
        @media only screen and (max-width : 370px) {
            display: none;
        }
    }

    @media only screen and (max-width : 665px) {
        padding: 9px 20px;

        .profile-wrapper .common-dropdown {
            width: calc(100% - 36px) !important;
        }

        .brand-wrapper {
            margin-top: 0;
            width: 100%;
            padding-left: 0;
        }

        .burger-nav {
            display: block;
        }

        .profile-wrapper {
            //float: none;
            //position: relative;

            width: 100%;
            //top: 10%;

            top: 8px;
            position: absolute;

            .common-squared-icon {
                position: absolute;
                //top:-31px;
                top: 0;
                left: 5px;

                .notification-overlay {
                    left: -15px;
                    width: 300px;

                    padding: 20px 10px;
                }
            }

            .common-dropdown {
                display: none;
                width: 100%;

                .dropdown-item {

                    span {
                        max-width: 100%;
                        strong {
                            max-width: 100%;
                        }
                    }

                    +.dropdown-item {
                        display: block;
                    }

                    &:first-child {
                        border: none;
                        margin-bottom: 20px;

                        &:before, &:after {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        border-top: solid 1px rgba(7, 27, 79, 0.2);
                        border-top-left-radius: 2px;
                        border-top-right-radius: 2px;
                    }
                    &:last-child {
                        box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
                    }
                }
            }
        }

        &.wallet-header {
            .brand-wrapper {
                display: none;
            }
            .profile-wrapper {
                top: 0;

                .common-squared-icon {
                    position: relative;
                    top: 0;
                    left: 0;

                    svg {
                        max-width: 90%;
                        margin-top: -3px;
                    }

                    .notification-indicator {
                        bottom: 2px;
                    }

                    &.toggle-overlay {
                        padding: 5px 3px 5px 5px;
                    }

                    &.common-goods-nav {
                        span {
                            margin-top: -3px;
                        }
                        .goods-amount {
                            margin-top: -2px;
                        }
                    }

                    .notification-overlay {
                        left: -120px;
                        padding: 0;
                    }
                }
            }

            .common-dropdown {
                margin-top: 100px;

                .dropdown-item {
                    +.dropdown-item {
                        background-color: white;
                    }
                }
            }

            .powered-by {
                display: none;
            }
        }
    }
}

.brand-wrapper {
    display: inline-block;
    vertical-align: middle;

    text-align: center;
    font-weight: @bold;
    font-size: 1.3em;

    img {
        max-width: 150px;
    }

    .certificate-management {
        display: block;
        font-size: 0.6em;
        color: #000000;
        font-weight: @normal;
    }
}

.header-absolute-item {
    position: absolute;
    margin-top: 3px;

    * {
        vertical-align: bottom;
    }

    span {
        font-weight: @bold;
        color: @dark-blue;
        margin-left: 5px;
    }

    @media only screen and (max-width : 660px) {
        display: none;
    }
}
.search-wrapper {
    display: flex;
    .search-in-wrapper {
        width: 180px;
        height: 40px;
        margin-right: 10px;
        font-size: 14px;
        color: #071b4f;
        animation-delay: 0.2s;
        -webkit-animation: appear 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        animation: appear 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        .custom-select  {
            height: 40px;
        }
        .select-items {
            margin-top: 1px;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        }
        .select-selected, .select-items div {
            line-height: 40px;
            //border: #071b4f 1px solid;
            border: solid 1px rgba(7, 27, 79, 0.2);
            padding-left: 15px;
        }
        .select-selected {
            border: #071b4f 1px solid;
            color: rgb(51, 51, 51);
            &:after {
                content: "›";
                position: absolute;
                top: 1px;
                right: 12px;
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg) scaleY(0.9);
                font-size: 27px;
            }
        }
        .select-items div {
            width: 100%;
            color: rgb(51, 51, 51);
            border-top: 0;
            &:first-child {
                border-top: 1px solid #071b4f;
            }
            &:hover {
                background-color: #cdd1dc;
                color: rgb(51, 51, 51);
            }
        }
    }
    .common-input {
        width: calc(100% - 180px);
    }
    a {
        background-color: #071b4f;
    }
}
@media only screen and (max-width: 600px){
    .search-wrapper {
        display: block;
        .search-in-wrapper, .common-input {
            width: 100%;
        }
        .search-in-wrapper {
            margin-bottom: 10px;
        }
    }
}
@media only screen and (max-width: 570px) {
    .common-wrapper {
        width: 100% !important;
        padding-left: 10px;
        padding-right: 10px;
    }
}





