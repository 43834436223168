.common-warning {
    padding: 10px 0 25px 0;
    border-radius: 2px;
    
    .warning-title {
        font-weight: @bold;
        color: @red;
    }
    
    p {
        margin-bottom: 0;
        margin-top: 0.5em;
        color: @red;
    }
    
    &.blue {
        border: solid 1px @dark-blue;
        padding: 15px;
        
        .warning-title {
            color: @dark-blue;
        }
        p {
            color: @dark-blue;
        }
    }
    
    &.red {
        border: solid 1px @red;
        text-align: center !important;
        padding: 20px 50px;
        
        .warning-title {
            color: @red !important;
            text-transform: uppercase;
            margin-top: 0;
        }
        p {
            color: @red !important;
        }
    }
}