.button-third {
    appearance: none;
    outline: none;
    border: none;

    padding: 15px 25px;

    background-color: none;
    color: @grey;
    font-weight: @normal;
    line-height: 1.29em;
    text-decoration: underline;

    cursor: pointer;

    &:hover {
        color: darken(@grey, 10%);
    }
    &:active {
        color: darken(@grey, 30%);
    }
}