.common-dropdown {
    transition: all 0.25s ease;
    transition-delay: 0.2s;
    border-radius: 2px;
    .dropdown-item {
        position: relative;
        display: block;
        padding: 4px 10px;
        min-width: 170px;
        border:solid 1px @border-color;
        
        +.dropdown-item {
            // When not active
            display: none;
            
            border-top: none;
            padding: 7px 10px;
        }        
        
        &:first-child {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            
            &:before, &:after {
                content: '';
                
                position: absolute;
                
                top:50%;
                right: 15px;
                
                width: 10px;
                height: 2px;
                
                background-color: #d4d7df;
                border-radius: 2px;
                
                transform:translate3d(0,-50%,0) rotate(45deg);
            }
            
            &:after {
                transform:translate3d(6px,-50%,0) rotate(-45deg);
            }
        }
        &:last-child {
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
        }
        
        .common-icon, span {
            display: inline-block;
            vertical-align: middle;
        }
        
        .common-icon {
            svg, img {
                width: 100%;
                height: 100%;
            }

            &.big {
                svg {
                    stroke-width: 3;
                }
            }
        }
        
        span {
            padding-left: 10px;
            max-width: 166px;
            white-space: nowrap;
            font-size: 0.9em;

            &.big {
                font-weight: @bold;
            }

            &.red {
                color: @red;
            }
            
            strong {
                display: block;
                font-size: 0.8em;
                font-weight: @medium;
                max-width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2em;
            }
        }
        
        transition: background 0.25s ease;
        cursor: pointer;
        &:hover, &.hovered {
            background-color: @light-grey;
            
            &:first-child {
                &:before, &:after {
                    background-color: white;
                }
            }
        }

    }

    // Active state
    &.active {
        box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
        border-radius: 2px;
        .dropdown-item {
            +.dropdown-item {
                display: block;
                animation: dropdown-item-appear 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            }
            
            &:first-child {
                &:before, &:after {
                    transform:translate3d(0,0,0) rotate(-45deg);
                }
                
                &:after {
                    transform:translate3d(6px,0,0) rotate(45deg);
                }
            }
        }
    }
}

// Wallet common dropdown specification

.wallet-header {
    .common-dropdown {
        &.active {
            background: white;
            transition: background 0s;
            
            .dropdown-item {
                &:first-child {
                    background-color: white; 
                }
            }
        }
        
        &:not(.active) {
            .dropdown-item {
                border:1px solid rgba(255, 255, 255, 0.2);
                
                &:first-child {
                    color: white;
                    
                    &:hover {
                        background-color: fade(@grey,20%);
                    }
                    
                    svg {
                        path {
                            stroke: white;
                            fill: none;
                            
                            &:first-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes dropdown-item-appear {
    0%{
        opacity: 0;
        transform: scaleY(0.9) translate3d(0,-30px,0);
    }
    100%{
        opacity: 1;
        transform: scaleY(1) translate3d(0,0px,0);
    }
}

.campaign-menu {
    .dropdown-item {
        color: @dark-blue;
        &:hover {
            background-color: #f4f2e9;
        }
    }   
}