.common-down-arrow {
    &:before, &:after {
        content: '';
        
        position: absolute;
        
        top:50%;
        right: 0;
        
        width: 10px;
        height: 2px;
        
        background-color: @dark-blue;
        border-radius: 2px;
        
        transform:translate3d(0,-50%,0) rotate(45deg);
    }
    
    &:after {
        transform:translate3d(6px,-50%,0) rotate(-45deg);
    }
    
    &.active {
        &:before, &:after {
            transform:translate3d(0,0,0) rotate(-45deg);
        }
        
        &:after {
            transform:translate3d(6px,0,0) rotate(45deg);
        }
    }
}