.wallet-information-list {
    position: relative;
    padding-left: 35px;
    text-align: left;

    &.no-padding {
        padding-left: 0;

    }
    li {
        width: 100%;
        color: @dark-blue;
        
        +li {
            margin-top: 10px;
        }
        
        i {
            display: inline-block;
            vertical-align: middle;
            width: 150px;
            text-align: right;
            font-style: normal;
            font-size: 0.9em;
        }
        strong {
            position: absolute;
            margin-left: 15px;
            margin-top: 1px;
        }

        &:last-child {
            margin-bottom: 15px;
        }
    }
}