.warranties-nav {
	margin-bottom: 28px;
	padding-top: 33px;
}
.status-wrapper {
	font-size: 18px;
	color: @black;
	display: inline-block;
	margin: 20px 36px 20px 0;
	font-weight: bold;
	&.status-open {
		.status-count {
			color: #fff;
			background-color: @dark-blue;
		}
	}
	&.status-closed {
		opacity: .6;
		.status-count {
			background-color: #fff;
		}
	}
	.status-count {
		border-radius: 9px;
	    padding: 12px 23px;
    	margin-left: 11px;
	}
}
.warranties-frame {
	border-radius: 2px;
    background-color: #ffffff;
    margin-bottom: 11px;
    /*box-shadow: 0 0 20px 0 #e7e1cd;*/
    border: 1px solid #EEEEEE;
    -webkit-box-shadow: 0px 43px 30px 0px rgba(0,0,0,0.09);
	-moz-box-shadow: 0px 43px 30px 0px rgba(0,0,0,0.09);
	box-shadow: 0px 43px 30px 0px rgba(0,0,0,0.09);
}
.frame-main-link {
	display: block;
	transition: all .2s;
	position: relative;
	&.visited {
		section {
			opacity: .5;
		}
	}
	article, section {
		transition: all .2s;
	}
	&:hover {
		z-index: 1;
		transform: translateY(-10px);
		article {
			transform: scale(1.02);
		}
		section {
			transform: scale(.9803921568627);
		}
		.warranties-frame {
    		/*box-shadow: 0 10px 30px 0 #e7e1cd;*/
    		-webkit-box-shadow: 0px 43px 30px 0px rgba(0,0,0,0.09);
			-moz-box-shadow: 0px 43px 30px 0px rgba(0,0,0,0.09);
			box-shadow: 0px 43px 30px 0px rgba(0,0,0,0.09);
		}
	}
}
.status-label {
	position: relative;
	color: @dark-blue;
	font-weight: bold;
	margin-left: 25px;
	font-size: 14px;
	&:before {
		content: "";
		background-color: #93DC00;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		position: absolute;
		top: 2px;
		left: -25px;
	}
}
.warranties-card, .warranty-detail-card {
	.information-header {
	    text-align: left;
	    padding: 29px;
	    padding-top: 27px;
	    padding-bottom: 16px;
	}
	.information-body {
		aside {
			display: inline-block;
		    vertical-align: middle;
		    width: 30.7%;
		    text-align: right;
		    img {
		    	width: 178px;
		    	height: 131px;
    			object-fit: contain;
    			transform: translateY(4px);
		    }
		}
		.information-details {
			display: inline-block;
		    vertical-align: middle;
		    width: 68.2%;
		    text-align: left;
		}
		.information-footer {
			text-align: right;
    		padding: 0 29px 35px;
    		.received {
	            margin-left: -6px;
	            text-align: right;
    		}
		}
	}
}
.warranties-information-list, .information-list {
	position: relative;
	padding-left: 35px;
	text-align: left;
	font-size: 16px;
	li {
		width: 100%;
		color: #071b4f;
		i {
			display: inline-block;
			vertical-align: middle;
			width: 100px;
			text-align: right;
			font-style: normal;
			font-size: 0.9em;
		}
		strong {
			position: absolute;
			margin-left: 45px;
			margin-top: 1px;
		}
	}
	li + li {
		margin-top: 15px;
	}
	li:nth-child(4) {
		margin-top: 35px;
	}
}
.received {
	font-size: 14px;

	color: @dark-blue;

	display: inline-block;
	vertical-align: middle;

	i {
		display: inline-block;
		vertical-align: middle;

		padding: 2px 3px;

		margin-right: 5px;
		margin-top: -2px;
		background-color: @dark-blue;

		font-style: normal;
		font-weight: @bold;
		color: white;
		border-radius: 2px;
	}
	svg {
		width: 24px;
		height: 24px;
		margin: 0 5px;
		display: inline-block;
		transform: translateY(6px);
	}
}
