#insurance-details-modal, #transfer-details-modal {
	color: @dark-blue3;
	.certificate-id {
		width: 100%;
	}
	.main-wrapper {
		display: flex;
		padding: 26px;
		padding-top: 0;
	}
	.certificate-id {
		padding: 26px;
		.number {
		    margin-left: 5px;
			font-size: 14px;
		}
	}
	.product {
		width: 330px;
		.img-wrapper {
			width: 330px;
			height: 324px;
			margin-bottom: 18px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.header {
		background-color: #e6e0ce;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 21px;
		padding: 15px 10px 14px 21px;
		letter-spacing: 1px;
		p {
			text-transform: none;
			font-weight: normal;
			font-size: 15px;
			letter-spacing: 0;
			margin: 0;
			margin-top: 6px;
		}
	}
	.line {
		font-size: 14px;
		padding: 12px 0;
		border-bottom: 1px solid  #c0c0c0;
		.label {

		}
		.value {
			font-weight: bold;
			float: right;
		}
	}
	.informations {
		width: calc(100% - 330px);
		padding-left: 54px;
	}
	.informations, .replacement {
		.cols2 {
			display: flex;
			margin-bottom: 56px;
			> div {
				width: 50%;
				/*&:not(:first-child) {
					padding-left: 54px;
				}*/
				&:first-child {
					padding-right: 27px;
				}
				&:last-child {
					padding-left: 27px;
				}
			}
		}
		.id-font {
			margin-right: 6px;
			display: inline-block;
			transform: scale(.8);
		}
	}
	.transfert-datas {
		margin-bottom: 60px;
		.line {
			padding: 18px 0;
			font-size: 13px;
		}
		label {
			padding-left: 32px;
			&:before {
				top: 0;
				border-color: #c0c0c0;
			}
			&:after {
				background-color: transparent;
			}
		}
		.value {
		    display: inline-block;
    		transform: translateY(4px);
		}
		.serial {
			float: right;
			margin-right: 107px;
			display: inline-block;
			transform: translateY(4px);
			span {
				margin-right: 12px;
			}
		}
	}
	.sticky-buttons {
		position: absolute;
		.container {
			display: flex;
    		justify-content: flex-end;
		}
		.back {
			&:before {
				display: none;
			}
		}
		.btn {
			&.disabled {
				background-color: #E6E8ED;
				color: #fff;
				cursor: inherit;
				box-shadow: none !important;
			}
		}
	}
}

.shadow-wrapper {
	position: fixed;
	display: none;
	z-index: 30;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    .shadow {
		position: fixed;
    	display: flex;
		background-color: rgba(0,0,0,.5);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		justify-content: center;
	    align-items: center;
    }
    .close-modal {
    	&:before, &:after {
    		display: none;
    	}
    }
	.popin {
		width: 100%;
		max-width: 580px;
		padding: 42px 66px 40px;
		background-color: #fff;
		color: @dark-blue3;
		position: relative;
	}
	.title {
		font-size: 29px;
		color: #314e5e;
		margin-top: 0;
	}
	.desc {
		margin-bottom: 34px;
	}
	.input-group {
		margin-bottom: 0;
	}
	input[type="text"], select, .custom-select {
		margin-bottom: 34px;
	}
	.button-wrapper {
		text-align: center;
		margin-top: 6px;
	}
}
.insurrance-replacement {
	height: 100vh;
	max-height: 100vh;
	.scrollbar-inner {
		height: 100vh;
	}
}
.none-certificat {
	text-align: center;
	padding: 2.5rem 7.5rem;
	.title {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 1rem;
	}
}
#shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.4);
	display: none;
	z-index: 10;
	/*&:before, &:after {
		content: "";
		background-color: #fff;
		width: 1px;
		height: 20px;
		position: absolute;
		right: 1110px;
		top: 10px;
		cursor: pointer;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}*/
}



