.factory-transfert-table {
    width: 100%;
    border-collapse: separate;
    // border-spacing: 0 15px;
    th {
        color: #cdd1dc;
        text-transform: uppercase;
        font-weight: @bold;
        font-size: 12px;
        line-height: 1.5em;
    }

    tr {
        &:nth-child(2n) {
            background-color: rgba(7, 27, 79, 0.02);
        }
    }

    td {
        padding: 10px;
        &:nth-child(2) {
            color: @grey;
        }
        &:nth-child(3) {
            color: @grey;
        }
    }
}