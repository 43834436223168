.powered-by {
    position: absolute;

    width: 100%;

    bottom: 0;
    left: 0;

    text-align: center;

    span, .goodsid {
        display: inline-block;
        vertical-align: middle;

        color: fade(@dark-blue, 20%);
        font-weight: @normal;
        font-size: 0.8em;
    }

    .goodsid {
        padding: 0 0 1px 2px;
        border:solid 1px fade(@dark-blue, 20%);
        text-transform: uppercase;
        margin-left: 5px;
        strong {
            padding-left: 4px;
            padding-right: 2px;
            margin-left: 3px;
            background-color: rgb(208, 212, 219);
            color: white;
            z-index: 10;
        }
    }
}