.main-header {
    z-index: 100000;
}

.main-footer {
    z-index: 1;
}

.growl-notification-wrapper {
    z-index: 100;
}

.common-wrapper {
    position: relative;
    z-index: 10000;
}

.modal-overlay {
    z-index: 10000000;
}