.ui-kit {
    padding-bottom: 40px;
}

.ui-big-title {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: @bold;
    
    display: block;
    
    strong {
        color: @grey;
        font-weight: @normal;
        text-transform: capitalize;
    }
}

.ui-title {
    display: block;
    
    padding-bottom: 5px;
    margin-bottom: 25px;
    
    text-align: left;
    font-size: 24px;
    font-weight: @bold;
    text-transform: uppercase;
    
    border-bottom: 2px solid #dce1f7;
}

.ui-description {
    color: #8091b0;
    text-transform: uppercase;
    font-size: 10px;
}

.ui-row {
    margin-bottom: 40px;
    .ui-block {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        
        margin-top: 45px;
        
        &.typography {
            width: 60%;
        }
        
        &.colors {
            width: 38%;
            padding-left: 2%;
        }

        .ui-description {
            display: block;
            margin-bottom: 5px;
        }
        
        .common-link {
            display: block;
        }
    }
}

.ui-color-list {
    margin-top: 10px;
}
.ui-color-item {
    display: inline-block;
    vertical-align: middle;
    
    width: 35px;
    height: 35px;
    
    margin-right: 3px;
    
    border-radius: 50%;
    
    &.dark-blue {
        background-color: @dark-blue;
    }
    &.dark-beige {
        background-color: @dark-beige;
    }
    &.beige {
        background-color: @beige;
    }
    &.light-grey {
        background-color: @light-grey;
    }
    &.black {
        background-color: @black;
    }
    &.grey {
        background-color: @grey;
    }
    &.light-white {
        background-color: @light-white;
    }
}

.ui-button-list {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 25px;

    .ui-description {
        display: block;
    }

    &:nth-child(2n +1) {
        margin-left: -6px;
    }

    .ui-button-item {
        display: inline-block;
        vertical-align: top;

        margin: 5px 5px 0 0;
    }
}

.dropdown {
    .ui-block {
        &.dropdowns {
            width: 60%;
        }
        &.text-inputs {
            width: 40%;
            margin-left: -6px;
            padding-left: 25px;

            .input-group {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                margin-top: 0;
                 
                &:nth-child(2n +1) {
                    margin-left: -6px;
                    padding-left: 10px;
                }
            }
        }
    }
}

.ui-column {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    padding-right: 20px;
}

.inputs {
    .ui-block {
        width: 33%;
        &:nth-child(2), &:nth-child(3) {
            padding-left: 20px;
        }
        label {
            margin-right: 30px;
        }

        p {
            display: inline-block;
            vertical-align: top;
            margin-top: 0;
        }
    }
}

.ui-links-wrapper {
    margin-top: 35px;

    h3 {
        margin: 0.5em;
        margin-left: 0;
    }
}

