.histories-list {
    .history-item {
        position: relative;
        padding-left: 15px;
        
        display: block;
        padding-bottom: 35px;
        
        &:last-child {
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
        
        .history-action {
            display: block;
            color: @dark-blue;
            font-weight: @medium;
        }
        
        .history-id {
            display: block;
            color: @dark-blue;
            font-weight: @normal;   
        }
        
        .history-date {
            font-size: 0.9em;
            color: @grey;
            font-weight: @medium;
        }
        
        &:before {
            content: '';
            position: absolute;
            
            left: 0;
            top: 6px;
            
            width: 7px;
            height: 7px;
            
            background-color: @dark-blue;
            border-radius: 50%;
        }
        
        &:after {
            content: '';
            position: absolute;
            
            left: 3px;
            top: 20px;
            
            width: 1px;
            height: calc(100% - 20px);
            
            background-color: @light-grey;
        }
    }
}