.common-wrapper {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    // margin-bottom: 150px;

    &.wallet-wrapper, &.warranties-wrapper {
        width: 940px;
        margin-top: 100px;

        @media only screen and (max-width : 940px) {
            width: 100%;

            padding: 20px;
        }
    }
    &.warranties-wrapper {
        margin-bottom: 90px;
    }
    &.warranty-details-wrapper {
        width: 974px;
        margin-top: 100px;
        margin-bottom: 90px;
    }
    &.wallet-message-wrapper {
        max-width: 780px;
    }

    &.centred {
        height: 100vh;
        margin-top: 0;
        margin-bottom: 0;
        // min-height: 750px;
        display: table;
        .inner-wrapper {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &.medium {
        width: 800px;
    }

    &.large {
        width: 1140px;
    }

    @media only screen and (max-width : 480px) {
        width: 100%;
        padding: 10px;
        margin-bottom: 100px;

        &.centred {
            margin-top: 50px;
        }
    }

}

.img-cta-wrapper {
    img, svg {
        max-width: 200px;
        margin-bottom: 1em;
    }

    @media only screen and (max-width: 640px) {
        margin-top: -25px;
    }
}

.half-block-container {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    &:nth-child(3) {
        margin-left: -6px;
    }

    @media only screen and (max-width : 640px) {
     display: block;
     width: 100%;
     margin: 0 !important;
    }
}

.row {
    .half-block-container {
        &:nth-child(2) {
            margin-left :   -6px;
        }
    }
}