.common-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
    .label {
        display: block
    }
    &.disabled {
        pointer-events: none;
        .slider {
            border-color: @light-grey;
            &:before {
                background-color: @light-grey;
            }
        }
    }
  }
  
  .common-switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 25px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border: solid 1px @dark-blue;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 3px;
    background-color: @dark-blue;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: @dark-blue;

    &:before {
        background-color: white;
    }
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(23px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }