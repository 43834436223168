.common-input-file {
    position: relative;
    text-align: left;
    display: block;
    margin: 15px 0;

    appearance: none;
    border: none;
    background: white;
    padding: 10px 15px;
    border:solid 1px rgba(7, 27, 79, 0.2);
    border-radius: 2px;
    .input-icon {
        position: absolute;
        top: 50%;
        right: 15px;

        transform: translate3d(0,-50%,0);
    }

    label {
        color: @black;
        font-weight: @normal;
        padding-bottom: 5px;
        display: block;
        font-size: 0.8em;
    }
    input {
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        cursor: pointer;
    }

    input + label {
        font-weight: @bold;
        color: @dark-blue;
        display: inline-block;
    }
    &.on-error {
        input {
            border-color: @orange;
        }
        .error-message {
            display: block;
            margin-top: 5px;
        }
    }
    .error-message {
        display: none;
        color: @orange;
        font-size: 0.7em;
        i {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    &.common-add-image-input {
        width: auto;
        display: inline-block;
        cursor: pointer;
        transition: all .2s;
        &:hover {
            background-color: @light-blue;
        }
        .input-icon {
            position: initial;
        }
        label {
            display: inline-block;
            vertical-align: top;
            margin: 8px 0 0 8px;
            font-size: 17px;
            color: @dark-blue;
            font-weight: 600;
            font-family: 'Source Sans Pro';
        }
    }
}

.form-wrapper {
    text-align: center;

    .button-main {
        margin-top: 25px;
    }

    .lost-password {
        display: block;
        margin-top: 15px;
    }
}