.modal-overlay {
    position: fixed;

    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;
    display: table;

    background-color: rgba(0, 0, 0, 0.5);
    display: none;

    &.white {
        background-color: rgba(255, 255, 255, 0.5);
    }

    @media only screen and (max-width : 570px) {
        padding: 15px;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;

        margin-left: auto;
        margin-right: auto;

        .modal-inner {
            position: relative;
            width: 550px;
            margin-left: auto;
            margin-right: auto;

            padding: 20px 35px;

            box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
            background-color: #ffffff;
            border: solid 1px @light-grey;
            border-radius: 4px;

            text-align: center;

            &.no-border {
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
                border: none;
            }

            &.large {
                width: 650px;

                @media only screen and (max-width : 570px) {
                    width: 100%;
                }
            }

            &.small {
                width: 450px;
                padding: 30px 35px;

                @media only screen and (max-width : 570px) {
                    width: 100%;
                }
            }



            .modal-header {
                img,svg {
                    display: block;
                    margin: 0 auto 30px auto;

                    &.small {
                        max-width: 50px;
                    }

                    &.medium {
                        max-width: 75px;
                    }
                }
                .modal-header-title {
                    max-width: 80%;
                    margin: 0 auto 30px auto;
                    font-weight: @normal;
                    font-size: 1.2em;

                    &.bold {
                        font-weight: @bold;
                    }
                }

                p {
                    padding-bottom: 15px;
                }
            }

            .common-warning {
                text-align: left;

                span, p {
                    color: @dark-blue;
                }
            }

            .wallet-information-list {
                text-align: left;
            }

            .modal-body {
                p {
                    max-width: 90%;
                    margin: auto;
                }
            }

            .checkbox {
                label {
                    margin-left: 35px;
                    color: @grey;
                    letter-spacing: 0;
                    line-height: 1.2em;

                    &::before {
                        width: 25px;
                        height: 25px;

                        left: -35px;
                        top: 0px;

                        border-color: @dark-beige;
                    }
                    &::after {
                        top: 5px;
                        left: -30px;

                        width: 15px;
                        height: 15px;
                    }
                }
            }

            h3 {
                margin: 10px 0;
            }

            @media only screen and (max-width : 570px) {
                width: 100%;
                padding: 35px 15px;
            }
        }
    }

    // Show modal behaviour

    &.active {
        display: table;
        .modal-inner {
            .common-appearance;
            animation-delay: 0s !important;
        }
    }

}
.close-modal {
    position: absolute;
    width: 20px;
    height: 20px;

    top: 25px;
    right: 35px;

    cursor: pointer;

    &:before, &:after {
        content: '';
        position: absolute;

        top: 9px;
        left: 0;

        width: 100%;
        height: 3px;
        border-radius: 2px;

        background: black;

        transform: rotate(45deg);

    }
    &::after {
        transform: rotate(-45deg);
    }

}