.button-bold {
    appearance: none;
    outline: none;
    border: none;
    
    text-transform: uppercase;
    font-weight: @bold;
    letter-spacing: 2px;
    
    color: @dark-blue;
    font-size: 0.8em;

    cursor: pointer;
    
    img, svg {
        display:inline-block;
        vertical-align: middle;
        
        transition: all 0.25s ease;

        &.arrow-left {
            margin-right: 10px;
            margin-top: -1px;
        }
    }

    &.red {
        color: @red;
    }

    &.bordered {
        padding: 12px 15px;
        background-color: @dark-blue;
        color: white;
        border-radius: 2px;

        &:hover {
            background-color: fade(@dark-blue, 90%);
        }

        &.red {
            background-color: @red;
            color: white;
        }
    }

    &:hover {
        img, svg {
            &:not(.arrow-left) {
                transform: translate3d(5px,0,0);
            }
            transform: translate3d(-5px,0,0);
        }
    }
}