.notification-indicator {
    position: absolute;
    bottom: 8px;
    right: 5px;

    width: 8px;
    height: 8px;

    border-radius: 50%;

    background-color: @medium-red;
}