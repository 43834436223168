.wallet-message {
    .message-header {
        position: relative;
        background-color: @beige;
        overflow: hidden;
        
        padding: 55px;

        @media only screen and (max-width : 580px) {
            padding: 55px 25px;
        }
        
        h1 {
            font-size: 1.3em;
            color: @dark-blue;
            font-weight: @normal;
            margin-bottom: 0;
            
            img {
                display: inline-block;
                vertical-align: middle;
                
                max-width: 105px;
                margin-left: 5px;
            }
        }
        
        .message-header-illustration {
            position: absolute;

            max-width: 150px;
            right: -38px;
            bottom: -40px;

            opacity: 0.4;

            @media only screen and (max-width : 580px) {
                max-width: 100px;

                right: -20px;
                bottom: -20px;
            }
        }
    }  
    .message-body {
        padding: 55px;

        .message-date {
            color: @grey;
            font-weight: @normal;
        }

        .message-title {
            color: @dark-blue;
            font-weight: @medium;
        }

        .message-copy {
            p {
                line-height: 1.63em;
            }
        }

        @media only screen and (max-width : 580px) {
            padding: 55px 25px;
        }
    } 

    
}