.common-notification {
    position: relative;
    background:#f4f2e9;
    padding: 10px 15px;
    margin-bottom: 20px;

    border-radius: 2px;
    border: solid 1px #e7e1cd;

    color: @grey;
    font-weight: @medium;

    animation:0.5s ease shake forwards;

    a {
        text-decoration: underline;
    }

    &.error {
        background-color: @light-red;
        color: @red;
        border-color:@red;

        .common-arrow {
            &:before, &:after {
                background-color: @red;
            }
        }
    }

}