.register-product-template {
	background-color: #f8f8f8;
	.register-product-header {
		color: @dark-blue3;
		display: flex;
		justify-content: space-between;
		padding: 24px 44px;
		.register-logo {
			padding-top: 17px;
			text-transform: uppercase;
			font-size: 22px;
			font-weight: bold;
			letter-spacing: 8px;
		}
	}
	main {
		color: @dark-blue3;
		min-height: calc(100vh - 380px);
		.container {
			margin: 28px auto;
			width: 100%;
			max-width: 565px;
			padding: 10px;
			h1, h1[data-icon] {
				margin-top: 0px;
				margin-bottom: 34px;
				position: relative;
				font-size: 44px;
				line-height: 62px;
				font-weight: 300;
				br {
					display: none;
				}
				&:before {
					content: attr(data-icon);
					position: absolute;
					top: 0;
					left: -109px;
					font-size: 70px;
					font-family: 'Goods ID';
				}
			}
			.intro {
				font-size: 16px;
				line-height: 29px;
			}
			h2 {
				font-weight: 600;
				font-size: 18px;
				margin: 70px 0 30px;
			}
			ul {
				list-style: none;
				li {
					font-size: 15px;
					margin: 10px 0;
					padding-left: 25px;
					position: relative;
					line-height: 1.8;
					margin-bottom: 20px;
					&:before {
						content: "";
						position: absolute;
						top: 11px;
						left: 0;
						width: 6px;
						height: 6px;
						background-color: @dark-blue3;
					}
				}
			}
			form {
				margin-top: 65px;
				label {
					width: 100%;
					font-size: 22px;
					display: inline-block;
					margin-bottom: 15px;
					sup {
						margin-left: 3px;
						display: inline-block;
					}
				}
				input, .select-selected {
					width: 100%;
					border-radius: 7px;
					margin-bottom: 0px;
					background-color: transparent;
					display: block;
					&.select-arrow-active {
						background-color: #fff;
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
					}
				}
				.select-items {
					height: 250px;
					overflow-y: scroll;
					border-bottom-left-radius: 7px;
					border-bottom-right-radius: 7px;
					-webkit-box-shadow: 0px 25px 26px 0px rgba(0,0,0,0.16);
					-moz-box-shadow: 0px 25px 26px 0px rgba(0,0,0,0.16);
					box-shadow: 0px 25px 26px 0px rgba(0,0,0,0.16);
					div {
						padding: 15px;
						&:first-child {
							border-top: 0;
						}
					}
				}
				input[type="text"], input[type="email"], .select-selected {
					border: 1px solid #D8D8D8;
					padding: 24px;
					color: @dark-blue3;
					&:focus {
						border-color: @dark-blue3;
						outline: none;
					}
					&.error {
						border-color: #CE725B;
					}
				}
				.error-message {
					color: #CE725B;
					font-size: 16px;
					margin: 0 0 41px 0;
				}
				.upload-img {
					margin-top: 0;
					width: 100%;
					.big-upload-btn {
						height: 136px;
					}
				}
				#legal {
					&+label {
						margin-top: 53px;
						padding-left: 60px;
						font-size: 17px;
						line-height: 29px;
						&:before {
							top: 8px;
						}
						&:after {
							top: 13px;
						}
						.info {
							float: none;
						}
					}
				}
				hr {
					margin: 64px 0 29px;
					display: block;
					clear: both;
					height: 0;
					border: 0;
					position: relative;
					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						background-color: #D9D9D9;
						height: 1px;
						width: 100%;
					}
				}
				button[type="submit"] {
					margin-top: 29px;
					padding: 34px 46px;
					&:disabled {
						opacity: .5;
					}
				}
			}
		}
	}
	.register-product-footer {
		//margin-top: 174px;
		margin-top: 50px;
		background: #fff;
		border-top: 1px solid #cecece;
		//padding-top: 135px;
		//padding-bottom: 215px;
		padding-top: 50px;
		padding-bottom: 55px;
		color: @dark-blue3;
		.container {
			width: 100%;
			max-width: 1367px;
			margin: 0 auto;
			display: flex;
			.logo-wrapper {
				width: 410px;
				padding-left: 10px;
				padding-top: 9px;
			}
			.text-wrapper {
				width: 540px;
				font-size: 15px;
				line-height: 29px;
				padding-right: 10px;
			}
			.know-more {
				text-align: right;
				width: 397px;
				font-size: 16px;
				line-height: 29px;
				padding-right: 10px;
				a {
					color: #0038CE;
					text-decoration: underline;
				}
			}
		}
	}
}
@media only screen and (max-width: 850px){
	.register-product-template {
		.register-product-header {
			padding: 24px 10px;
			.register-logo {
				padding-top: 18px;
				letter-spacing: 2px;
				font-size: 20px;
			}
		}
		main {
			.container {
				margin-top: 0;
				padding-top: 0;
				h1[data-icon] {
					padding-right: 59px;
					font-size: 33px;
    				line-height: 42px;
					&:before {
						right: 40px;
						left: inherit;
						font-size: 50px;
   						//top: 8px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				form {
					label {
						.info {
							margin-right: 20px;
							.infobulle {
								margin-left: -110px;
								width: 300px;
								&:before, &:after {
									left: calc(100% - 35px) !important;
								}
							}
						}
					}
				}
			}
		}
		.register-product-footer {
			padding-top: 65px;
			padding-bottom: 65px;
			.container {
				display: block;
				.logo-wrapper, .text-wrapper, .know-more {
					width: 100%;
					margin-bottom: 30px;
					padding-left: 10px;
					padding-right: 10px;
					text-align: center;
				}
			}
		}
	}
}
@media only screen and (max-width: 570px){
	.register-product-template {
		main {
			.container {
				h1[data-icon] {
					br {
						display: block;
					}
				}
			}
		}
	}
}
.select-lang {
	position: relative;
	.lang-selected {
		background-color: #fff;
		border: 1px solid #D8D8D8;
		//padding: 19px 30px;
		padding: 19px 20px;
		font-size: 18px;
		cursor: pointer;
		border-radius: 10px;
		transition: all .2s;
		cursor: pointer;
		position: relative;
		//padding-right: 77px;
		padding-right: 40px;
		&:after {
			right: 18px;
		}
		&.on {
			&:after {
				transform: rotate(-90deg) translateY(-3px);
			}
		}
		&:hover {
			background-color: @dark-blue3;
			color: #fff;
		}
		&.on {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}
		&:after {
			content: "›";
			position: absolute;
			top: 19px;
			right: 25px;
			transform: rotate(90deg);
		}
	}
	ul {
		position: absolute;
		left: 0;
		top: 100%;
		background-color: #fff;
		border: 1px solid #D8D8D8;
		border-top: none;
		width: 100%;
		font-size: 18px;
		border-bottom-left-radius: 7px;
		border-bottom-right-radius: 7px;
		transition: .2s all;
		display: none;
		z-index: 1;
		li {
			padding: 17px 30px;
			cursor: pointer;
			&:hover {
				background-color: @dark-blue3;
				color: #fff;
			}
			&:not(:first-child) {
				border-top: 1px solid #D8D8D8;
			}
		}
	}
}
.info {
	background-color: #fff;
	border-radius: 50%;
	text-align: center;
	float: right;
	width: 24px;
	height: 24px;
	display: inline-block;
	transition: .2s all;
	.icon {
		//color: #cbced5;
		color: #a0a6b2;
		font-weight: bold;
		font-family: "Times New Roman", serif;
		//font-size: 16px;
		font-size: 15px;
		display: inline-block;
		//transform: translateY(-2px);
		transform: translateY(-1px);
	}
	&:hover {
		background-color: @dark-blue3;
		color: #fff;
	}
	.infobulle {
		color: @dark-blue3;
		font-size: 15px;
		line-height: 30px;
		width: 546px;
		top: -20px;
		margin-left: -169px;
		padding: 48px 41px;
		&:before, &:after {
			left: calc(100% - 99px) !important;
		}
	}
}