.common-input {
    border:solid 1px @dark-blue;
    border-radius: 2px;
    position: relative;

    margin-bottom: 30px;

    .common-appearance;

    input, .common-icon {
        display: inline-block;
        vertical-align: middle;
    }

    .common-icon {
        margin: 0 8px;
        cursor: pointer;
        margin-top: 7px;
    }

    a {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 100%;
        border-left: solid 1px @dark-blue;
    }

    input {
        appearance: none;
        border: none;
        background: white;
        padding: 10px 15px;
        outline: none;

        width: 100%;

        &::placeholder {
            color : @grey;
            font-style: italic;
            font-size: 1em;
        }


        transition: all 0.25s ease;
        &:focus {
            box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
        }
    }

    &.no-shadow {
        box-shadow: none;
    }
}
.input-group.dark-field, .dark-field {
    input, textarea {
        background-color: #F9F9F9;
    }
}