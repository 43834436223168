.warranty-nav {
	margin-bottom: 35px;
	.received-status-wrapper {
		float: right;
		.received {
			padding-right: 25px;
			vertical-align: top;
			transform: translateY(-9px);
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: 8px;
				right: 0;
				height: 23px;
				width: 1px;
				background-color: #707070;
				opacity: .21;
			}
		}
		.warranty-status-wrapper {
			vertical-align: top;
			margin-left: 26px;
			padding-top: 2px;
		}
	}
}
.warranty-detail-card {
	margin-bottom: 30px;
	.information-body {
		width: 100%;
		border-bottom: #DEDEDE 1px solid;
		display: flex;
		align-items: center;
		&:nth-child(2) {
			border-bottom: none;
		}
		aside {
			img {
				height: 79px;
    			transform: translateY(3px);
			}
		}
	}
	.good-details-information {
		width: 67%;
		display: flex;
		align-items: center;
		/*padding: 68px 0 38px;*/
		padding: 53px 0;
		border-right: #DEDEDE 1px solid;
		aside {
			width: 33%;
			.good-illustration {
				width: 163px;
			}
		}
		.information-details {
			width: 67%;
			li {
				i {
					width: 38px;
				}
				strong {
					margin-left: 18px;
				}
			}
		}
	}
	.owner-information {
		width: 33%;
		display: inline-block;
		padding-left: 66px;
		svg{
			margin-bottom: 15px;
		}
		.svg {
			max-height: 48px;
			margin-bottom: 9px;
		}
		.information-list {
			padding-left: 0;
			transform: translateY(4px);
			li {
				i {
					width: auto;
				}
				strong {
				    margin-left: 8px;
				}
			}
		}
	}
	.sale-information, .warranty-information {
		width: 50%;
		padding-left: 99px;
		svg {
			float: left;
			margin-right: 31px;
		}
		li {
			i {
				width: auto;
			}
			strong {
			    margin-left: 8px;
			}
		}
	}
	.sale-information {
		border-right: #DEDEDE 1px solid;
		padding: 51px 0 53px 95px;
	}
}
.common-small-bold-blue {
	margin-bottom: 27px !important;
	color: #CDD1DC;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: bold;
}
.warranty-message-card, .warranty-history-card {
	&.warranty-message-card {
		padding: 56px 76px 68px;
	}
	&.warranty-history-card {
		padding: 40px 77px 85px;
	}
}
.warranty-message-card {
	font-size: 18px;
	color: @dark-blue;
	margin-bottom: 6px;
	.information-body {
		display: flex;
		align-items: center;
		.textual-information {
			width: 65%;
		}
		.download-photo-wrapper {
			width: 35%;
		}
		p {
			margin: 7px 0;
		}
		.message {
			font-weight: bold;
		}
		.message-date {
			font-size: 16px;
			margin-top: 14px;
			svg {
				display: inline-block;
				transform: translateY(2px);
				margin-right: 12px;
			}
			span {
				color: @black;
			}
		}
	}
}
.download-photo-wrapper {
	text-align: right;
	.download-photo {
		border: 1px solid @very-light-grey;
		padding: 19px 40px 14px;
		display: inline-block;
		transition: all .2s;
		svg {
			margin-right: 4px;
		}
		span {
			text-decoration: underline;
			display: inline-block;
			transform: translateY(-6px);
			font-weight: bold;
		}
		&:hover {
			background-color: @light-blue;
		}
	}
}
.button-warranties {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    //padding: 15px 25px;
    padding: 28px 64px;
    border: solid 1px @dark-blue;
    border-radius: 2px;
    background-color: @dark-blue;
    color: white;
    font-weight: 700;
    line-height: 1.29em;
    cursor: pointer;
    transition: all 0.25s ease;
    margin-bottom: 34px;
    margin-right: 4px;
    span {
    	font-size: 17px;
    }
    svg {
    	display: inline-block;
	    vertical-align: middle;
	    margin-top: -2px;
	    margin-right: 8px;
	    margin-left: -5px;
    }
    &.extend-button {
    	background-color: #fff;
    	color: @dark-blue;
    	border: solid 1px #fff;
    	svg {
    		margin: 4px 8px 4px 0;
    	}
    }
    &.answer-button {
		span {
			font-weight: normal;
		}
    }
    &:hover {
    	box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.2);
    }
}
.warranty-history-card {
	&.block {
		padding: 0 !important;
	    border: none;
	    box-shadow: none;
	    margin-bottom: 60px !important;
	    .events-list {
	    	.event-wrapper {
				.textual-information {
					.information-title {
						font-size: 16px;
					}
					.history-details {
						font-size: 14px;
					}
				}
	    	}
	    }
	}
	.events-list {
		margin-top: 37px;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 27px;
			background-color: #C8C8C8;
			width: 1px;
			height: calc(100% - 55px);
		}
	}
	.mobile-submiter {
		display: none;
	}
	.event-wrapper {
		color: @dark-blue;
		display: flex;
		align-items: flex-start;
		&:not(:last-child) {
			margin-bottom: 46px;
		}
		.icon-wrapper {
			width: 62px;
			position: relative;
			z-index: 1;
			svg {
				border: 5px solid #fff;
				transform: scale(1.15);
			}
		}
		.textual-information {
			width: calc(75% - 62px);
			padding-left: 21px;
			.information-title {
				font-weight: bold;
				font-size: 18px;
				margin: 2px 0;
			}
			p:not(.information-title) {
				margin: 9px 0;
			}
			.reason {
				margin: 0 2px;
			}
			.history-details {
				margin-top: 6px;
				font-size: 16px;
				display: inline-block;
				&.tiny-date-wrapper, {
					margin-right: 30px;
					svg {
						margin-right: 8px;
						transform: translateY(2px);
					}
				}
				&.tiny-submiter-wrapper {
					svg {
						margin: 0 5px;
						transform: translateY(2px);
					}
				}
				.id-font {
					margin-right: 10px;
				}
			}
		}
		.download-photo-wrapper {
			width: 25%;
		}
	}
}
@media screen and (max-width: 1000px) {
	.warranty-history-card.warranty-history-card:not(.block) {
		width: 100vw;
		padding: 20px 29px 45px 49px;
		.events-list {
			padding-left: 0;
			margin-top: 0;
			&:after {
				left: -23px;
				top: 30px;
				height: calc(100% - 35px);
			}
			.event-wrapper {
				display: block;
				margin-bottom: 7px;
				padding-left: 6px;
				.mobile-submiter {
					display: inline-block;
					vertical-align: top;
					margin-left: 10px;
					transform: translateY(4px);
				}
				.icon-wrapper {
					width: 100%;
					font-weight: bold;
					position: relative;
					svg {
						-webkit-transform: scale(.9);
					    transform: scale(.9);
					}
					&:before {
						content: "";
						position: absolute;
						background: #fff;
						border-radius: 50%;
						border: 1px solid #C8C8C8;
						width: 7px;
						height: 7px;
						left: -33px;
    					top: 23px;
					}
				}
				.textual-information {
					padding-left: 0;
					width: 100%;
					.information-title {
					    padding-left: 6px;
					    margin: 1px 0 12px;
					    font-size: 18px;
					    line-height: 1.5;
					}
					.reason {
					    margin-bottom: 10px;
					}
					.tiny-date-wrapper {
					    padding-left: 6px;
					    font-size: 16px;
						svg {
							display: none;
						}
					}
					.tiny-submiter-wrapper {
						display: none;
					}
					.reason {
					    padding-left: 3px;
					    margin-bottom: 12px;
					}
				}
				.download-photo-wrapper {
					width: 100%;
					transform: translateY(calc(-100% + 7px));
					.download-photo {
						border: none;
						padding: 0;
						font-size: 16px;
						svg {
							-webkit-transform: translateY(.75);
						    transform: scale(.75);
						}
						span {
							-webkit-transform: translateY(-8px);
							transform: translateY(-8px);
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 940px) {
	.warranty-history-card.warranty-history-card.block {
		padding: 20px 29px 45px 49px;
		width: 100% !important;
		.events-list {
			padding-left: 0;
			margin-top: 0;
			width: calc(100% - 35px) !important;
    		margin-left: 25px;
			&:after {
				left: -23px;
				top: 30px;
				height: calc(100% - 35px);
			}
			.event-wrapper {
				display: block;
				margin-bottom: 7px;
				padding-left: 6px;
				.mobile-submiter {
					display: inline-block;
					vertical-align: top;
					margin-left: 10px;
					-webkit-transform: translateY(-2px);
					transform: translateY(-2px);
				}
				.icon-wrapper {
					width: 100%;
					font-weight: bold;
					position: relative;
					svg {
						-webkit-transform: scale(.9);
					    transform: scale(.9);
					}
					&:before {
						content: "";
						position: absolute;
						background: #fff;
						border-radius: 50%;
						border: 1px solid #C8C8C8;
						width: 7px;
						height: 7px;
						left: -33px;
    					top: 23px;
					}
				}
				.textual-information {
					padding-left: 0;
					width: 100%;
					.information-title {
					    padding-left: 6px;
					    margin: 1px 0 1px;
					    line-height: 1.5;
					}
					.reason {
					    margin-bottom: 10px;
					}
					.tiny-date-wrapper {
					    padding-left: 6px;
						svg {
							display: none;
						}
					}
					.tiny-submiter-wrapper {
						display: none;
					}
					.reason {
					    padding-left: 3px;
					    margin-bottom: 12px;
					}
				}
				.download-photo-wrapper {
					width: 100%;
					transform: translateY(calc(-100% + 7px));
					.download-photo {
						border: none;
						padding: 0;
						font-size: 16px;
						svg {
							-webkit-transform: translateY(.75);
						    transform: scale(.75);
						}
						span {
							-webkit-transform: translateY(-8px);
							transform: translateY(-8px);
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 480px) {
	.warranty-history-card.warranty-history-card {
		width: calc(100vw - 20px);
	}
}
@media screen and (max-width: 330px) {
	.warranty-history-card.warranty-history-card {
		.events-list .event-wrapper .download-photo-wrapper {
			text-align: left;
			transform: none;
			webkit-transform: none;
			margin: 10px 0;
		}
		&.block {
			.textual-information {
				margin-bottom: 30px;
			}
		}
	}
}

