.button-group {
    text-align: center;
    margin-top: 35px;

    a, input, span {
        margin-top: 0 !important;
        display: inline-block !important;
        vertical-align: middle;
        +a, input, span {
            margin-left: 5px;
        }
    }

    &.spaced {
        text-align: left;

        a, span, input {
            +input, +a, +span {
                float: right;
            }
        }

        @media only screen and (max-width : 450px) {
            text-align: center;
            a, span, input {
                display: block !important;
                float: none !important;
                margin: 10px auto;
            }
        }
    }

   

}