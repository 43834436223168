.modal-overlay {
	.modal-wrapper {
		.warranties-modal, .extend-warranty-modal {
			border: 1px solid #CDD1DC;
			padding: 64px 94px;
			text-align: left;
		}
		.warranties-modal {
			width: 920px;
		}
		.extend-warranty-modal {
			width: 1170px;
			padding: 78px 94px 64px;
			button {
				svg {
					transform: scale(0.7) translateX(-7px) translateY(-2px);
				}
			}
		}
		.buttons-right-wrapper {
			overflow: visible;
			float: right;
			text-align: right;
			.button-third, .button-main {
				margin-top: 15px;
			}
		}
		.buttons-left-wrapper {
			overflow: visible;
			float: left;
			text-align: left;
			.button-third, .button-main {
				margin-top: 15px;
			}
		}
		.warranties-modal, .extend-warranty-modal {
			.modal-header {
				.modal-header-title {
					margin: 0 0 7px 0;
					font-size: 22px;
					color: @dark-blue;
				}
				.common-regular-grey {
					margin: 2px 0;
					font-size: 16px;
				}
			}
			.form-wrapper {
				textarea {
					height: 263px;
					max-height: none;
					margin: 2px 0;
				}
				.buttons-wrapper {
					text-align: left;
					min-height: 68px;
				}
				.buttons-right-wrapper {
					overflow: visible;
					float: right;
					.button-third, .button-main {
						margin-top: 15px;
					}
				}
				.common-input-file {
					margin-bottom: 0;
					.close-icon {
						margin: 0 7px 0 10px;
						transform: translateY(-8px);
						display: inline-block;
						cursor: pointer;
					}
				}
				button {
					svg {
						display: inline-block;
						margin: 0;
						path {
							fill: transparent;
						}
					}
				}
			}
		}
		.extend-warranty-block {
			display: flex;
			margin-bottom: 30px;
			margin-top: 39px;
			text-align: left;
			.extend-warranty-part {
				border-radius: 8px;
				width: 50%;
				padding: 37px 42px 34px;
				position: relative;
				&.extend-warranty-left-part {
					background-color: #F4F1E9;
					color: @black;
					position: relative;
					z-index: 1;
				}
				&.extend-warranty-right-part {
					background-color: @dark-blue;
					color: #fff;
					padding-left: 66px;
					.date {
						width: 100%;
						margin-top: -12px;
						input {
							width: 100%;
							border: none;
							background-color: transparent;
							padding: 0;
							font-size: 20px;
							color: #fff;
							padding-bottom: 24px;
							border-bottom: dashed #9E9E9E 1px;
							outline: none !important;
							font-family: 'Source Sans Pro';
						}
						::placeholder { /* Firefox, Chrome, Opera */
							color: #fff;
							opacity: 1;
						}

						:-ms-input-placeholder { /* Internet Explorer 10-11 */
							color: #fff;
							opacity: 1;
						}

						::-ms-input-placeholder { /* Microsoft Edge */
							color: #fff;
							opacity: 1;
						}
					}
				}
				strong {
					font-size: 19px;
					width: 100%;
					margin-bottom: 82px;
					display: inline-block;
				}
				.date {
					font-size: 20px;
					width: 100%;
					margin: 0;
					padding-bottom: 0;

				}
				.extend-warranty-arrow {
					position: absolute;
					right: 0;
					top: 50%;
					display: inline-block;
					transform: translateX(50%) translateY(-17px);
				}
			}
		}
		.exercise-warranty-modal {
			padding: 0;
			border-radius: 10px;
			width: 100%;
			max-width: 970px;
			.modal-header {
				display: flex;
				border-radius: 10px;
				background-color: @light-beige;
				background-image: url(../img/ui/icons/shield-bg.svg);
				background-repeat: no-repeat;
				background-position: left bottom;
				border: 1px solid @beige;
				text-align: left;
				color: @dark-blue3;
				padding: 69px 0 39px 105px;
				width: calc(100% + 2px);
				margin: -1px 0 0 -1px;
				.img {

				}
				.txt {
					padding-left: 60px;
					.modal-header-title {
						margin: 0;
						width: auto;
						min-width: 330px;
						border-bottom: 2px solid #eae9e7;
						padding-bottom: 9px;
						margin-bottom: 21px;
						font-size: 22px;
					}
					p {
						margin: 0;
						font-size: 18px;
						&:not(:last-child) {
							margin-bottom: 6px;
						}
					}
				}
			}
			.form-wrapper {
				text-align: left;
				color: @dark-blue3;
				padding: 40px 111px 79px 122px;
				h3 {
					font-size: 24px;
					margin-bottom: 33px;
				}
				.textarea-file-wrapper {
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;
					.textarea-wrapper {
						width: 462px;
						label {
							font-size: 17px;
							margin-bottom: 10px;
							display: inline-block;
							font-weight: normal;
						}
						textarea {
							border-radius: 5px;
							height: 149px;
							max-height: none;
						}
					}

				}
				.phone-wrapper {
					label {
						font-size: 17px;
						margin-bottom: 10px;
						display: inline-block;
					}
					input {
						width: 100%;
						background: white;
						outline: none;
						border: solid 1px #e7e1cd;
						border-radius: 2px;
						padding: 15px;
						transition: all 0.25s ease;
						border-radius: 5px;
						&:focus {
							border-color: #071b4f;
							box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
						}
					}
					.label-authorization {
						color: #A1A1A1;
						font-size: 15px;
						padding-left: 30px;
						margin-top: 18px;
						margin-bottom: 28px;
						&:before {
							top: 0;
							border: solid 1px #e7e1cd;
							border-radius: 2px;
						}
						&:after {
							top: 5px;
						}
					}
				}
				.buttons-wrapper {
					overflow: auto;
				}
			}
		}
		.confirm-warranty-extension-modal {
			width: 100%;
			max-width: 920px;
			padding-top: 96px;
			padding-bottom: 66px;
			p {
				font-size: 24px;
				color: @dark-blue3;
			}
			.buttons-wrapper {
				padding-top: 20px;
				overflow: auto;
				text-align: center;
				button {
					margin: 0 4px;
					margin-bottom: 32px;
				}
			}
		}
		.certificates-approbation-modal {
			width: 100%;
			max-width: 920px;
			padding: 80px 100px 66px;
			text-align: left;
			p {
				font-size: 24px;
				color: @dark-blue3;
			}
			.buttons-wrapper {
				padding-top: 20px;
				text-align: center;
				height: 104px;
				button {
					margin-top: 0;
					margin-bottom: 32px;
					min-width: 130px;
				}
			}
		}
	}
}
.upload-img {
	width: 264px;
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin-top: 29px;
	position: relative;
	cursor: pointer;
	.big-upload-btn {
		background-color: #FBFCFF;
		border: 1px solid #B3B9CB;
		width: 100%;
		height: 149px;
		border-radius: 5px;
		color: @dark-blue3;
		font-weight: bold;
		text-align: center;
		font-size: 16px;
		cursor: pointer;
	}
	input[type=file] {
		font-size: 100px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		cursor: pointer;

	}
	.init {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		&.inline {
			br {
				display: none;
			}
			span {
				display: inline-block;
				transform: translateY(-15px);
				margin-left: 20px;
			}
		}
	}
	.path {
		font-size: 16px;
		text-decoration: underline;
		font-weight: normal;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		&.not-underlined {
			text-decoration: none;
		}
		.title {
			font-size: 18px !important;
			color: #5C6889 !important;
			letter-spacing: .2px;
			text-decoration: none !important;
			text-decoration-color: transparent;
			margin-top: 0 !important;
			margin-bottom: 20px !important;
			line-height: 1.2;
			
			position: relative;
			font-weight: normal;
		}
		.edit {
			margin-top: 10px;
			font-size: 14px;
			color: #628DFF;
			text-decoration: underline;
			margin: 0;
			line-height: 1.2;
		}
		.file {
			font-size: 13px;
			border-bottom: 1px solid #ccc;
			padding: 12px 38px;
			font-weight: bold;
			margin: 0;
			.id-font4 {
				display: inline-block;
    			margin-left: 14px;
    			font-size: 17px;
    			transform: translateY(1px);
    			cursor: pointer;
			}
		}
	}
	.bin {
		display: none;
		position: absolute;
		right: 22px;
		bottom: 17px;
		z-index: 2;
		&:hover {
			opacity: .8;
		}
	}
}
@keyframes fadein {
	from {
		opacity: 0;
		top: 0px;
	}

	to {
		opacity: 100%;
		top: -20px;
	}
}
.question {
	cursor: help;
	position: relative;
	margin-left: 10px;
	&:focus, &:hover {
		.infobulle {
			display: block;
			top: -20px;
		}
	}
	.icon {
		font-weight: normal;
		text-transform: none;
	}
	.infobulle {
		animation-duration: .5s;
		animation-name: fadein;
		display: none;
		position: absolute;
		z-index: 5;
		left: 8px;
		transform: translateX(-50%) translateY(-100%);
		background-color: #fff;
		border: 1px solid #C7C7C7;
		padding: 50px 71px;
		font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
		width: 100vw;
		max-width: 600px;
		line-height: 1.8;
		-webkit-box-shadow: 0px 20px 22px -5px rgba(0,0,0,0.19);
		box-shadow: 0px 20px 22px -5px rgba(0,0,0,0.19);
		&.text-left {
			text-align: left;
		}
		strong {
			font-weight: 600;
		}
		&:after, &:before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}
		&:after {
			border-color: rgba(255, 255, 255, 0);
			border-top-color: #fff;
			border-width: 15px;
			margin-left: -15px;
		}
		&:before {
			border-color: rgba(199, 199, 199, 0);
			border-top-color: #C7C7C7;
			border-width: 16px;
			margin-left: -16px;
		}
	}
}

@media only screen and (max-width: 920px){
	.modal-overlay {
		padding: 0;
		.modal-wrapper {
			.exercise-warranty-modal {
				padding: 0;
				width: 100vw;
				overflow: hidden;
				.modal-header {
					padding: 69px 0 53px 37px;
					.img {
						width: 58px;
						svg {
							margin-top: -30px;
							transform: translateY(15px);
						}
					}
					.txt {
						padding-left: 33px;
						width: calc(100% - 59px);
						h2 {
							display: inline-block;
							margin-left: 0px;
							min-width: auto;
							width: 100%;
							max-width: 100%;
						}
					}
				}
				.form-wrapper {
					padding: 40px 66px 79px 52px;
					h3 {
						margin-bottom: 27px;
					}
					.textarea-file-wrapper {
						display: block;
						margin-bottom: 33px;
						.textarea-wrapper {
							width: 100%;
							label {
								margin-bottom: 14px;
							}
							textarea {
								height: 206px;
							}
						}
						.upload-img {
							width: 100%;
							margin-top: 8px;
							.big-upload-btn {
								height: 89px;
								.init {
									transform: translateY(calc(-50% - 14px)) translateX(-50%);
									svg {
										margin-right: 21px;
										display: inline-block;
										transform: translateY(14px);
									}
									br {
										display: none;
									}
								}
							}
							#message {
								width: 100%;
								height: 206px;
							}
							input[type=file] {
								font-size: 73px;
							}
						}
					}
					.phone-wrapper {
						input {
							padding: 21px 15px;
						}
						label {
							padding-left: 0;
							&.label-authorization {
								padding-left: 37px;
							}
						}
					}
				}
				.buttons-right-wrapper {
					float: none;
					width: 100%;
					text-align: center;
				}
			}
		}
	}
}
@media only screen and (max-width: 430px){
	.modal-overlay {
		.modal-wrapper {
			.exercise-warranty-modal {
				.modal-header {
					padding: 49px 0 23px 37px;
					.img {
					    width: 38px;
					    transform: translateY(-19px);
					}
					.txt {
						strong {
							display: block;
						}
					}
				}
				.form-wrapper {
					padding: 40px 40px 79px 40px;
					.question {
						.infobulle {
							animation-name: none;
							padding: 30px;
							transform: translateX(calc(-50% - 50px)) translateY(-100%);
							&:before, &:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 330px){
	.modal-overlay {
		.modal-wrapper {
			.exercise-warranty-modal {
				.form-wrapper {
					.question {
						.infobulle {
							transform: translateX(calc(-50% - 100px)) translateY(-100%);

						}
					}
				}
			}
		}
	}
}



