.common-appearance {
    animation: appear forwards 0.4s  cubic-bezier(0.785, 0.135, 0.15, 0.86);
    will-change: transform;
    
    // Common staggered animation
    &:nth-child(1) {
        animation-delay: 0.1s;
        opacity: 0;
    }
    &:nth-child(2) {
        animation-delay: 0.2s;
        opacity: 0;
    }
    &:nth-child(3) {
        animation-delay: 0.3s;
        opacity: 0;
    }
    &:nth-child(4) {
        animation-delay: 0.4s;
        opacity: 0;
    }
    &:nth-child(5) {
        animation-delay: 0.5s;
        opacity: 0;
    }
    &:nth-child(6) {
        animation-delay: 0.6s;
        opacity: 0;
    }
    &:nth-child(7) {
        animation-delay: 0.7s;
        opacity: 0;
    }
    &:nth-child(8) {
        animation-delay: 0.8s;
        opacity: 0;
    }
    &:nth-child(9) {
        animation-delay: 0.9s;
        opacity: 0;
    }
    &:nth-child(10) {
        animation-delay: 1s;
        opacity: 0;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translate3d(0,20px,0);
    }
    
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes shake-appear {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    20%, 100% {
        opacity: 1;
        transform: translate3d(-1px, 0, 0);
    }
    
    30%, 90% {
        transform: translate3d(2px, 0, 0);
    }
    
    40%, 60%, 80% {
        transform: translate3d(-4px, 0, 0);
    }
    
    50%, 70% {
        transform: translate3d(4px, 0, 0);
    }
}