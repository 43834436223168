.campaign-menu {
    position: absolute;
    
    @media only screen and (max-width : 665px) {
        display: none;
    }
}

.campaign-list-header {
    width: 100%;
    display: table;
    text-align: left;
    
    span {
        display: table-cell;
        vertical-align: middle;
    }
    
    i {
        font-style: normal;
    }
    
    span {
        p {
            display: inline-block;
            padding-bottom: 10px;
            color: @grey;
            font-weight: @normal;
            font-size: 1em;
            
            @media only screen and (max-width : 665px) {
                font-size: 0.7em;
            }
            
        }
        
        i {
            &:before {
                content: '('
            }
            &:after {
                content: ')'
            }
        }
        
        &.active {
            p {
                font-weight: @bold;
                color: @black;
                border-bottom: solid @black 2px;
            }
        }
        
        +span {
            margin-left: 25px;
        }
        
        &.active-campaign, &.inactive-campaign {
            width: 20%;
            text-align: center;
            cursor: pointer;
        }
        
        &.add-campaign {
            text-align: right;
            
            a {
                padding: 10px 25px;
            }
            
            @media only screen and (max-width : 665px) {
                a {
                    font-size: 0.7em;
                    padding: 10px 15px;
                }
            }
        }
    }
}

.campaign-list {
    li {
        width: 100%;
        position: relative;
        display: block;
        padding: 15px;
        
        .campaign-name, .campaign-information, svg, img, span {
            display: inline-block;
            vertical-align: middle;
        }
        
        .campaign-name {
            text-align: left;
            width: 50%;
        }
        
        .campaign-information {
            text-align: right;
            width: 50%;
            margin-left: -6px;
            
            .campaign-due-date {
                font-size: 0.8em;
                color: @grey;
                margin-right: 15px;
            }
        }
        
        &:nth-child(2n) {
            background-color: rgba(7, 27, 79, 0.02);
        }
    }
}

.row {
    &.boxes {
        max-width: 70%;
        margin: auto;
        
        .box {
            width: 40%;
            height: 0;
            padding-bottom: 40%;
            
            margin: 10px;
            
            border: solid 1px rgba(7, 27, 79, 0.2);
            
            svg, img {
                margin-top: 20px;
                * {
                    transition: all 0.25s ease;
                }
            }
            p {
                max-width: 80%;
                margin: auto;
                color: @black;
                font-size: 0.8em;
                text-transform: uppercase;
            }
            
            cursor: pointer;
            transition: all 0.25s ease;
            &:hover {
                border-color: @red;
                
                svg {
                    .roof, .door, .arrow {
                        fill: @red;
                    }
                }
            }
        }
        
        @media only screen and (max-width : 665px) {
            max-width: 100%;
            
            .box {
                display: inline-block;
            }
        }
        
        @media only screen and (max-width : 410px) {
            .box {
                display: block;
                width: 100%;
                height: auto;
                padding-bottom: 0;
                padding: 15px 0 25px 0;

                svg, img {
                    margin-top: 0;
                }
                
                +.box {
                    margin-top: 10px !important;
                    
                }
            }
        }
    }
}