
.side-menu-page-wrapper .side-menu-container #main-menu {
	.submenu li a {
		&:hover {
			color: #fff !important;
		}
	}
	li.submenu-level2-parent a {
		&:hover {
			color: @dark-blue2 !important;
		}
	}
}
.side-menu-page-wrapper .side-menu-container #main-menu {
	//color: inherit !important;
	.submenu-level2 {
		li a {
			&:hover {
				color: @dark-blue2 !important;
			}
		}
	}
 }
.side-menu-container {
	background-color: #fff;
}
.side-menu-page-wrapper {
	display: flex;
	align-items: stretch;
	.side-part {
		width: 85px;
		background-color: @dark-blue2;
		color: #fff;
		height: 100%;
		height: 100vh;
		position: fixed;
		a {
			display: inline-block;
			width: 85px;
			text-align: center;
			margin: 15px auto;
			position: relative;
			&.logo {
				font-size: 45px;
			}
			&.search {
				margin-top: 22px;
				font-size: 25px;
			}
			&.info {
				margin-bottom: 20px;
				font-size: 40px;
				position: absolute;
				bottom: 0;
			}
		}
	}
	.side-menu-container {
		width: 415px;
		top: 0;
		left: 0;
		display: flex;
		-webkit-box-shadow: -16px 0px 42px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: -16px 0px 42px 0px rgba(0, 0, 0, 0.2);
		box-shadow: -16px 0px 42px 0px rgba(0, 0, 0, 0.2);
		#main-menu {
			min-height: 100vh;
			width: 330px;
			background-color: #fff;
			border: 1px solid @very-light-grey2;
			color: @dark-blue3;
			margin-left: 85px;
			.name-wrapper, .submenu {
				width: 252px;
				margin: 0 auto;
				position: relative;
				&:after {
					content: "";
					position: absolute;
					height: 1px;
					width: 200px;
					bottom: 0;
					left: 20px;
					background-color: @very-light-grey;
				}
				.id-font {
					margin-right: 19px;
					&.campagnes {
						transform: scale(1.2);
					}
				}
			}
			.name-wrapper {
			    font-weight: 600;
			    margin-top: 4px;
				padding: 40px 0 40px 25px;
				.id-font {
					color: @medium-red2;
					transform: scale(1.4);
				}
			}
			.submenu {
			    font-weight: 600;
			    padding: 50px 0;
			    /*&:first-child {
					padding: 25px 0 50px;
				}*/
				.id-font {
				    width: 22px;
				    transform-origin: top left;
				    &.icon-campaigns {

				    }
				    &.icon-certificates {
				    	transform: scale(1.6) translateX(1px) translateY(-2px);
				    }
				    &.icon-data {
				    	transform: scale(1.1) translateX(3px) translateY(-2px);
				    }
				    &.icon-warranties {
				    	transform: scale(1.1) translateX(3px) translateY(-2px);
				    }
				    &.icon-insurance {
				    	transform: scale(1.2) translateX(5px) translateY(-1px);
				    }
				    &.icon-reporting {

				    }
				    &.icon-transfer {
						transform: scale(1.1);
				    }
				    &.icon-messages {
						transform: scale(1.2);
				    }
				    &.icon-alerts {
						transform: scale(1.2);
				    }
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				li {
					background-color: transparent;
					border: 1px solid transparent;
					a {
						padding-left: 20px;
						width: 100%;
						position: relative;
						&:before {
							z-index: -1;
							content: "";
							background-color: @dark-blue3;
							border: 1px solid @dark-blue3;
							color: #fff;
							border-radius: 12px;
							position: absolute;
							top: -18px;
							left: 0px;
							height: 57px;
							width: 100%;
							opacity: 0;
							transition: all .2s;
						}
					}
					.submenu-level2 {
						display: none;
						li {
							transition: all 1s;
						}
					}
				}
				li:not(.submenu-level2-parent) {
					transition: all 1s;
					a {
						&:hover {
							color: #fff;
							z-index: 1;
							&:before {
								opacity: 1;
							}
						}
						.count {
							font-weight: bold;
							color: #a9afc0;
							position: relative;
							margin-left: 18px;
							z-index: 1;
							padding: 0 12px;
							&:before {
								z-index: -1;
								content: "";
								background-color: #F6F6F6;
								width: 100%;
								height: 31px;
								border-radius: 16px;
								position: absolute;
								left: 0;
								top: -5px;
							}
						}
					}
					.submenu-level2 {
						li {
							opacity: 0;
						}
					}
					&:not(:last-child) {
						margin-bottom: 40px;
					}
				}
				.submenu-level2-parent {
					transition: background-color 1s;
					background-color: @very-light-blue;
					border: 1px solid @very-light-blue2;
					border-radius: 10px;
					padding: 15px 0 37px 0;
					margin-top: -14px;
					margin-bottom: 38px;
					}
					.submenu-level2 {
						margin-top: 44px;
				    	font-weight: 400;
						li {
							padding-left: 46px;
				    		opacity: 1;
							&.current {
								font-weight: bold;
							}
							a {
								padding-left: 0px;
								color: @dark-blue3;
								transition: all .2s;
								&:before {
									display: none;
								}
								&:hover {
									opacity: .7;
									color: @dark-blue3;
								}
							}
						}
						> li:not(:last-child) {
							margin-bottom: 25px;
						}
					}
			}
		}
	}
	.datas-container {
		width: 100%;
		header {
			width: 100%;
			padding: 22px;
			.top-menu {
				color: @dark-blue2;
				font-size: 25px;
				float: right;
				li {
					display: inline-block;
					margin-left: 15px;
					&:last-child {
						margin-left: 16px;
						padding-left: 17px;
						position: relative;
						&:before {
							content: "";
							position: absolute;
							background-color: @very-light-grey2;
							height: 28px;
							width: 1px;
							left: 0px;
							top: 0px;
						}
					}
					a {
						transition: all .2s;
						position: relative;
						&:hover {
							opacity: .8;
						}
						.count {
							position: absolute;
							top: 11px;
							left: 13px;
							background-color: #f00;
							color: #fff;
							font-weight: bold;
							font-size: 12px;
							padding: 0 3px 2px 2px;
							border: 1px solid #fff;
							letter-spacing: -1px;
							border-radius: 5px;
						}
					}
				}
			}
		}
		main {
			width: 100%;
			max-width: 978px;
			padding: 0 20px;
		    margin: 63px auto;
		    h1 {
		    	font-size: 55px;
    			font-weight: 600;
    			color: @dark-blue2;
    			margin-bottom: 12px;
    			.id-font {
    				transform: scale(.9) translateX(-4px) translateY(3px);
    				margin-right: 5px;
    			}
		    }
		    .tabs {
				font-weight: 600;
				letter-spacing: 0.5px;
				width: 100%;
				border-bottom: 1px solid @very-light-grey2;
				ul {
					display: flex;
					align-items: stretch;
					li {
						color: @blue-grey;
						display: inline-block;
						position: relative;
						&.current {
							color: @dark-blue2;
							&:after {
								content: "";
								position: absolute;
								background-color: @dark-blue2;
								height: 1px;
								width: 100%;
								bottom: -1px;
							}
						}
						a {
							padding: 20px 20px;
							transition: all .2s;
							&:hover {
								color: @dark-blue2;
							}
						}
						&:first-child {
							a {
								padding-left: 0;
							}
						}
					}
				}
		    }
		    .container {
		    	margin: 20px 0;
		    }
		}
	}
	#main-footer {
		padding: 22px;
		color: @dark-blue2;
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 13px;
		a {
			text-decoration: underline;
			font-weight: 600;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
/* Intégration des modules existants */
.common-wrapper.wallet-wrapper, .common-wrapper.warranties-wrapper {
	margin-top: 0;
}
.side-menu-page-wrapper {
	main {
		h1 {
			margin-bottom: 30px;
		}
	}
	// Paramètres
	.common-frame.details,
	.common-frame.details .frame-header,
	.side-menu-page-wrapper .datas-container header,
	.manual {
		padding-left: 0;
	}
	.account-settings .common-label {
	    font-weight: 600;
	}
	hr {
	    color: #cdd1dc;
	    background-color: #cdd1dc;
	    height: 1px;
	    border: 0;
	    margin-bottom: 25px;
	}
	.account-settings .account-id,
	.account-settings .account-mail,
	.account-settings .email_warranty,
	.account-settings .entity-name {
	    padding-bottom: 20px;
	}
	.common-input-file.common-add-image-input:hover {
	    background-color: rgba(7,27,79,.05);
	}
	.button-main.hovered:not(.disabled), .button-main:hover:not(.disabled) {
	    box-shadow: 5px 10px 20px 0 rgba(7,27,79,.2);
	}
	.update-pen {
	    cursor: pointer;
	}
	.upload-btn-wrapper {
	    position: relative;
	    overflow: hidden;
	    display: inline-block;
	    vertical-align: bottom;
	    transition: all 0.25s ease;
	    &:hover {
	    	box-shadow: 5px 10px 20px 0 rgba(7,27,79,.2);
	    }
	}
	.upload-btn-wrapper input[type=file] {
	    font-size: 100px;
	    position: absolute;
	    left: 0;
	    top: 0;
	    opacity: 0;
	}
	.manual {
	    font-size: 1em;
	    text-align: left;
	    padding-top: 25px;
	}
	.library-step {
		display: none;
	}
	#library-step1 {
		display: block;
	}
	// Campagnes
	.row.boxes {
		max-width: 420px;
		.box {
			width: 40%;
		}
	}
	.lang-select {
		position: relative;
	    width: 100px;
	    margin: 0 auto;
	}
	.modal-header-title {
		width: 100%;
		text-align: center;
	}
	.row.boxes .box.campaignKind {
	    border-color: #ae3737;
	}
}
@media only screen and (max-width : 1280px) {
	.side-menu-page-wrapper {
		.side-part {
			width: 65px;
			a {
				width: 65px;
				font-size: 35px;
			}
		}
		.side-menu-container {
			#main-menu {
				margin-left: 65px;
				width: 270px;
			}
		}
	}
}
@media only screen and (max-width : 1280px) {
	.side-menu-page-wrapper {
		.side-part {
			width: 65px;
			a {
				width: 65px;
				font-size: 35px;
			}
		}
		.side-menu-container {
			width: 335px;
			#main-menu {
				margin-left: 65px;
				width: 270px;
			}
		}
	}
}
.side-menu-page-wrapper .side-part a.info {
	display: none;
}
@media only screen and (min-width : 1241px) {
	.burger {
		display: none;
	}
	.side-menu-container {
		.close {
			display: none;
		}
	}
}
@media only screen and (max-width : 1240px) {

	body.hidden {
		overflow: hidden;
	}
	.burger {
		cursor: pointer;
		font-size: 26px;
		position: absolute;
		top: 22px;
		left: 26px;
		color: @dark-blue3;
	}
	.top-menu {
		ul {
			li {
				&:nth-child(2) {
					display: none !important;
				}
			}
		}
	}
	.side-part, .side-menu-container {
		position: fixed;
		top: 0;
		left: 0;
		transform: translateX(-100%);
		transition: all .3s;
		z-index: 10;
		&.on {
			transform: translateX(0);
		}
	}
	.side-menu-container.on {
		overflow: scroll;
   		max-height: 100vh;
	}
	#main-menu {
		border-bottom: none !important;
	}
	.side-part {
		z-index: 11;
	}
	.side-menu-container {
		.close {
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
			color: @dark-blue3;
			font-size: 12px;
			z-index: 1;
			opacity: 1;
		}
	}
	.side-menu-page-wrapper {
		.datas-container {
			main {
				h1 {
    				font-size: 45px;
    			}
    		}
    	}
    }
}
@media only screen and (max-width : 680px) {
	.side-menu-page-wrapper .datas-container main h1 {
	    font-size: 35px;
	}
}








