.pagination {
    text-align: center;
    padding-top: 15px;

    svg, .index-number, li {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }

    svg {
        margin: 0 10px;
    }

    .index-number {
        li {
            border:solid 1px @dark-blue;
            padding: 2px 7px;
            margin: 0 1px;

            border-radius: 3px;

            font-weight: @normal;
            color: @dark-blue;

            &.active {
                background-color: @dark-blue;
                font-weight: @bold;
                color: white;
            }
        }
    }
    @media only screen and (max-width : 480px) {
        display: none;
    }
}