[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: @dark-blue;
    user-select: none;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 25px;
    width: 18px;
    height: 18px;
    border: 1px solid @dark-blue;
    border-radius: 100%;
    background: white;
}
[type="radio"]:checked + label:before {
    background-color: @dark-blue;
}

[type="radio"] {
    &.disabled {
        pointer-events: none;
        +label {
            pointer-events: none;
            &:before {
                border-color: @light-grey;
            }
        }
    }
}



[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background:white;
    position: absolute;
    top: 30px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}