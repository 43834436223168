.wallet-frame {
    border-radius: 2px;
    box-shadow: 0 0 20px 0 #e7e1cd;
    background-color: #ffffff;
    margin-bottom: 100px;
}

.login-page {
    .wallet-frame {
        margin-bottom: 35px;
    }
}