.main-footer {
    position: absolute;
    width: 100%;
    height: 250px;
    bottom: 0;

    .powered-by {
        bottom: 15px;
    }

    @media only screen and (max-width : 665px) {
        position: relative;
        height: 60px;
    }
}

// Wallet footer

.wallet {
    .left-illustration {
        #lines {
            path {
                stroke: #f3e8d5;
            }
        }
        #dots {
            circle {
                fill: #f3e8d5;
            }
        }
    }
}

.illustrations-footer {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;

    .left-illustration, .right-illustration {
        position: absolute;
        overflow: hidden;
        bottom: 0;
    }
    .right-illustration {
        right: 0;
        transform: translate3d(15px,0,0);
    }
    .left-illustration {
        left: 0;
        transform: rotate(90deg);
        
        circle {
            fill: @beige;
        }

        path {
            stroke: @beige;
        }
    }
    @media only screen and (max-width : 665px) {

        .left-illustration {
            display: none;
        }
        .right-illustration {
            transform-origin: bottom right;
            transform: translate3d(-75px, 0, 0) scale(0.4) rotate(90deg);
        }
    }
}