textarea {
    width: 100%;
    appearance: none;
    background: white;
    outline: none;
    border: solid 1px @dark-beige;
    border-radius: 2px;
    padding: 10px 15px;
    transition: all 0.25s ease;
    height: 100px;
    max-height: 100px;
    resize: none;
    
    &::placeholder {
        color : @light-grey;
        font-weight: @normal;
        font-size: 1em;
    }
    
    &:focus, &.on-focus {
        border-color: @dark-blue;
        box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
    }
}