.button-secondary {
    appearance: none;
    outline: none;
    border: none;

    padding: 15px 25px;
    border-radius: 2px;

    background-color: white;
    color: @dark-blue;
    border:solid 1px @dark-blue;
    font-weight: @bold;
    line-height: 1.29em;

    cursor: pointer;

    transition: all 0.25s ease;

    img, svg, .id-font {
        display: inline-block;
        vertical-align: middle;

        width: 20px;
        height: 20px;

        // Due to the svg viewbox
        margin-top: -2px;
        // Used to center the font & icon for the eye
        margin-right: 5px;
        margin-left: -5px;

        fill: @dark-blue;
        stroke: @dark-blue;

        line {
            stroke: @dark-blue;
        }
    }


    &:hover, &.hovered {
        &:not(.disabled) {
            background-color: rgba(7, 27, 79, 0.05);
        }
    }
    &:active,&.pressed {
        &:not(.disabled) {
            background-color: rgba(7, 27, 79, 0.1);
        }
    }
    &.disabled {
        background-color: white;
        color: @grey;
        border-color: @grey;
    }


    &.red {
        border-color: @red;
        color: @red;
    }
}