.main.center-blocks {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	flex-direction: column;
	padding: 200px 0;
	> div:first-child {
		margin-bottom: 200px;
	}
}
.search-results-2 {
	.count-results, .cards-list {
		max-width: 800px;
	    margin-left: auto !important;
	    margin-right: auto !important;
	}
}
.authent-block.shop-card {
	// Modif centrage vertical
	.block-content {
		.img-wrapper {
			transform: translateY(0) !important;
		}
		.details-block {
			display: flex;
			align-items: center;
			.details-btn-wrapper {
				padding-top: 0 !important;
				table {
					margin-top: 0 !important;
					tbody {
						tr:last-child {
							td {
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	//
	.auth-block-content {
		width: calc(100% - 194px);
	}
	&.design-watchfid {
		.auth-block-content {
			background-image: url(../img/watchfid-small.svg);
			background-size: 250px;
			background-repeat: no-repeat;
			background-position: right center;
		}
		.auth-block-top-bar {
			background-color: #8EBEE2 !important;
			.bubble {
				//color: #4C4C4C !important;
			}
		}
		.see-btn {
			background-color: #4C4C4C;
			color: #fff;
			.arrow {
				&:before, &:after {
					background-color: #fff;
				}
			}
		}
		tr {
			td {
				//color: #4C4C4C;
				&:first-child {
					//color: #999999;
				}
			}
		}
		.serial-wrapper {
			//background-color: #F7F5F5;
		}
		.background {
			display: none;
		}
	}
	&.waiting-accept {
		position: relative;
		&:after {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: calc(100% - 49px);
			background-color: #0A1B4F;
			opacity: .35;
		}
	}
	&.sent {
		position: relative;
		&:after {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: #4C4C4B;
			opacity: .35;
		}
		.see-btn {
			background-color: #E6E1CE;
			color: @dark-blue3;
			//font-style: italic;
		}
	}
	&.robbery-card {
		.see-btn {
			background-color: #E6E1CE;
			color: #c20000;
			font-weight: bold;
			.icon-wrapper {
				//background-color: #c20000;
				color: #fff;
				display: inline-block;
				text-align: center;
				margin-right: 10px;
				border-radius: 50%;
				width: 21px;
				height: 21px;
				//transform: translateY(15px);
				transform: translateY(6px);
				position: relative;
				span {
					transform: translateY(-14px);
					display: inline-block;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 21px;
					height: 21px;
					object-fit: contain;
				}
			}
		}
	}
}
.authent-block {
	text-decoration: none !important;
	width: 100%;
	max-width: 1096px;
	/*-webkit-box-shadow: 0px 18px 30px 0px rgba(145,145,145,.5);
	-moz-box-shadow: 0px 18px 30px 0px rgba(145,145,145,.5);
	box-shadow: 0px 18px 30px 0px rgba(145,145,145,.5);*/
	-webkit-box-shadow: 0px 8px 8px 0px rgba(145,145,145,.5);
	-moz-box-shadow: 0px 8px 8px 0px rgba(145,145,145,.5);
	box-shadow: 0px 8px 8px 0px rgba(145,145,145,.5);
	background-color: #fff;
	margin-bottom: 50px;
	cursor: pointer;
	transition: all .2s;
	-webkit-transition: all .2s;
	&:not(.main-wrapper) {
		&:hover {
			transform: translateY(-10px);
		-webkit-box-shadow: 0px 12px 12px 0px rgba(145,145,145,.6);
		-moz-box-shadow: 0px 12px 12px 0px rgba(145,145,145,.6);
		box-shadow: 0px 12px 12px 0px rgba(145,145,145,.6);
		}
	}
	.auth-block-top-bar {
		display: flex;
		justify-content: space-between;
		background-color: #f4f4f4;
		color: @dark-blue3;
		padding: 7px;
		.bubble {
			border-radius: 18px;
			-webkit-border-radius: 18px;
			display: inline-block;
			background-color: #fff;
			padding: 0 12px;
			margin: 0 7px;
			line-height: 35px;
			-webkit-box-shadow: 0px 0px 4px 0px rgba(145,145,145,.8);
			-moz-box-shadow: 0px 0px 4px 0px rgba(145,145,145,.8);
			box-shadow: 0px 0px 4px 0px rgba(145,145,145,.8);
			font-weight: 500;
			font-size: 13px;
			.id-font {
				margin: 0 7px;
			}
			&.status-color {
				padding-left: 35px;
				position: relative;
				&:before {
					position: absolute;
					content: "●";
					font-size: 30px;
					left: 10px;
					top: 50%;
					transform: translateY(calc(-50% - 2px));
				}
				&.green {
					&:before {
						color: #4BD053;
					}
				}
				&.red {
					&:before {
						color: #FF0000;
					}
				}
				&.orange {
					&:before {
						color: #CF7C00;
					}
				}
			}
			&.transparent-bubble {
				background-color: transparent;
				box-shadow: none;
				padding-left: 0;
				&:before {
					-webkit-transform: translateY(calc(-50% - 5px)) translateX(-13px);
    				transform: translateY(calc(-50% - 5px)) translateX(-13px);
				}
			}
		}
	}
	.auth-block-content {
		background-color: #fff;
		display: flex;
	}
	.number-wrapper {
		//width: 225px;
		width: 255px;
		background-color: #EAF0F6;
		color: @dark-blue3;
		font-size: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'Source Sans Pro', sans-serif;
		.number {
			width: 100%;
			text-align: center;
			font-weight: bold;
			transform: translateY(-20px);
			.legend {
				//font-size: 15px;
				//margin-top: -16px;
				margin-bottom: 16px;
				font-size: 19px;
    			margin-top: -9px;
			}
		}
	}
	.details-block {
		//width: calc(100% - 225px);
		width: 100%;
		.details-btn-wrapper {
			width: 100%;
			padding: 59px 0 13px;
			display: flex;
			align-items: center;
			position: relative;
		}
		table {
			width: 100%;
			//min-width: 557px;
			//max-width: 557px;
		}
		tr {
			td:first-child {
				//width: 247px;
				width: 207px;
				color: #747F9C;
				font-size: 11px;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 1px;
				.dots {
					position: relative;
					width: 100%;
					overflow: hidden;
					&:before {
						content: "..........................";
						opacity: .6;
						letter-spacing: 7px;
						font-size: 8px;
						position: absolute;
						top: 1px;
						left: 0;
					}
					span {
						background-color: #fff;
						position: relative;
						z-index: 1;
						padding-right: 8px;
					}
				}
			}
			td {
				color: @dark-blue3;
				padding-left: 26px;
				padding-bottom: 20px;
				//font-size: 18px;
				font-weight: bold;
			}
		}
		.btn-wrapper {
			text-align: center;
			//width: 100%;
			position: absolute;
		    right: 30px;
		    bottom: 40px;
		}
	}
	.see-btn {
		cursor: pointer;
		//background-color: #E6E0CE;
		background-color: @dark-blue3;
		line-height: 53px;
		//color: @dark-blue3;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		font-weight: bold;
		font-size: 13px;
		/*transition: .4s all;
		&:hover {
			opacity: .7;
		}*/
		.arrow {
			display: inline-block;
			margin-left: 4px;
			position: relative;
			&:before, &:after {
				content: "";
				position: absolute;
				background-color: #fff;
				height: 2px;
				width: 7px;
			}
			&:before {
				transform: rotate(45deg);
				top: -6px;
				right: -10px;
			}
			&:after {
				transform: rotate(-45deg);
				top: -6px;
				right: -14px;
			}
		}
	}
}
.authent-block.shop-card.wallet-card {
	.auth-block-top-bar {
		background-color: #f8f8f9;
	}
}
.button-main.green-validate {
	background-color: #DAF4DF;
	border-color: #DAF4DF;
	color: #4AB95D !important;
	position: relative;
	/*padding-left: 48px;
	&:before {
		color: #fff;
		content: "v";
		font-family: 'Goods ID2';
		font-size: 9px;
		margin-right: 15px;
		background-color: #4AB95D;
		border-radius: 50%;
		width: 20px;
		line-height: 20px;
		display: inline-block;
		position: absolute;
		top: calc(50% - 10px);
		left: 15px;
	}*/
}
.vintage-card {
	.auth-block-top-bar {
		background-color: @brown !important;
		> div {
			.bubble {
				.date {
					display: inline !important;
				}
			}
		}
	}
}
.authent-block.shop-card {
	//display: flex;
	width: 100%;
	max-width: 1000px;


    //animation-delay: 1s;
    //-webkit-animation: appear 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    //animation: appear 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);

	.auth-block-top-bar {
		background-color: @dark-blue3;
		padding-left: 26px;
		padding-right: 26px;
	}
	.certificat-number {
		.logo {
			margin-left: 5px;
			margin-right: 3px !important;
		}
	}
	.bubble {
		.logo {
			display: inline-block;
			transform: scale(1.2);
			margin-right: 13px;
		}
	}
	.img-wrapper {
		width: 301px;
		padding: 20px;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.block-content {
		//width: calc(100% - 301px);
		width: 100%;
		display: flex;
		position: relative;
	}
	.serial-wrapper {
		//background-color: #F4F2E9;
		//padding: 6px 10px;
		//margin-left: -11px;
	}
	.details-block tr td {
		letter-spacing: 1px;
		&:first-child {
			padding-left: 33px;
			padding-right: 26px;
		}
	}

	.background {
		position: absolute;
		font-size: 180px;
		right: 65px;
		top: calc(50% - 132px);
		color: #F8F8F9;
	}
}
.module-auth.authent-block {
	.auth-block-top-bar {
		//background-color: #E6E0CE;
		background-color: #f4f4f4;
		padding: 12px;
		.bubble {
			.logo {
				margin-right: 5px;
			}
		}
	}
	.see-btn {
		//background-color: #f4f4f4;
		background-color: #E6E0CE;
		color: @dark-blue3;
		.arrow {
			&:before, &:after {
				background-color: @dark-blue3;
			}
		}
		span {
			text-transform: none;
		}
	}
	.details-block {
		.prep {
			display: block;
		}
		table {
			padding-right: 20px;
			tr {
				td {
					.limited {
						max-width: calc(100% - 38px);
					    display: inline-block;
					    word-break: break-word;
					}
				}
			}
		}
	}
	.btn-wrapper {
	    bottom: 50% !important;
    	transform: translateY(50%) !important;
    	button {
    		box-shadow: none !important;
    	}
	}
}
.authent-block.shop-card {
	.details-btn-wrapper {
		//padding: 29px 0 0px;
		padding: 0 !important;
		table {
			margin-top: 29px;
		}
	}
	.img-wrapper {
    	width: 194px;
		max-height: 194px;
    }
    .background {
	    font-size: 100px;
	    //top: calc(50% - 76px);
	    //top: calc(50% - 48px);
	    top: calc(50% - 57px);
	}
}
.bubble {
	&.check {
		margin-right: 4px;
		&:before {
			color: #fff;
			content: "v";
			font-family: 'Goods ID';
			font-size: 10px;
			margin-right: 6px;
			background-color: #4BD053;
			border-radius: 50%;
			width: 16px;
			line-height: 16px;
			display: inline-block;
			text-align: center;
			margin-top: -3px;
			transform: translateY(-1px);
		}
		&.question {
			background-color: transparent !important;
			box-shadow: none !important;
			&:before {
				transform: scale(2.2) translateY(0px);
			}
			.infobulle {
				padding: 10px 15px;
				width: auto;
				right: -127px;
				left: inherit;
				&:before, &:after {
					left: inherit !important;
					right: 21px;
				}
			}
		}
	}
}
.wallet-list-wrapper {
	font-family: 'Source Sans Pro';
	padding-top: 30px;
	margin-top: 80px;
	.see-btn {
		font-size: 13px;
		line-height: 49px;
		.arrow {
			background-color: #E6E0CE;
			transform: rotate(-90deg) translateX(-4px) translateY(11px);
		}
		span {
			text-transform: none;
			font-weight: normal;
		}
	}
	.cards-wrapper {
		.title-wrapper {
			width: 100%;
			text-align: left;
			position: relative;
			padding: 0 0 30px 0;
			cursor: pointer;
			.arrow {
				position: absolute;
			    right: 20px;
			    top: 20px;
				&:before, &:after {
					content: "";
				    position: absolute;
				    background-color: @dark-blue3;
				    height: 2px;
				    width: 7px;
				}
				&:before {
					-webkit-transform: rotate(45deg);
				    transform: rotate(45deg);
				    top: -6px;
				    right: -10px;
				}
				&:after {
					-webkit-transform: rotate(-45deg);
				    transform: rotate(-45deg);
				    top: -6px;
				    right: -14px;
				}
				&.closed {
					transform: scaleY(-1) translateY(12px);
				}
			}
		}
		.title {
			color: @dark-blue3;
			font-size: 1.8rem;
			margin-bottom: 2rem;
			background-color: #E6E0CE;
			padding: 0 20px 0 10px;
			strong, em {
				display: inline;
				font-style: normal;
			}
			strong {
				font-weight: 700;
			}
			em {
				font-weight: 400;
			}
		}
		.line {
			position: absolute;
			height: 1px;
			width: calc(100% - 40px);
			background-color: @dark-blue3;
			top: 14px;
			left: 0;
			z-index: -1;
		}
		.cards {
			clear: both;
			overflow: auto;
			padding-top: 10px;
			&:after {
				content: "";
				display: table;
			}
		}
	}
	.authent-block.shop-card {
		position: relative;
		/*margin-right: 30px;
		margin-left: 30px;
		width: calc(50% - 60px);*/
		margin-right: 10px;
		margin-left: 10px;
		width: calc(50% - 20px);
		float: left;
		.auth-block-content {
			background-image: none;
		}
		&.design-watchfid {
			.background.id-font {
				display: none;
			}
		}
		.bubble {
			font-size: 11px;
			padding: 0px 11px 0 9px;
			margin: 0;
			.date {
				display: none;
			}
			.logo {
				margin-right: 4px;
			}
			&.check {
				&:before {
					margin-right: 4px;
				}
			}
		}
		.block-content {
			width: 100%;
		}
		.img-wrapper {
			//position: absolute;
			width: 180px;
			height: 180px;
			//top: 21px;
			//left: 17px;
			//padding: 10px;

	    	padding: 29px;
	    	transform: translateY(5px);
		}
		.block-content {
			.auth-block-top-bar {
			    padding-left: 10px;
    			padding-right: 10px;
			}
		}
		.details-btn-wrapper {
			padding-top: 25px;
			padding-bottom: 0;
		}
		.details-block {
			//padding-left: 178px;
			.prep {
				display: block;
			}
			table {
				display: block;
				tr {
					td {
						//display: block;
						font-size: 13px;
						padding-left: 0 !important;
						padding-bottom: 20px;
						//width: auto !important;

						&:first-child {
							font-size: 10px;
							//padding-bottom: 2px;
							padding-right: 0 !important;
							width: 66px;
							.dots {
								&:before {
									top: -1px;
								}
							}
						}
						/*.dots {
							&:before {
								display: none;
							}
						}*/
						.serial-wrapper {
							/*background-color: transparent;
							padding: 0 !important;*/
							margin-left: 0;
						}
					}
				}
			}
		}
		.background {
			font-size: 90px;
    		right: 20px;
    		top: calc(50% - 65px);
		}
	}
}
.module-auth-2.authent-block {
	.number-wrapper {
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: stretch;
		padding: 0 20px;
		.number {
			background-color: #EAF0F6;
			padding-top: 10px;
			transform: none;
		}
	}

	.details-block {
		.details-btn-wrapper {
			padding: 40px 0;
			align-items: stretch;
		}
		tr {
			td {
				padding: 10px 0;
			}
		}
		.btn-wrapper {
			.button-main {
				padding: 13px 15px;
				font-size: 14px;
				background-color: transparent;
				box-shadow: none !important;
				color: @dark-blue3 !important;
				border-color: transparent;
				position: relative;
			}
			.id-font {
				margin-left: 4px;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	.see-btn {
		background-color: @dark-blue3;
		color: #fff;
		.arrow {
			transform: rotate(-90deg) translateX(-4px) translateY(10px);
			&:before, &:after {
				background-color: #fff;
			}
		}
	}
	.status {
		.bubble {
			box-shadow: none;
			background-color: transparent;
		}
	}
}
.status {
	.bubble {
		box-shadow: none;
		background-color: transparent;
	}
}

	.icon-circle {
		border-radius: 50%;
		background-color: #E0E0E0;
		color: #fff;
		text-align: center;
		width: 27px;
		height: 27px;
		display: inline-block;
		margin-top: 4px;
		//transform: scale(1.3);
		box-shadow: 0px 0px 4px 0px rgba(145, 145, 145, 0.8);
		&.green {
			background-color: #4AD600;
		}
		&.red, &.orange {
			display: flex !important;
			margin-right: 20px;
			box-shadow: none;
			justify-content: center;
			align-items: center;
		}
		&.red {
			background-color: #FEEEEE;
			color: #D56E6E !important;
		}
		&.orange {
			background-color: #FEF2E7;
			color: #F39138 !important;
		}
		span {
			transform: translateY(3px) translateX(0px) scale(1.2);
		}
		&.light-green {
			color: rgba(75,208,83,1);;
			background-color: rgba(75,208,83,.1);
			height: 22px;
			width: 22px;
			box-shadow: none;
			font-size: 11px;
			padding-top: 2px;
			margin-right: 17px;
		}
		&.off {
			background-color: #fff;
			border: 1px solid #e4e9ed;
			//color: #000;
			color: #e4e9ed;
			position: relative;
			span {
				display: block;
			}
			/*&:after {
				content: "";
				background-color: #e4e9ed; 
				height: 1px;
				width: 16px;
				border: 1px #fff solid;
				transform: rotate(-45deg) ;
				position: absolute;
				top: 8px;
				left: 1px;
			}*/
		}
	}
.certificat-popin.my-certificat-wrapper {
	position: fixed !important;
	width: calc(100vw - 20px);
	height: calc(100% - 20px);
	border: 3px solid #fff;
	top: 10px;
	left: 10px;
	z-index: 30;
	background-color: #E6E0CE;
	margin-top: 0 !important;
	max-width: 100vw !important;
	overflow: auto;
	align-items: start;
	padding-top: 60px;
	display: none !important;
	opacity: 0;
	transition: .3s all;
	animation-name: appear;
	animation-duration: .3s;
	&.on {
		display: flex !important;
		opacity: 1;
	}
	.main-wrapper {
		margin-right: auto;
		margin-left: auto;
	}
	.close-certificat-popin {
		position: absolute;
	    width: 20px;
	    height: 20px;
	    top: 25px;
	    right: 35px;
	    cursor: pointer;
	    transition: all .3s;
	    &:hover {
	    	opacity: .7;
	    }
		&:before, &:after {
			height: 2px;
    		background-color: #061A4F;
			content: '';
		    position: absolute;
		    top: 9px;
		    left: 0;
		    width: 100%;
		    height: 3px;
		    border-radius: 2px;
		    background: black;
		    -webkit-transform: rotate(45deg);
		    transform: rotate(45deg);
		}
		&:after {
			-webkit-transform: rotate(-45deg);
    		transform: rotate(-45deg);
		}
	}
}
.open-certificat-popin {
	text-decoration: underline;
}
.authent-block.insurance-card {
	position: relative;
	&:before {
		position: absolute;
		content: "";
		border-radius: 50%;
		background-color: #9B9B9B;
		width: 10px;
		height: 10px;
		top: 4px;
		right: 4px;
	}
	&.processing {
		&:before {
			background-color: #CF7C00;
		}
	}
	.auth-block-content {
		.details-block {
			.details-btn-wrapper {
				display: block;
			}
		}
		.prep {
			display: block;
			font-size: 14px;
			color: #747F9C;
			//padding-left: 36px;
			margin-bottom: 18px;
			margin-top: 5px;
			.id-font {
				margin-right: 5px;
				display: inline-block;
			}
		}
		.details-block tr td:first-child {
			padding-right: 10px;
		}
		.details-block tr td {
			padding-left: 0;
		}
	}
	.img-wrapper {
		padding: 30px;
	    width: 214px;
    	max-height: 214px;
    	img {
    		border-radius: 5px;
    	}
	}
	.value-block {
	    padding: 30px;
	    display: flex;
	    align-content: center;
	}
	.value-wrapper {
		background-color: #ECECEC;
		padding: 25px 17px;
		font-weight: bold;
	    display: flex;
	    align-content: center;
	    flex-direction: column;
	    justify-content: center;
		p {
			color: #071B4F;
			text-transform: uppercase;
			opacity: .5;
			font-size: 12px;
			margin-top: 0;
			margin-bottom: 5px;
		}
		.value {
			color: #071B4F;
			font-size: 21px;
			margin-top: 5px;
		}
	}
	tr {
		td {
			padding-bottom: 9px;
		}
	}
}
@media only screen and (max-width : 1239px) {
	.certificat-popin {
		padding-top: 0 !important;
	}
	.module-auth-2 {
		.number-wrapper {
			position: initial !important;
			top: inherit !important;
			left: inherit !important;
			height: auto !important;
			width: 255px !important;
			padding-left: 0 !important;
			.number {
				display: flex;
			    flex-direction: column;
			    justify-content: center;
			}
		}
		.status {
			.bubble {
				&:not(:last-child) {
					margin-right: 10px !important;
				}
			}
		}
	}
	.details-block {
		table {
			padding-left: 7px !important;
		}
	}
	.module-auth.authent-block {
		.bubble {
			font-size: 12px;
			&.status-color {
				background-color: transparent;
				box-shadow: none;
				padding-left: 0;
				margin-left: 0;
				padding-right: 25px;
				strong {
					display: none;
				}
			}
		}
		.status {
			.bubble {
				&:not(:last-child) {
					margin-right: -3px;
				}
				.logo {
				    margin: 0 4px;
				}
			}
		}
		.auth-block-content {
			position: relative;
			.number-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				height: calc(100% - 44px);
				width: 97px;
				.number {
					font-size: 50px;
				    transform: translateY(-5px);
					.legend {
						font-size: 12px;
						//margin-top: -7px;
					}
				}
			}
		}
		.details-block {
			.see-btn {
				line-height: 44px;
				font-size: 12px;
				.arrow {
				    margin-bottom: -2px;
				}
			}
			.details-btn-wrapper {
			    padding: 19px 16px 13px;
			}
			table {
				display: block;
				padding-left: 97px;
				tr {
					td {
						display: block;
						font-size: 12px;
						//font-weight: normal;
						padding-left: 0 !important;
						padding-bottom: 4px;
						width: auto !important;
						&:first-child {
							font-size: 12px;
							padding-bottom: 2px;
							padding-top: 2px;
						}
						&:last-child {
							padding-top: 0;
						}
						.dots {
							&:before {
								display: none;
							}
						}
						.serial-wrapper {
							background-color: transparent;
							margin-left: 0;
							padding: 0 !important;
						}
						.limited {
							max-width: calc(100% - 45px);
						    display: inline-block;
						    word-break: break-word;
						}
					}
				}
			}
			.btn-wrapper {
				//right: -13px;
    			//bottom: 12px;
				button {
					font-size: 10px;
					//width: 70%;
					&:before {
						//display: none;
					}
				}
			}
		}
	}
}
.common-wrapper-free {
	padding-top: 120px;
}
.shop-card-open {
	margin-bottom: 80px;
	.shop-card {
		margin-bottom: 0;
		margin-right: auto;
		margin-left: auto;
		cursor: auto;
		box-shadow: 0px 8px 8px 0px rgba(145, 145, 145, 0.5);
		&:hover {
		    transform: none;
			box-shadow: 0px 8px 8px 0px rgba(145, 145, 145, 0.5);
		}
	}
	.authent-block.shop-card, .form {
		max-width: 866px;
	}
	.form {
		margin-right: auto;
		margin-left: auto;
		position: relative;
		z-index: 1;
		//width: calc(100% - 60px);
		margin-top: -15px;
		//margin-right: 30px;
    	//margin-left: 30px;
		font-family: 'Source Sans Pro', sans-serif;
		color: @dark-blue3;
	}
	.transfert-wrapper {
		box-shadow: 0px 8px 8px 0px rgba(145, 145, 145, 0.5);
		background-color: #E6E1CE;
		padding: 56px 110px 56px;
		display: flex;
		//flex-direction: column;
		justify-content: space-between;
		align-items: center;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		.email {
			width: 374px;
			transform: translateY(-12px);
			label, input {
				text-align: left;
				display: block;
				font-family: 'Source Sans Pro', sans-serif;
			}
			label {
				color: @dark-blue3;
				//text-transform: none;
				//font-size: 16px;
				font-size: .8rem;
				margin-bottom: 2px;
				margin-top: 10px;
			}
			input {
				border: 1px solid @dark-blue3;
			}
		}
		.btns-wrapper {
			display: flex;
			max-width: 230px;
		}
		.button-wrapper {
			padding: 0 45px 0 0;
			button {
				text-transform: uppercase;
			}
		}
		.cancel-wrapper {
			//padding: 0 45px;
			display: flex;
			align-items: center;
			.cancel {
				text-decoration: underline;
				cursor: pointer;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.settings-wrapper {
		margin-top: 67px;
		.title-block {
			font-weight: bold;
			margin-bottom: 26px;
			position: relative;
			width: 100%;
			&:after {
				content: "";
				background-color: @dark-blue3;
				height: 1px;
				width: 100%;
				position: absolute;
				top: 50%;
				left: 0;
			}
			.text {
				background-color: #fff;
				position: relative;
				z-index: 1;
			    padding-right: 15px;
			}
			.info {
				font-size: 14px;
				float: none;
				border: #cbced5 1px solid;
			    color: #cbced5;
			    -webkit-transform: translateY(2px);
			    transform: translateY(2px);
			    width: 17px;
			    height: 17px;
			    text-transform: none;
			    .infobulle {
			        margin-left: 100px;
			        &:before, &:after {
			            left: calc(100% - 372px) !important;
			        }
			    }
			}
		}
		.settings {
			background-color: #F2F2F2;
			padding: 70px 75px;
			.row {
				display: flex;
				justify-content: space-between;
				> div {
					width: 50%;
					padding: 0 18px;
				}
				&:not(:last-child) {
					margin-bottom: 27px;
				}
			}
			label {
				display: block;
				margin-bottom: 5px;
				font-size: .8rem;
				text-transform: uppercase;
			}
			input, .custom-select {
				display: block;
			    width: 100%;
			    -webkit-appearance: none;
			    -moz-appearance: none;
			    appearance: none;
			    background: white;
			    outline: none;
			    border: solid 1px #e7e1cd;
			    border-radius: 2px;
			    padding: 10px 15px;
			    transition: all 0.25s ease;
			    .select-items {
			    	-webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.24);
					-moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.24);
					box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.24);
			    	div {
			    		padding: 10px 15px;
			    		border-color: #e7e1cd;
			    	}
			    }
			}
		}
	}
}
@media only screen and (max-width : 1060px) {
	.shop-card-open {
		.form {
			width: calc(100% - 60px);
			margin-right: 30px;
	    	margin-left: 30px;
	    }
	}
	.count-results {
		padding-right: 30px;
		padding-left: 30px;
	}
	.authent-block.shop-card {
		position: relative;
		margin-right: 30px;
		margin-left: 30px;
		width: calc(100% - 60px);
	}
}
@media only screen and (max-width : 900px) {
	.shop-card-open {
		.transfert-wrapper {
			text-align: center;
			display: block;
			.email {
				margin-bottom: 20px;
				width: 100%;
			}
			.btns-wrapper {
				width: 100%;
				max-width: 100%;
				justify-content: center;
			}
			.button-wrapper {
				display: inline-block;
				padding-left: 0;
				order: 2;
				padding: 0 25px;
			}
			.cancel-wrapper {
				padding: 0 25px;
			    //margin-top: 20px;
    			//display: inline-block;
				order: 1;
			}
		}
		.settings-wrapper {
			.settings {
				.row {
					display: block;
					margin-bottom: 0;
					> div {
						width: 100%;
						padding-left: 0;
						padding-right: 0;
						margin-bottom: 27px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width : 670px) {
	.bubble.check.question .infobulle {
		display: none !important;
	}
	.authent-block.shop-card:not(.wallet-card) {
		.bubble {
			font-size: 11px;
			padding: 0px 9px 0 6px;
			margin: 0;
			.date {
				display: none;
			}
			.logo {
				margin-right: 4px;
			}
			&.check {
				margin-right: 4px;
			}
		}
		.block-content {
			width: 100%;
		}
		.img-wrapper {
			//position: absolute;
			width: 180px;
			height: 180px;
			//top: 21px;
			//left: 17px;
		}
		.block-content {
			.auth-block-top-bar {
			    padding-left: 10px;
    			padding-right: 10px;
			}
		}
		.details-btn-wrapper {
			padding-top: 24px;
		}
		.details-block {
			//padding-left: 178px;
			table {
				display: block;
				tr {
					td {
						display: block;
						font-size: 12px;
						//font-weight: normal;
						padding-left: 0 !important;
						padding-bottom: 5px;
						width: auto !important;
						&:first-child {
							font-size: 8px;
							padding-bottom: 2px;
						}
						.dots {
							&:before {
								display: none;
							}
						}
						.serial-wrapper {
							background-color: transparent;
							margin-left: 0;
							padding: 0 !important;
						}
					}
				}
			}
		}
		.background {
			font-size: 90px;
    		right: 20px;
    		top: calc(50% - 65px);
		}
	}
}
.search-results-2 {
	width: 100%;
	max-width: 1020px;
	.search-wrapper {
		width: 100%;
		max-width: 550px;
		margin-left: auto;
		margin-right: auto;
	}
	.count-results {
		font-weight: bold;
		margin: 30px 0 15px;
		color: #999999;
		text-transform: uppercase;
	}
}
@media only screen and (max-width : 900px) {
	.wallet-list-wrapper {
		.authent-block.shop-card {
			width: calc(100% - 60px) !important;
		}
	}
}
@media only screen and (max-width : 767px) {
	.module-auth-2.authent-block {
		.details-block {
			.details-btn-wrapper {
				padding: 15px 10px 0px;
			}
			table {
				transform: translateY(-5px);
			}
		}
		.see-btn {
				border: 10px solid #fff;
		}
	}
}
@media only screen and (min-width : 670px)  and (max-width : 900px) {
	/*.authent-block.shop-card {
		.auth-block-content {
			background-image: none !important;
		}
	}*/
}
@media only screen and (max-width : 430px) {
	.side-menu-page-wrapper {
		.authent-block:not(.module-auth-2) {
			.auth-block-top-bar {
				padding: 10px;
				display: block;
				.date, .status {
					display: block;
					&:not(:last-child) {
						margin-bottom: .5rem;
					}
				}
			}
		}
	}
}
@media only screen and (max-width : 600px) {
	.authent-block.shop-card {
		width: 100% !important;
	}
	.wallet-list-wrapper .cards-wrapper .title {
		em {
			display: block;
			padding: 0 20px 0 10px;
		}
		.separator {
			display: none;
		}
	}
	.shop-card-open {
		.email {
			width: 100% !important;
		}
		.authent-block.shop-card {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
		.form {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
		.transfert-wrapper {
			padding: 36px 20px 36px;
		}
		.settings-wrapper {
			margin-top: 27px;
			.settings {
				padding: 30px 20px;
			}
		}
		.cancel-wrapper {
			float: left;
			padding-left: 25vw;
		}
		.button-wrapper {
			padding-right: 0;
		}
	}
	.common-wrapper-free {
		padding-left: 10px;
		padding-right: 10px;
	}

	.auth-block-top-bar {
		.date {
			.bubble {
				padding-left: 25px;
				position: relative;
				.logo {
					/*position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 10px;*/
				}
			}
		}
	}
}
@media only screen and (max-width : 570px) {
	.authent-block {
		width: 100%;
	}
	.count-results {
		padding-right: 0px;
		padding-left: 0px;
	}
	.authent-block.shop-card {
		position: relative;
		margin-right: 0px;
		margin-left: 0px;
	}
	.authent-block.shop-card {
		.auth-block-content {
			background-image: none !important;
		}
	}
}
@media only screen and (max-width : 580px) {
	.module-auth-2 {
		.details-btn-wrapper {
			//display: flex !important;
			//flex-direction: column;
			.number-wrapper {
				padding: 0 !important;
				//width: 100% !important;
				padding-right: 10px !important;
				.number {
					transform: none;
				}
			}
		}
		.details-block {
			position: initial;
			.btn-wrapper {
				transform: none !important;
				position: initial;
				margin: 20px 0;
			}
			table {
				//width: 100%;
				padding: 0 !important;
				tr {
					td {
					    //width: 200% !important;
					}
				}
			}
		}
	}
}
@media only screen and (max-width : 480px) {
	.wallet-list-wrapper {
		.authent-block.shop-card.wallet-card {
			width: calc(100% - 20px) !important;
			margin-right: 0px;
			margin-left: 10px;
			.auth-block-top-bar {
				padding: 10px;
				display: flex !important;
				.date, .status {
					//display: block;
					display: inline-block !important;
					/*&:not(:last-child) {
						margin-bottom: .5rem;
					}*/
					.date {
						display: inline;
					}
				}
				.status {
					.bubble {
						box-shadow: none;
						background-color: transparent;
						transform: translateY(2px);
						.text {
							display: none;
						}
					}
				}
			}
			.block-content {
				//display: block;
			}
			/*.img-wrapper {
			    width: 130px;
			    height: 130px;
			    //width: 100%;
			}*/
			.details-btn-wrapper {
				padding-top: 13px;
				padding-bottom: 0;
			}
		}
	}
	.side-menu-page-wrapper {
		.authent-block:not(.module-auth-2) {
			.details-btn-wrapper {
				display: block;
				.btn-wrapper {
					position: initial;
					transform: none !important;
					text-align: left;
				    padding-left: 96px;
				    margin-top: 7px;
				    .button-main {
				    	width: 100%;
				    }
				}
			}
			.limited {
				width: 100%;
				max-width: 100% !important;
				right: 0;
				bottom: 0;
			}
		}
		.module-auth-2 {
			.status {
				position: relative;
				.bubble {
					padding-right: 0;
					padding-left: 0;
				}
			}
			.date {
				max-width: 180px;
			}
			.bubble {
				line-height: 18px;
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 12px;
				.txt {
					display: block;
				}
				.id-font {
					margin-left: 0;
				}
				&.status-color {
					position: absolute;
					right: 0;
					top: 0;
					padding-right: 7px;
				}
			}
			.number-wrapper {
				width: 145px !important;
				margin-right: 10px;
			}
			table {
				tr {
					td {
						padding-left: 10px !important;
						.limited {
							max-width: calc(100% - 32px) !important;
						}
					}
				}
			}
			.btn-wrapper {
				position: absolute;
				right: 18px;
				bottom: -7px !important;
				margin: 0;
				z-index: 1;
				.button-main {
					transform: translateY(-3px);
					padding: 13px 4px !important;
					font-size: 10px !important;
				}
			}
		}
	}
}
@media only screen and (max-width : 455px) {
	/*.authent-block.module-auth {
		.auth-block-top-bar {
			padding: 10px;
			display: block;
			.date, .status, .certificat-number {
				display: block;
				&:not(:last-child) {
					margin-bottom: .5rem;
				}
			}
		}
	}*/

	// ex media querie

	.wallet-list-wrapper {
		.authent-block.shop-card.wallet-card {
			.details-block {
				table {
					display: block;
					margin-top: 25px;
					tr {
						td {
							display: block;
							font-size: 12px;
							//font-weight: normal;
							padding-left: 0 !important;
							padding-bottom: 5px;
							width: auto !important;
							&:first-child {
								font-size: 8px;
								//padding-bottom: 2px;
								padding-bottom: 0px;
							}
							.dots {
								&:before {
									display: none;
								}
							}
							.serial-wrapper {
								background-color: transparent;
								margin-left: 0;
								padding: 0 !important;
							}
						}
					}
				}
			}
			.details-btn-wrapper {
    			padding-top: 8px !important;
    			padding-bottom: 3px;
    		}
		}
	}
}
@media only screen and (max-width : 374px) {
	.authent-block.shop-card {
		&:not(.wallet-card) {
			.auth-block-top-bar {
				//padding: 10px;
				//display: block;
				.date, .status, .certificat-number {
					display: block;
					&:not(:last-child) {
						//margin-bottom: .5rem;
					}
					.date {
						display: none;
					}
				}
			}
		}
		/*.img-wrapper {
		    width: 130px;
		    height: 130px;
		}*/
	}
}
@media only screen and (max-width : 360px) {
	.module-auth-2 {
		.auth-block-top-bar {
			.bubble {
				font-size: 12px;
			}
			.date {
				max-width: 160px;
			}
		}
		.number-wrapper {
			.number {
				font-size: 43px;
			}
		}
		.open-certificat-popin {
			font-size: 11px !important;
		}
	}
}


