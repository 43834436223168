.good-article {
    .information-header {
        padding-top: 30px;
        text-align: center;
        
        .brand-copy {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.8em;
            font-weight: @bold;
            letter-spacing: 1.8px;
        }
        
        .good-details {
            margin-top: 25px;
        }
    }
    
    .information-body {
        margin-top: 35px;
        aside {
            display: inline-block;
            vertical-align: middle;
            width: 33%;
            text-align: right;
            
            img {
                width: 175px;
            }
        }
        .information-details {
            display: inline-block;
            vertical-align: middle;
            width: 66%;
            
            text-align: left;
            
            
        }
        
        @media only screen and (max-width : 680px) {
            aside, .information-details {
                width: 100%;
                display: block;
            }
            aside {
                text-align: center;
            }
            .information-details {
                margin-top: 25px;
                
                ul {
                    padding-left: 0;
                    li {
                        strong {
                            width: calc(100% - 150px);
                        }
                    }
                }
            }
        }
    }
    
    .information-footer {
        text-align: center;
        margin: 35px 0;
    }
    
    .good-navigation {
        width: 100%;
        padding: 15px 0;
        
        background-color: @beige;
        text-align: center;
        
        ul {
            width: 100%;
            li {
                display: inline-block;
                vertical-align: top;
                
                width: 33%;
                
                a {
                    padding: 12px 15px;
                }
            }
        }
        
        @media only screen and (max-width : 680px) {
            ul {
                li {
                    display: block;
                    width: 100%;
                    
                    &:last-child {
                        margin: 10px 0;
                    }
                }
            }       
        }
    }
    
    .good-description {
        padding: 30px 60px;
        
        @media only screen and (max-width : 680px) {
            padding: 30px 20px;
        }
        
        .block {
            position: relative;
            margin-bottom: 30px;
            
            p {
                color: @dark-blue;
                line-height: 1.63em;
            }
            
            .common-small-bold-grey {
                display: block;
                margin-bottom: 1em;
                color: @light-grey;
            }
        }
        .block-files {
            .button-group {
                text-align: left;
                
                @media only screen and (max-width : 580px) {
                    margin-top: 30px;
                    a {
                        display: block !important;
                        text-align: center;
                        &:last-child {
                            margin: 15px 0 0 0 !important;
                        }
                    }     
                }
            }
        }
        .block-history {
            .button-underlined {
                margin-bottom: 1em;
            }
            
            .common-small-bold-grey {
                &:after {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    
                    width: 70%;
                    height: 2px;
                    
                    // Optical adjustement
                    margin-top: -1px;
                    
                    margin-left: 10px;
                    
                    background-color: @light-grey;
                    
                    @media only screen and (max-width : 940px) {
                        width: calc(75% - 150px);
                    }
                    @media only screen and (max-width : 400px) {
                        width: calc(75% - 175px);
                    }
                }
            }
            
            .display-history {
                position: absolute;
                top: 0;
                right: 0;
                
                padding-right: 15px;
                
                font-weight: bold;
                text-transform: uppercase;
                font-size: 0.9em;
                color: @dark-blue;
            }
        }
        
    }
    
}

.block-photos {
    .photos-list {
        .photo-item {
            display: inline-block;
            vertical-align: top;
            position: relative;
            
            width: 20%;
            height: 0;
            padding-bottom: 20%;
            
            +.photo-item {
                margin-left: 15px;
            }
            
            border: solid 1px @beige;
            
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                
                transform: translate3d(-50%,-50%,0);
            }
        }
    }
}