.create-certificat-form-v1 {
	background-color: #fff;
	font-family: 'Source Sans Pro', sans-serif;
}
.create-certificat {
	.main-wrapper {
		h1 {
			line-height: 1.44em;
		}
		h2 {
			line-height: 1.15;
		}
	}
	.modal-overlay {
	    z-index: 10000000;
	}
	.buttons-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 37px;
		margin-bottom: 9px;
		.cancel-link {
			text-decoration: underline;
			cursor: pointer;
		}
		.button-main {
			white-space: nowrap;
			width: auto !important;
			margin: 0 !important;
			font-size: 14px;
		}
		&.with-space {
			margin-top: 40px;
			padding: 0 86px;
		}
	}
	.no-link {
		text-decoration: underline;
		cursor: pointer;
		color: @dark-blue3;
		font-size: 14px;
	}
}
.side-menu-3-container {
	background-color: #f7f7f7;
	font-family: @font-family;
	font-family: 'Source Sans Pro', sans-serif;
	width: 272px;
	min-width: 272px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	margin-right: 0;
	&:before {
		display: none;
	}
	a {
		color: inherit;
		text-decoration: none;
	}
	.logo2 {
		line-height: 1.25;
		//color: #fff;
		position: relative;
		transform: translate(8px, 8px);
		color: @dark-blue3;
		transform: translate(3px, -7px);
    	font-size: 28px;
		/*&:before {
		    content: "";
		    position: absolute;
		    z-index: -1;
		    background-color: @dark-blue3;
		    border-radius: 50%;
		    width: 24px;
		    height: 24px;
		    top: -4px;
		    left: -6px;
		    transform: scale(1.05) translateX(2%);
		}*/
	}
	.account-part {
		padding-left: 15px;
	}
	.site-menu-wrapper {
		width: 272px;
	}
	.side-save {
		font-size: 13px;
		color: #2a2a3e;
		margin-bottom: 27px;
		display: inline-block;
		text-decoration: underline;
		font-weight: bold;
		.id-font4 {
			font-size: 20px;
			display: inline-block;
			transform: translateY(2px);
		}
	}
	.topline {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding-right: 15px;
	}
	.side-title {
		font-size: 26px;
		color: #2a2a3e;
		margin-bottom: 39px;
		display: inline-block;
		line-height: 1.2;
		font-weight: @medium;
	}
	.nav {
		position: absolute;
		width: 45px;
		top: 110px;
		li {
			min-height: 45px;
			margin-bottom: 15px;
			.link {
				background-color: transparent !important;
				box-shadow: none !important;
				transform: none !important;
				&:after {
					display: none !important;
				}
				span {
					background-color: transparent !important;
				}
			}
		}
	}
	.nav2 {
		//margin-left: 45px;
		font-size: 15px;
		padding-left: 15px;
		a {
			padding: 12px 0;
			color: #2a2a3e;
		}
		.item {
			> a {
				font-weight: @medium;
			}
			a {
				text-decoration: none !important;
			}
			.before {
				display: inline-block;
				height: 24px;
				width: 27px;
			    transform: scale(1.3) translateY(0px);
			}
			li a {
				display: flex;
				align-items: center;
			}
			.picto {
				display: none;
				&.lock {
					color: #ccc;
					font-size: 20px;
					transform: translateX(3px) scale(1.2);
				}
				&.current {
					color: #FBB756;
				}
				&.check {
					color: #4ED157;
				}
			}
			.step-item {
				padding-right: 25px;
				.wrapper {
					display: flex;
					align-items: center;
				}
				&.current {
					background-color: #efefef;
					width: calc(100% + 14px);
					margin-left: -14px;
					padding-left: 14px;
				}
			}
			.statut-lock {
				.lock {
					display: inline-block;
				}
				+ .submenu {
					display: none;
				}
			}
			.statut-current {
				.current {
					display: inline-block;
				}
				+ .submenu {
					display: none;
				}
			}
			.statut-check {
				.check {
					display: inline-block;
				}
				+ .submenu {
					display: none;
				}
			}
			.step2-parent-item + .submenu {
				//display: block;
			}
		}
		.step-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
body .create-certificat-form-container {
	margin: 0 !important;
	width: 100%;
	max-width: 100% !important;
	padding-right: 0 !important;
	.summernote + .note-editor, .summernote-step2 + .note-editor {
		.note-style,
		.note-fontname,
		.note-color,
		.note-table,
		.btn-codeview,
		.note-para button:nth-child(2) {
			display: none !important;
		}
	}
	.step {
		display: none;
		position: relative;
		padding: 0;
		h2 {
			font-size: 24px;
			font-weight: 600;
			//margin-bottom: 55px;
			margin-bottom: 32px;
		}
		.intro {
			margin-bottom: 32px;
			ul {
				list-style: disc;
				margin-left: 30px;
			}
		}
		.note {
			font-size: 13px;
			opacity: .5;
			margin: -10px 0 6px;
			display: block;
			width: calc(100% + 10px);
		}
		.wrapper {
	    	height: calc(100vh - 75px);
			overflow-y: auto;
			padding: 124px !important;
			padding-top: 51px !important;
			max-width: 100% !important;
			.container {
				max-width: 546px;
			}
		}
		.wrapper a {
			color: #628dff;
			text-decoration: underline;
		}
		.info-block {
			padding: 8px 24px;
			border-left: 4px #e6e0ce solid;
			margin-bottom: 32px;
		}
		.have-to-appear {
			display: none;
		}
		input[type="text"], input[type="number"], textarea, select {
			width: 100%;
			line-height: 35px;
			padding: 5px 15px;
			border-radius: 4px;
			border: solid 1px #e8e8e8;
			margin: 2px 0 32px 0;
		}
		.prefilled {
			margin-bottom: 32px;
			.no-link {
				color: #628dff;
				display: inline-block;
				margin-left: 16px;
			}
		}
		.currency {
			position: relative;
			width: 230px;
			#currency {
				position: absolute;
				left: 0;
				//top: 40px;
				top: 0;
				height: calc(100% - 34px);
				width: 60px;
			}
			input {
				padding-left: 75px;
			}
		}
		.duration-wrapper {
			display: flex;
			align-items: center;
			margin: 8px 0 32px 0;
			.and {
				min-width: 30px;
				text-align: center;
			}
		}
		.note + .duration-wrapper {
			margin-top: 0;
		}
		.mois-sufix, .annee-sufix {
			position: relative;
			width: 230px;
			margin: 0;
			input {
				padding-right: 60px;
				margin: 0;
			}
			&:after {
				content: "mois";
				position: absolute;
				right: 20px;
				top: 14px;
			}
		}
		.annee-sufix {
			input {
				padding-right: 60px;
			}
			&:after {
				content: "an(s)"
			}
		}
		.summernote {
			width: 100%;
			line-height: 35px;
			margin: 8px 0 32px 0;
		}
		.note-editing-area {
			height: 100px;
		}
		.note-editor {
			margin-bottom: 32px;
		}
		.preview-link {
			position: absolute;
			right: 32px;
			top: 24px;
			//opacity: 0;
			opacity: 1;
		}
		label {
			margin-bottom: 2px;
			font-weight: normal;
		}
		&.step-with-info {
			//padding-right: 247px;
			width: calc(100% - 247px);
		}
		.upload-wrapper {
			display: inline-block; 
		}
		.upload-wrapper,
		//.currency,
		.duration-wrapper {
			margin-top: -8px;
		}
		.info-sidebar {
			width: 247px;
			min-height: 100vh;
			height: 100%;
			background-color: #fff;
			padding: 34px 16px 35px 16px;
			box-shadow: -2px 0 0 0 rgba(0, 0, 0, 0.08);
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(100%);
			overflow-y: auto;
			.title {
				margin-top: 0;
				margin-bottom: 89px;
				font-weight: bold;
				font-size: 16px;
			}
			.block {
				margin: 19px 0 0 0.5px;
				//margin-bottom: 89px;
				padding: 14px 14px 14px 17px;
				border-radius: 4px;
				//background-color: #628dff;
				width: 100%;
				//color: #fff;
				//font-style: italic;
				border-radius: 4px;
				box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.14);
				background-color: #fff;
				color: #888;
				font-size: 13px;
				b, strong {
					color: #3b3b3b;
				}
				p {
					margin-top: 0;
					margin-bottom: 9px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				ul {
					list-style: disc;
					padding-left: 15px;
					li {
						margin-bottom: 14px;
						&:last-child {
							margin-bottom: 0;
						}
						ul {
							margin-top: 15px;
							list-style: none;
							padding-left: 0;
							li {
								&:before {
									content: "- ";
								}
							}
						}
					}
				}
			}
		}
		&.step2 {
			.info-sidebar {
				.block {
					//margin-top: 350px;
				}
			}
		}
		&.step3 {
			.info-sidebar {
				.block {
					//margin-top: 363px;
				}
			}
			.uploaded-img {
				align-items: flex-start;
				display: none;
				&.on {
					display: flex;
				}
				.img-wrapper {
					width: 279px;
					height: 169px;
					margin: 0 8px 0 0;
					border-radius: 4px;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.delete {
					font-size: 18px;
					cursor: pointer;
				}
			}
		}
		&.step5 {
			input[type=number] {  
				max-width: 127px;
				display: block;
			}
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			} 
			/* Firefox */
			input[type=number] {
				-moz-appearance: textfield;
			}
		}
		.upload-img {
			width: 100%;
			background-color: #fff;
			border-radius: 4px;
			border: solid 1px #e8e8e8;
			margin-top: 0;
			border: none !important;
			.big-upload-btn {
				background-color: #fff !important;
				height: 200px;
			}
			.icon {
				font-size: 44px;
				color: #3b3b3b;
				margin-top: 30px;
				margin-bottom: 10px;
			}
			.underline {
				text-decoration: none;
				color: #3b3b3b;
				font-size: 15px;
				.link {
					transform: none !important;
					margin-left: 0 !important;
					color: #628dff;
					text-decoration: underline;
				}
				br {
					display: block !important;
				}
			}
			.greyline {
				font-weight: normal;
				margin-bottom: 30px;
				color: #3b3b3b;
			}
			.desc {
				font-weight: normal;
				display: block;
				transform: translateY(1px);
			}
			.big-upload-input {
				margin-top: 128px;
				font-size: 60px;
			}
		}
	}
	.uploaded-img2, .upload-doc2 {
		.main-img {
			position: relative;
			display: inline-block;
			.label {
				font-size: 12px;
				font-weight: 600;
				//color: #666674;
				color: #fff;
				position: absolute;
				//bottom: -10px;
				//left: 0;
				top: 15px;
				left: 15px;
				padding: 0;
				margin: 0;
			}
		}
		.img-wrapper, .doc-wrapper {
			width: 166.6px;
			height: 181px;
			//margin: 0px 16px 5px 0;
			margin: 0px 16px 16px 0;
			border-radius: 4px;
			position: relative;
			color: #fff;
			display: inline-block;
			overflow: hidden;
			background-color: #000;
			transition: .3s all;
			vertical-align: top;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.delete {
				position: absolute;
				top: 9px;
				right: 9px;
				font-size: 18px;
				cursor: pointer;
				opacity: 0;
				transition: .3s all;
				z-index: 1;
			}
			.txt {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100%;
				text-decoration: underline;
				text-align: center;
				padding: 0 15px 15px;
				font-size: 12px;
				margin-bottom: 0;
				cursor: pointer;
				opacity: 0;
				transition: .3s all;
				z-index: 1;
			}
			&:hover {
				img {
					opacity: .6;
				}
				.delete, .txt {
					opacity: 1;
				}
			}
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				transform: translateX(-50%) translateY(-50%);
				font-size: 30px;
				color: #3b3b3b;
				margin-top: 20px;
			}
			.iconPdf {
				transition: .3s all;
				object-fit: none;
			}
			&.add-img {
				border: solid 1px #e8e8e8;
				background-color: #fff;
			}
		}
		.img-wrapper {
			.delete { 
				color: #fff !important;
			}
		}
		.to-appear {
			display: none;
		}
	}
	.upload-doc2 {
		//color: @dark-blue3;

		.doc-wrapper {
			//border-bottom-left-radius: 0;
			//border-bottom-right-radius: 0;
			//background-color: #628dff;
			background-color: #F7F7F7;
			border: 1px solid #efefef;
			position: relative;
			overflow: visible;
			color: #fff;
			//margin-bottom: 44px;
			margin-bottom: 74px;
			.delete {
				//color: #fff;
				color: #333;
			}
			.icon {
				top: calc(50% - 37px) !important;
			    font-size: 49px !important;
			    color: #fff !important;
	    		margin-top: 40px;
			}
			.name {
				//color: #fff;
				color: #333;
				max-width: 100%;
				position: absolute;
				left: 0;
				bottom: 24px;
				overflow:visible;
				text-align: center;
				font-size: 14px;
				width: 100%;
				font-weight: 600;
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.img-wrapper {
				+ .custom-select-nojs {
				    transform: translateY(-16px) !important;
				}
			}
		}
		.custom-select, .custom-select-nojs {
			&.clicked {
			    background: #efefef;
			    border-radius: 3px;
			}
			position: absolute !important;
			bottom: -2px;
			left: -3px;
			width: calc(100% + 3px) !important;
			//border-radius: 4px;
			//border-top-left-radius: 0;
			//border-top-right-radius: 0;
			padding: 9px 0;
			font-size: 14px;
			text-align: center;
			width: 100%;
			position: relative;
			//border-top: solid 1px #e1e1e1;
			padding-right: 20px;
			color: @dark-blue3;
			font-weight: 600;
			background-color: #fff;
			transform: translateY(100%) translateX(2px);
			background-color: #fff;
		}
		.custom-select {
			&:after {
				content: "s";
				position: absolute;
				right: 9px;
				top: 50%;
				transform: translateY(-50%);
				font-family: 'Goods ID4';
				font-weight: normal;
    			font-style: normal;
				color: @dark-blue3;
			}
			.select-items {
				-webkit-box-shadow: 0px 10px 11px -5px rgba(0,0,0,0.5); 
				box-shadow: 0px 10px 11px -5px rgba(0,0,0,0.5);
				div {
					padding: 9px 13px;
					background-color: #f7f7f7;
					//background-color: @dark-blue3;
					//color: #fff;
					color: @dark-blue3;
					transition: .2s all;
					//border: @dark-blue3 solid 1px;
					border: 1px solid #efefef;
					border-top: none;
					text-align: left;
					&:hover {
						//background-color: @dark-blue3;
						color: #fff;
						background-color: #628dff;
					}
				}
			}
		}
		.custom-select-nojs {
			padding-right: 0;
			//margin-bottom: 32px;
			width: calc(100% + 2px) !important;
			position: relative !important;
			bottom: inherit;
			//top: 100px;
			left: -1px;
			transform: translateY(16px);
			div {
				width: 100%;
			}
			select {
			    padding: 5px 8px;
			}
		}
	}
	.step6, .step7 {
		h2 {
			//margin-bottom: 5px;
		}
		/*.big-upload-btn {
		    height: 181px !important;
		    .big-upload-input {
		    	margin-top: 118px !important;
		    }
		}
		.upload-img {
			display: inline-block;
			width: 166.6px;
			height: 181px;
			margin: 0px 16px 5px 0;
			border: solid 1px #e8e8e8;
			background-color: #fff;
			.icon {
				font-size: 30px;
				color: #3b3b3b;
			}
			.underline {
				font-size: 11px;
			}
			.greyline {
				font-size: 9px;
				br {
					display: block;
				}
			}
		}
		.img-wrapper, .upload-img {
			margin-top: 30px !important;
		}*/
		.big-upload-btn {
			.icon {
				margin-top: 30px;
				color: #3b3b3b;
			}
		}
		.note {
			margin-bottom: 32px;
		}
		.upload-img {
			max-width: 516px;
		}
		.to-appear {
			display: none;
		}
		.wrapper {
   			padding-right: 30px !important;
			.container {
			    max-width: 687px;
			}
		}
	}
	.step1 {
		display: block;
		h2, label {
			//text-align: center;
			text-align: left;
		}
		.note {
			opacity: 1;
		}
		.bien-choices {
			display: flex;
			justify-content: flex-start;
			text-align: center;
			margin-top: 28px;
			width: 795px;
		    flex-wrap: wrap;
			input {
				display: none;
			}
			input:checked + label {
				border: solid 1px rgba(200, 215, 255, 0.63);
				background-color: #628dff;
				color: #fff;
				.icon {
					color: #fff;
				}
			}
			.bien {
				display: inline-block;
			}
			.bien label {
				width: 240px;
				height: 240px;
				padding: 38px 12px 18px;
				margin: 0 24px 24px 0;
				border-radius: 8px;
				background-color: rgba(224, 232, 255, 0.44);
				font-size: 18px;
				font-weight: 600;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				&:before, &:after {
					display: none !important;
				}
				.icon {
					font-size: 85px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(calc(-50% - 15px));
					color: #628dff;
					display: block;
					object-fit: contain;
					max-width: 138px;
					max-height: 138px;
				}
				&.active {
					border: solid 1px rgba(200, 215, 255, 0.63);
				}
			}
			.bien {
				&:nth-child(3) {
					.icon {
						font-size: 58px;
					}
				}
			}
		}
	}
	.step2 {
		h2 {
			//margin-bottom: 8px;
		}
		.wrapper {
			width: 100%;
			max-width: 546px;
			display: block;
			margin: auto;
		}
	}
	.p-like label {
		display: block;
		margin-bottom: 10px;
	}
}
.create-certificat-form-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 71px;
	width: calc(100% - 272px);
	padding: 14px 40px;
	margin-left: 272px;
	background-color: #fff;
	position: fixed !important;
	transition: .2s all;
	&.mini {
 		margin-left: 272px;
		width: calc(100% - 521px);
    	margin-right: 249px;
	}
	&.wide {
		width: calc(100% - 272px);
    	margin-right: 0;
	}
	&:before {
		content: "";
		background-color: #f2f2f2;
		width: 100%;
		height: 4px;
		position: absolute;
		top: -4px;
		left: 0;
	}
	&:after {
		content: "";
		background-color: #4bd053;
		width: 15%;
		height: 4px;
		position: absolute;
		top: -4px;
		left: 0;
		transition: .2s all;
	}
	&[data-step="1"] {
		&:after {
			width: 10%;
		}
	}
	&[data-step="2"] {
		&:after {
			width: 20%;
		}
	}
	&[data-step="3"] {
		&:after {
			width: 30%;
		}
	}
	&[data-step="4"] {
		&:after {
			width: 40%;
		}
	}
	&[data-step="5"] {
		&:after {
			width: 50%;
		}
	}
	&[data-step="6"] {
		&:after {
			width: 60%;
		}
	}
	&[data-step="7"] {
		&:after {
			width: 70%;
		}
	}
	&[data-step="8"] {
		&:after {
			width: 80%;
		}
	}
	&[data-step="9"] {
		&:after {
			width: 90%;
		}
	}
	.elements-count {
		font-size: 11px;
	}
}
.side-menu-page-wrapper .create-certificat-form-container,
.side-menu-page-wrapper .create-certificat-form-footer {
	.button-main {
		font-size: 15px;
		font-weight: 600;
		padding: 8px 25px;
		border-radius: 3px;
		cursor: pointer;
		text-decoration: none !important;
		&.white {
			border: solid 1px #979797;
			background-color: #fff;
			color: #666674 !important;
		}
		&.disabled {
			cursor: initial;
		}
	}
}
.side-menu-page-wrapper .create-certificat-form-footer[data-step="1"] {
	.prev {
		opacity: 0;
	}
}
.upload-existing-medias, .upload-existed-media, .upload-existed-medias,
.upload-existing-docs, .upload-existed-doc, .upload-existed-docs {
	display: none;
	font-family: 'Source Sans Pro', sans-serif;

	.close {
		opacity: .4;
		&:hover {
			opacity: .6;
		}
	}
	.submodal-shadow {
		display: block;
	}
	.submodal {
		max-width: 729px;
		text-align: center;
	}
	.modal-title {
		color: #2a2a3e;
		font-weight: bold;
		font-size: 27px;
		margin: 0 auto 40px;
	}
	.searchbar {
		padding: 14px 14px 14px 56px;
		background-image: url(../img/search.svg);
		background-repeat: no-repeat;
		background-size: 22px 22px;
		background-position: 19px 12px;
		border-radius: 24px;
		border: solid 1px #dcdcdc;
		margin-bottom: 34px;
		font-size: 14px;
		width: 100%;
		max-width: 410px;
		outline: none !important;
	}
	.see-img {
		width: 40px;
		height: 40px;
		background-image: url(../img/search.svg);
		background-repeat: no-repeat;
		float: right;
	}
	.my-medias-list {
		//max-height: 505px;
		max-height: 295px;
		width: 100%;
		font-size: 14px;
		text-align: left;
		font-weight: bold;
		margin: auto;
		.media-item {
			padding: 14px 34px 16px 50px;
			/*&:nth-child(odd) {
				background-color: #f7f6f8;
			}
			&:nth-child(even) {
				background-color: #fff;
			}*/
			background-color: #fff;
			&:hover, &.selected {
				background-color: #f7f6f8;
			}
			label {
				max-width: 370px;
			}
			.question {
				margin-left: 0;
				.ellipsis {
					white-space: nowrap;
	  				overflow: hidden;
					text-overflow: ellipsis;
					display: inline-block;
					max-width: 100%;
					vertical-align: bottom;
					line-height: 1.2;
				}
				.infobulle {
					max-width: 100%;
		    		white-space: break-spaces;
		    		text-overflow: unset;
		    		overflow-wrap: break-word;
		    	}
			}
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				.question {
					.infobulle {
						//max-width: 100%;
		    			//white-space: break-spaces;
		    			//text-overflow: unset;
		    			//overflow-wrap: break-word;

	    			
		    			top: inherit !important; 
	    				bottom: -16px;
	    				transform: translateY(100%) translateX(-50%);
	    				box-shadow: 0px -2px 14px 0px rgb(0 0 0 / 20%);
	    				&:after {
	    					top: inherit;
	    					bottom: 100%;
	    					transform: rotate(180deg);
	    				}
	    			}
	    		}
	    	}
		}
		.my-medias-list {
			max-width: 498px !important;
			label {
				margin-bottom: 0;
			}
		}
		.see-img {
			width: 19px;
		    height: 19px;
		    background-image: url(../img/search.svg);
		    background-repeat: no-repeat;
		    float: right;
		    background-size: contain;
		    cursor: pointer;
		    transform: translateY(2px);
		}
	}
	.button-main {
		margin: 30px auto;
	}
	[type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
	[type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
		top: 1px;
	}
	[type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
	[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
		padding-left: 37px;
	}
	[type="checkbox"]:checked + label:after,
	[type="checkbox"]:not(:checked) + label:after {
		top: 5px !important;
	}

	[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
		//transform: translateY(-23px);
		&:before {
			border-radius: 3px;
		}
	}
	[type="checkbox"]:checked + label:before,
	[type="checkbox"]:not(:checked) + label:before {
		width: 18px;
		height: 18px;
		border-radius: 3px;
		border: solid .8px #ccc;
	}
	[type="checkbox"]:checked + label:before {
		background-color: #fff;
		border: solid .8px #071b4f;
	}
	[type="checkbox"]:checked + label:after,
	[type="checkbox"]:not(:checked) + label:after {
		content: '';
		width: 10px;
		height: 10px;
		background: #071b4f !important;
		border-radius: 2px !important;
		position: absolute;
		top: 29px;
		left: 4px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}

	[type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
		&:before {
			border-radius: 100%;
		}
	}
	[type="radio"]:checked + label:before,
	[type="radio"]:not(:checked) + label:before {
		width: 18px;
		height: 18px;
		border-radius: 24.5px;
		border: solid .8px #ccc;
	}
	[type="radio"]:checked + label:before {
		border: solid .8px #071b4f;
	}
	[type="radio"]:checked + label:after,
	[type="radio"]:not(:checked) + label:after {
		content: '';
		width: 8px;
		height: 8px;
		background: white !important;
		position: absolute;
		top: 30px;
		left: 5px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;

		display: none;
	}
	.button-main {
		padding: 24px 85px;
		font-size: 14px;
		font-weight: normal;
		text-transform: uppercase;
	}
}
.upload-existed-media, .upload-existed-medias,
.upload-existed-doc, .upload-existed-docs {
	text-align: center;
	.submodal-shadow {
		display: block;
	}
	.submodal {
		max-width: 580px;
	}
	.modal-title {
		max-width: 415px;
		margin-bottom: 22px;
		line-height: 1.3;
	}
	.img-wrapper {
		width: 279px;
		height: 169px;
		margin: 14px auto;
		border-radius: 4px;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
	.desc {
		font-weight: bold;
		font-size: 13px;
		max-width: 360px;
		display: block;
		margin: 32px auto 43px;
	}
}
.upload-existed-medias,
.upload-existed-docs {
	.modal-title {
		margin-bottom: 45px;
	}
	.media-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		&:nth-child(odd) {
			background-color: #f7f6f8 !important;
		}
		&:nth-child(even) {
			background-color: #fff !important;
		}
	}
	label {
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		padding-left: 0 !important;
		&:before, &:after {
			display: none !important;
		}
	}
	img {
		width: 24px;
		height: 24px;
		object-fit: cover;
		margin-right: 19px;
	}
	.delete {
		color: @dark-blue3;
		//float: right;
		cursor: pointer;
	    transform: scale(1.4);
	}
}
.upload-existed-docs {
	img {
		width: 17px;
   		height: 19px;
   		object-fit: contain;
	}
}
.modal-delete-img, .modal-model-confirmation, .modal-image-already-exist, .modal-images-already-exist, .modal-documents-already-exist, .modal-confirm-saving {
	display: none;
	.submodal-shadow {
		display: block;
		overflow: auto; 
	}
	.submodal {
		//max-width: 580px;
		max-width: 740px;
		text-align: center;
		padding: 30px !important;
	}
	.modal-title {
		//color: #2a2a3e;
		color: @dark-blue3;
		//font-weight: bold;
		font-size: 22px;
		margin: 20px auto 32px;
		font-weight: 400;
    	b, strong {
    		font-weight: 700;
    	}
	}
	.modal-txt {
		font-style: italic;
		margin-bottom: 45px;
		color: @dark-blue3;

		max-width: 490px;
    	margin-left: auto;
    	margin-right: auto;
    	text-align: left;
    	font-style: italic;
	}
	.buttons-wrapper {
		//display: block;
		max-width: 490px;
		padding: 0;
		margin-left: auto;
		margin-right: auto;
		.button-main {
			margin: 5px 0 22px !important;
			text-transform: uppercase;
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			border-radius: 3px;
			padding: 22px 17px;
			width: 111px;
		}
		.confirm {
			//background-color: #f55;
			margin-left: 40px !important;
		}
	}
	.filename {
		display: inline-block;
		max-width: 200px;
		width: 100%;
		margin: 0 0 10px;
	}
	.question {
		margin-left: 0;
		.ellipsis {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			max-width: 90%;
			vertical-align: bottom;
		}
		.infobulle {
			white-space: break-spaces;
			text-overflow: unset;
			overflow-wrap: break-word;
			line-height: 1.2;

			transform: translateY(-100%);
    		left: 0;
    		max-width: 170%;
    		&:after {
    		    left: 53px;
    		}
		}
	}
	tr {
		&:first-child {
			.question {
				.infobulle { 
					top: inherit !important;
    				bottom: -14px;
    				transform: translateY(100%);
    			    box-shadow: 0px -4px 14px 0px rgb(0 0 0 / 20%);
    				&:after {
    					top: inherit !important;
    					bottom: calc(100% - 1px);
    					transform: rotate(180deg);
    				}
				}
			}
		}
	}
}
.modal-delete-img, .modal-model-confirmation {
	.submodal {
		max-width: 540px;
		.modal-title {
			font-weight: bold;
		}
		.buttons-wrapper {
			max-width: 300px;
		}
	}
}
.modal-confirm-saving {
	z-index: 10000001 !important;
	.modal-txt {
		font-size: 16px;
		font-size: normal;
		max-width: 400px;
    	margin-left: auto;
    	margin-right: auto;
	}
	.modal-txt-checkbox {
		font-size: 14px;
		font-size: normal;
	}
	label {
		text-align: left;
    	padding-left: 34px;
    	font-weight: normal;
    	&:before {
    		top: 1px !important;
    	}
    	&:after {
    		display: none;
    	}
	}
}
.modal-image-already-exist {
	.submodal {
		    max-width: 640px;
	}
	.modal-txt {
		font-style: normal;
	}
	.modal-title {
		//font-weight: 500;
		color: @dark-blue3;
	}
	.files {
		display: flex;
		width: 100%;
	}
	.file {
		width: 50%;
		text-align: center;
		cursor: pointer;
	}
	.filetype {
		font-weight: bold;
		font-size: 22px;
		line-height: 1.1;
	}
	.img-wrapper {
		width: 200px;
    	height: 130px;
		border-radius: 5px;
		overflow: hidden;
		margin: auto;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.filesize {
		display: block;
	    margin: 8px auto 45px;
    	font-size: 18px;
		color: #9e9e9e;
		&.over {
			color: #db0000;
		}
	}
}
.modal-images-already-exist, .modal-documents-already-exist {
	.submodal {
		max-width: 740px;
	}
	.modal-title, .modal-txt, table, .buttons-wrapper, .table-wrapper {		
		max-width: 583px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0; 
	}
	.buttons-wrapper {
		display: flex;
		margin-bottom: 0 !important;
		.button-main {
			margin-bottom: 0 !important;
		}
	}
	.modal-title {
		font-weight: 400;
		font-size: 22px;
		b, strong {
			font-weight: bold;
		}
	}
	.modal-txt {
		text-align: left;
		margin-bottom: 24px;
	}
	.table-wrapper {
		//max-height: 430px;
		max-height: 380px;
		overflow: overlay;
		//margin-bottom: 10px;
		margin-bottom: 25px;
		position: relative;
	}
	table {
		margin: auto;
		//margin-bottom: 45px;
		width: 100%;
	}
	th {
		font-weight: bold;
		font-size: 16px;
		color: @dark-blue3;
		padding-bottom: 16px;
		position: sticky;
		top: 0;
		background-color: #fff;
		border-bottom: 1px solid #ccc;
		z-index: 10;
	}
	tr {
		border-top: 1px solid #ccc;
		&:first-child {
			border-top: none;
		}
	}
	td {
		padding: 16px 0;
	}
	.img-wrapper {
		display: flex;
		align-items: center;
	}
	img {
		width: 100px;
		height: 60px;
		border-radius: 3px;
		overflow: hidden;
		object-fit: cover;
	}
	.filename {
		text-align: left;
		color: @dark-blue3;
	}
	.filetype {
		font-weight: bold;
		font-size: 22px;
	}
	.filesize {
		display: block;
	    margin: 0 20px;
    	font-size: 14px;
		color: #9e9e9e;
		&.over {
			color: #db0000;
		}
	}
	input[type="checkbox"] {
		+ label {
			width: 18px;
			&:before {
				top: 0;
			}
			&:after {
				display: none;
			}
		}
	}
}
.modal-documents-already-exist {
	img {
		object-fit: contain;
		width: 60px;
		margin-left: 20px;
	}
	.filesize {
		margin-right: 60px;
	}
}
.modal-certificat-preview {
	display: none;
	.submodal-shadow {
		display: block;
	}
	.submodal {
		max-width: 812px;
		text-align: center;
		padding: 30px !important;
		.my-certificat-wrapper-preview {
			//max-width: 751px;
			margin-bottom: 35px;
			text-align: left;
		}
	}
	.modal-title {
		color: #2a2a3e;
		font-weight: bold;
		font-size: 27px;
		margin: 0px auto 30px;
	    //margin-top: 150px !important;
	    margin-top: 60px !important;

		text-align: left;
	    //max-width: 900px;
		max-width: 1090px;
	}
	.submodal-footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		.buttons-right {
			display: flex;
			.button {
				margin-left: 16px;
			}
		}
		.button {
			text-align: center;
			padding: 8px 22px !important;
		}
	}
}
.note-statusbar {
	display: none !important;
}
.note-editor.note-airframe, .note-editor.note-frame {
	border: solid 1px #e8e8e8 !important;
}
.note-editable {
	height: 100%;
}
.my-certificat-wrapper-preview {
	margin-top: 0;
	////max-width: 731px;
	.main-wrapper {
		//background-color: #e6e0ce;
	    background-color: #f7f5f5;
		//padding: 11px !important;
		//padding: 1px !important;
		padding: 1px !important;
		//max-width: 731px;
		////max-width: 751px;
		max-height: calc(100vh - 320px);
   		overflow: overlay;

   		border: 11px solid #E6E0CE;
	}
	.authent-block {
		max-width: 100%;
		transform: none !important;
		box-shadow: none ! important;
		overflow: auto;
		//margin-bottom: 0;
		.auth-block-top-bar {
			//padding: 14px 16px;
			border-bottom: none;

			//height: 54px;
			display: flex !important;
			/*.bubble {
				font-size: 10px;
				line-height: 25px;
			    padding: 0 9px;
			    .logo {
			    	margin-left: 1px;
			    }
			}*/
		}
		.center-wrapper {
			 background-color: #f7f5f5;
			 //padding: 16px;

			 display: block !important;
		}
		.bubble {

			width: auto !important;
		}
		.title {
			max-width: 408px;
			/*padding: 14px 11px !important;

			margin: 0 !important;
			float: right !important;
			h1 {
				//font-size: 16px;
				margin-left: 51px;
				margin-bottom: 2px;
			}
			h2 {
				//font-size: 12px;
				margin-left: 51px;
				margin-bottom: 0;
			}
			.small-logo {
			    width: 29px;
			    height: 29px;
			    top: 16px;
			}*/
		}
		.main-image-wrapper {
			display: block !important;
		}
		.tabs {
			//max-width: 408px;
			//font-size: 10px;

			float: right !important;
			display: flex !important;
		}
		.tab-target {
			//width: 408px;
			float: right !important;
		}
		.info {
		    transform: translateY(-2px);
    		display: inline-block;
		}
		/*table {
			tr {
				td {
					//font-size: 12px;
					padding: 8px 0;
					&:first-child {
						width: 110px;
					}
				}
			}
		}*/
		.description {
			//width: 408px;

			float: right !important;
			.subtitle {
				//font-size: 10px;
			}
			/*.txt {
				padding: 16px;
				li {
					//font-size: 12px;
					margin-bottom: 8px;
				}
			}
			.subtitle-wrapper {
				padding: 8px 16px;
			}*/
		}
		.images {
			//width: 258px;
			//width: 278px;
			////width: 397px;
			//padding: 16px;
			.main-image-wrapper {
				//width: 224px;
				//height: 224px;
				//width: 364px;
				//height: 364px;
			}
			/*.certificat-slider2 {
				display: flex;
				justify-content: space-between;
				.image {
					width: 65px;
					height: 65px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}*/
			/*.button-arrow {
				bottom: 55px !important;
				transform: translateY(50%) scale(0.6);
				.button-prev {
					left: 25px;
				}
				.button-next {
					right: 25px;
				}
			}*/
		}
		.documents {
			//width: 258px;
			//width: 278px;
			width: 397px;
			/*padding: 19px 16px;
			.subtitle {
				//font-size: 10px;
			}
			a {
				//font-size: 12px;
			}
			li {
				&:after {
					right: 6px;
				}
			}*/
		}
		.warranty-insurance {
			width: 408px;
			/*padding: 19px 16px;
			.block-title {
				//font-size: 10px;
			}
			.block {
				padding: 16px;
				.block-subtitle {
					//font-size: 14px;
				    margin-bottom: 18px;
				}
				ul {
					//font-size: 14px;
					li {
						margin-bottom: 12px;
					}
				}
			}*/
		}
	}
}
.modal-certificat-preview, .modal-certificat-preview-big {
	.auth-block-top-bar .certificate-id {
	    transform: translateY(-2px);
	    display: flex;
	    align-items: center;
	}
	.warranty-insurance .info.orange {
		transform: translateY(5px);
		.icon {
	    	transform: translateY(-6px);
	    }
	}
	.modal-title {
		text-align: center;
	}
	&.bg-gold {
	    .submodal {
	        padding: 24px !important;
	        padding-bottom: 0px !important;
	    	background-color: #e6e0ce;
	    }
	    .close {
	    	margin-bottom: 18px;
	    	opacity: .8;
	    	font-size: 15px;
	    	&:hover {
	    		opacity: 1;
	    	}
	    }
	    .my-certificat-wrapper-preview {
	    	margin: 0 !important;
	        max-width: 100%;
    		width: 100%;
    		.main-wrapper {
    			max-width: 100%;
    			width: 100%;
    			max-height: calc(100vh - 170px);
    		}
	    }
	}
}
.modal-certificat-preview-big {
	.submodal {
		max-width: 1138px;
		margin: 40px auto;
	}
	.submodal-inner {
		display: block;
	}
	.submodal-shadow {
		display: block;
		.main-wrapper {
			max-width: 100%;
			background-color: transparent;
		}
		.my-certificat-wrapper-preview {
			max-width: 100%;
		}
	}
	.my-certificat-wrapper {
		margin-top: 36px;
	}
	.authent-block {
		box-shadow: none !important;
		transform: none !important;
	    background-color: #F7F5F5;
	    margin-bottom: 24px;
	}
	.warranty-insurance .info.orange .icon {
	    transform: translateY(-6px);
	}
	@media only screen and (min-width: 1280px) {
		.certificat-mob {
			.close {
				display: none;
			}
		}
		.mob-title, .certificat-mob, .historique-button {
		    display: none;
		}
	}
}
.modal-certificat-validation {
	.modal-title {
		text-align: left;
	}
	&.step-version {
		@width: 272px;
		left: @width; 
		width: calc(100vw - @width);
		.close {
			display: none;
		}
		@width2: 1116px;
		.my-certificat-wrapper-preview {
			//max-width: 900px;
			max-width: @width2;

			display: block;
			padding-bottom: 100px;
		}
		.main-wrapper {
			//max-width: 900px;
			width: @width2;
			max-width: @width2;

			max-height: initial !important;
		}
		.authent-block {
			@width: 627px;
			.title {
				max-width: @width;
			}
			.tabs {
				max-width: @width;
			}
			.tab-target {
				width: @width;
			}
			.description {
				width: @width;
			}
			.warranty-insurance {
				width: @width;	
			}
		}
	}
	.submodal-inner {
		display: block;
	}
	.submodal {
		max-width: 100%;
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-bottom: 0 !important;
		.close {
			position: absolute;
			right: 20px;
		}
		.my-certificat-wrapper-preview {
			margin-left: auto;
			margin-right: auto;
			.main-wrapper {
				max-height: calc(100vh - 203px);
			}
		}
		.submodal-footer {
			padding: 22px;
			position: relative;

			position: fixed;
		    right: 0;
		    bottom: 0;
		    z-index: 20;
		    background: #fff;
		    width: calc(100% - 272px);
			&:after {
				content: "";
			    background-color: #4bd053;
			    width: 100%;
			    height: 4px;
			    position: absolute;
			    top: 0px;
			    left: 0;
			    transition: 0.2s all;
			}
		}
		.link {
			padding: 8px 22px !important;
			text-decoration: underline;
			cursor: pointer;
			display: inline-block;
			color: @dark-blue3;
			&:hover {
				text-decoration: none;
			}
		}
		.button-secondary {
			border: solid 1px #979797;
   			background-color: #fff;
    		color: #666674 !important;
		}
		.auth-block-top-bar {
			.certificate-id {
				//transform: translateY(2px);
				transform: translateY(-2px);
				display: flex;
    			align-items: center;
				.logo {
					//transform: translateY(0px) scale(1.1);
				}
			}
		}
	}
}
.modal-overlay.view-img {
	.submodal-shadow {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px;
	}
	.photo {
		max-height: calc(100vh - 60px);
		max-width: calc(100vw - 60px);
	}
	.close {
		position: absolute;
		top: 10px;
		right: 10px;
		color: #fff;
		text-shadow: none;
		opacity: 1;
		&:hover {
			opacity: .8;
		}
	}
}
.step .upload-wrapper .add-imgs,
.step .upload-wrapper .add-docs {
	display: inline-block;
	color: #3b3b3b;
	transition: .2s all;
	vertical-align: top;
	&:hover {
		//opacity: .7;
	}
	.add-wrapper {
		border: solid 1px #e8e8e8 !important;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 16px;
		text-align: center;
		cursor: pointer;
		font-family: 'Source Sans Pro', sans-serif;
		height: 100%;
		background-color: #fff;
		width: 166.6px;
		height: 181px;
		margin: 0px 16px 5px 0;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		position: relative;
		&:hover {
			.hover {
				opacity: 1; 
			}
		}
		.hover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			//background-color: rgba(0,0,0,.8);
			background-color: #333; 
			display: flex;
			flex-direction: column;
			justify-content: center;
			-webkit-border-radius: 4px;
			border-radius: 4px;
			overflow: hidden;
			opacity: 0;
			transition: .2s all;
			.button {
				margin: 4px;
				border: 1px solid #777; 
				height: 50%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				-webkit-border-radius: 4px;
				border-radius: 4px;
				animation: .2s opacity;
				.icon {
					width: 50px;
					//color: #333;
					color: #F7F7F7; 
					font-size: 20px;
				}
				.button-label {
					//color: #333;
					color: #F7F7F7; 
					width: calc(100% - 50px);
					text-align: left;
    				line-height: 1.4;
    				font-weight: bold;
				}
				&:first-child {
					margin-bottom: 2px;
				}
				&:last-child {
					margin-top: 2px;
				}
				&:hover {
					background-color: rgba(255,255,255,.2);
				}
			}
		}
	}
	img {
		margin-bottom: 11px;
	}
	.txt {
		margin-top: 11px;
		margin-bottom: 4px;
		font-weight: bold;
		font-size: 13px;
	}
	.greyline {
		margin-bottom: 0 !important;
		font-size: 9px;
	}
}
.add-imgs-choice, .add-docs-choice {
	.submodal-shadow {
		display: block;
	}
	.submodal {
		max-width: 530px;
    	text-align: center;
    	padding: 30px;
	}
	.close {
		opacity: .4;
		&:hover {
			opacity: .6;
		}
	}
	.modal-title {
		color: #2a2a3e;
		font-weight: bold;
		font-size: 27px;
		margin: 0 auto 40px;
	}
	.button {
		border: 1px solid #3b3b3b;
		background-color: #f7f7f7;
		padding: 21px;
		width: 100%;
		margin-bottom: 12px;
		transition: .2s all;
		font-weight: bold;
		cursor: pointer;
		&:hover {
			color: #fff;
			background-color: @dark-blue3;
		}
		.id-font5 {
			display: inline-block;
			margin-right: 15px;
		}
	}
	.button + .button {
		margin-bottom: 0;
	}
}
.hidden {
	display: none;
}
.no-assurance {
	.submodal-shadow {
		display: block;
	}
	.submodal {
		max-width: 580px;
    	text-align: center;
	}
	.close {
		opacity: .4;
		&:hover {
			opacity: .6;
		}
	}
	.modal-title {
		color: #2a2a3e;
		font-weight: bold;
		font-size: 27px;
		margin: 0 auto 24px;
		line-height: 1.3;
    	padding-left: 74px;
    	padding-right: 74px;
	}
	.desc {
		font-size: 13px;
		margin-top: 0;
		margin-bottom: 24px;
	}
}
.submodal-inner {
	overflow: auto;
}