.input-group {
    text-align: left;
    display: block;
    margin: 15px 0;

    label {
        text-transform: uppercase;
        color: @black;
        font-weight: @normal;
        padding-bottom: 5px;
        display: block;
        font-size: 0.8em;
    }
    input, .custom-select {
        width: 100%;
        appearance: none;
        background: white;
        outline: none;
        border: solid 1px @dark-beige;
        border-radius: 2px;
        padding: 10px 15px;
        transition: all 0.25s ease;

        .select-selected {
            position: relative;
            &:after {
                content: "›";
                position: absolute;
                top: -7px;
                right: -2px;
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg) scaleY(.9);
                font-size: 27px;
            }
        }

        .select-items {
            background-color: #fff;
            -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.2);
            box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.2);
            width: calc(100% + 2px);
            left: -1px;
            max-height: 240px;
            overflow-y: scroll;
            div {
                width: 100%;
                appearance: none;
                background: white;
                outline: none;
                border: solid 1px @dark-beige;
                border-radius: 2px;
                padding: 10px 15px;
                transition: all 0.25s ease;
                padding: 10px 15px;
                &:not(last-child) {
                    border-bottom: none;
                }
                &:hover {
                    background-color: @dark-blue3;
                }
            }
        }

        &::placeholder {
            color : @light-grey;
            font-weight: @normal;
            font-size: 1em;
        }

        &:focus, &.on-focus {
            border-color: @dark-blue;
            box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
        }
    }
    &.on-error {
        input {
            border-color: @orange;
        }
        .error-message {
            display: block;
            margin-top: 5px;
        }
    }
    .error-message {
        display: none;
        color: @orange;
        font-size: 0.7em;
        i {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .input-icon {
        position: absolute;
        bottom: 14px;
        right: 10px;

        width: 25px;
        height: 25px;
    }

    .row {
        display: table;
        width: 100%;
        position: relative;

        label {
            display: block;
        }

        .input-column {
            position: relative;
            display: table-cell;
            vertical-align: top;
            width: 50%;

            padding-top: 10px;

            label {
                color: @grey;
                font-size: 0.8em;
            }

            +.input-column {
                padding-left: 10px;
            }
        }

        @media only screen and (max-width : 665px) {
            .input-column {
                width: 100%;
                display: block;
            }
        }
    }
}

.form-wrapper {
    text-align: center;

    .button-main {
        margin-top: 25px;
    }

    .lost-password {
        display: block;
        margin-top: 15px;
    }
}

.custom-select {
    position: relative;
    select {
        display: none;
    }
}
.select-selected.select-arrow-active:after {
    border: none;
    top: rem(7);
}
.select-items div,.select-selected {
    border: none;
    cursor: pointer;
}
.select-selected {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.select-items div {
    border-top: 1px solid rgba(0,0,0,.1);
    background-color: #fff;
    &:last-child {
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    &:hover {
        background-color: @dark-blue3;
        color: #fff;
    }
}
.select-items {
    position: absolute;
    background-color: transparent;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 9;
    .mCSB_container {
        margin-right: 0 !important;
    }
}
.select-hide {
    display: none;
}
