.new-certificats-forms {
	display: flex;
	.aside {
		width: 50%;
		.image-wrapper {
			position: fixed;
			top: 0;
			left: 0;
			width: 50%;
			height: 100vh;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.form-wrapper {
		padding-bottom: 160px;
		width: 50%;
		min-width: 448px;
		background-color: #fff;
		color: @dark-blue3;
		padding: 44px 0 180px;
		text-align: left;
		h1 {
			font-weight: 100;
			font-size: 39px;
			line-height: 49px;
			width: 67%;
			max-width: 448px;
			margin: 0 auto 47px;
		}
		.input-group {
			width: 67%;
			max-width: 448px;
			margin: 0 auto;
			input, label, textarea {
				display: block;
				width: 100%;
			}
			input, textarea, .custom-select {
				margin-bottom: 35px;
			}
		}
	}
	.note-editor {
		border: solid 1px #e7e1cd !important;
    	border-radius: 2px;
		.note-style, .note-fontname, .note-color, .note-table, .note-insert, .note-view {
			display: none;
		}
		.note-editing-area {
			min-height: 120px;
		}
		.note-statusbar {
			background-color: transparent !important;
    		border-top: none !important;
		}
	}
	.for-file {
		margin-top: 35px;
		.info {
		    float: none;
		}
	}
	.info {
		border: #cbced5 1px solid;
		color: #cbced5;
		-webkit-transform: translateY(2px);
		transform: translateY(2px);
		width: 17px;
    	height: 17px;
    	text-transform: none;
    	.icon {
    		font-size: 14px;
    	}
    	.infobulle {
    		padding: 21px 25px;
    		width: 287px;
    		margin-left: -120px;
    		&:before, &:after {
    			left: calc(100% - 23px) !important;
    		}
    	}
	}
	.files-inputs {
		display: flex;
		margin-bottom: 15px;
		width: calc(100% + 20px);
		.formats {
			font-size: 11px;
			font-weight: 400;
			br {
				display: block;
			}
		}
		.upload-wrapper {
			width: calc(33% - 10px);
			&:not(:last-child) {
				margin-right: 10px;
			}
			.upload-img {
				width: calc(33% - 10px);
				margin-top: 0;
				position: absolute;
				cursor: pointer;
				.big-upload-btn {
					height: 100%;
				}
				input {
					height: 100%;
				}
				.photo {
					display: none;
					position: relative;
					width: 100%;
					height: 100%;
					background-position: center;
					background-size: cover;
				}
			}
			.label-photo {
				margin-top: calc(100% + 5px);
				font-size: 13px;
			}
			.path {
				font-size: 13px;
			}
			.bin {
			    right: 9px;
    			bottom: 4px;
			}
		}
	}
	.button-wrapper {
		border-top: 1px solid @very-light-grey;
		background-color: #fff;
		position: fixed;
		right: 0;
		bottom: 0;
		padding: 21px;
		width: 50%;
		text-align: right;
		.button-main {
			margin: 0 !important;
		}
	}
}
.info-popin-hover {
	display: none;
	position: fixed;
	//left: 0;
	//top: 98px;
	z-index: 10000;
	background-color: #fff;
	border: 1px solid #C0C0C0;
	padding: 40px;
	padding-top: 60px;
	text-align: left;
	margin: 0 25px;
	-webkit-box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
	box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);

	margin: auto;

	top: calc(50% + 40px);
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	width: calc(100% - 50px);

	.infobulle {
		overflow: auto;
		max-height: calc(100vh - 240px);
	}
	.close {
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer;
	}
	p {
		margin-top: 5px;
	}
	.img-wrapper {
		max-width: 300px;
		max-height: 300px;
		text-align: center;
		margin: 30px auto;
		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}
	&.on {
		display: block;
		//transform: none;
		opacity: 1;
		z-index: 999999;
	}
}
@media only screen and (min-width : 800px) {
	.info-popin-hover {
		max-width: 80%;
	}
}
@media only screen and (max-width : 800px) {
	.new-certificats-forms {
		display: block;
		.aside, .form-wrapper {
			width: 100%;
		}
		.aside {
			height: 300px;
			.image-wrapper {
				width: 100%;
				height: 100%;
				position: initial;
			}
		}
		.button-wrapper {
			width: 100%;
		}
	}
}
@media only screen and (max-width : 650px) {
	.info-popin-hover {
		padding: 20px;
    	padding-top: 50px;
    	width: calc(100% - 10px);
    	max-width: 98%;
    	top: calc(50% + 30px);
    	.infobulle {
    		max-height: calc(100vh - 180px);
    	}
	}
}
@media only screen and (max-width : 455px) {
	.new-certificats-forms {
		.form-wrapper {
			padding-top: 53px;
			min-width: auto;
			h1, .input-group {
				width: calc(100% - 40px);

			}
		}
	}
}





