.growl-notification-wrapper {
    position: absolute;
    top: 7%;
    right: 20px;

    @media only screen and (max-width : 665px) {
     right: 0;
     top: 10%;
    }
}

.growl-notification {
    position: relative;
    padding: 10px 15px;
    margin-bottom: 10px;
    
    border-radius: 2px;
    border: solid 1px #e7e1cd;
    
    color: @grey;
    font-weight: @medium;
    
    animation:0.6s ease shake-appear forwards;
    
    .growl-title {
        font-weight: @medium;
        font-size: 1em;
        
        img, svg {
            width: 20px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: bottom;
        }
    }
    
    .growl-message {
        font-weight: @normal;
        padding-left: 30px;
        margin-bottom: 0;
    }
    
    .common-arrow {
        position: absolute;
        top: 20px;
        right: 10px;
    }
    
    &.error {
        background-color: @light-red;
        border-color: @red;
        
        .common-arrow {
            &::before, &::after {
                background-color: fade(@red,50);
            }
        }
        
        .growl-title, .growl-message {
            color: @red;
        }
    }
    @media only screen and (max-width : 665px) {
        padding: 10px 5px;
    }
    
}