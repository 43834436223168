.grey-font {
    color: @grey !important;
}

.mb-25 {
    margin-bottom: 25px;
}

.w-80 {
    max-width: 80%;
}

.margin-auto {
    margin: auto;
}

.no-border {
    border: none !important;
}

.no-arrow {
    &:after, &:before {
        display: none;
    }
}

.inline-block {
    display: inline-block;
}

.font-small {
    font-size: 0.9em !important;
}

