.common-arrow {
    position: absolute;
    display: block;

    top: 50%;
    right: 15px;

    transform: translate3d(0,-50%,0);

    width: 12px;
    height: 12px;

    &::before, &::after {
        content: '';
        position: absolute;

        top: 50%;
        left: 50%;

        width: 100%;
        height: 1px;

        background-color: @grey;

        transform: translate3d(-50%,-50%,0) rotate(45deg);
    }
    &:after {
        transform: translate3d(-50%,-50%,0) rotate(-45deg);
    }

}