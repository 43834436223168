.common-frame {
    border-radius: 2px;
    padding: 0 20px;   
    
    .common-appearance;
    
    .brand-wrapper .certificate-management {
        font-size: 0.6em;
        font-weight: @normal;
        margin-top: 0;
    }
    
    .frame-header {
        text-align: center;
        padding-bottom: 35px;
        
        .brand-wrapper {
            margin-top: 10px;
            display: block;

            img, svg {
                max-width: 150px;
            }
        }
        
        span {
            margin-top: 15px;
            display: block;
            font-weight: @bold;
            font-size: 1.2em;
        }
    }
    
    .common-regular-grey {
        padding: 0 25px;

        &.empty-content {
            max-width: 70%;
            margin: -15px auto;
        }
        
        @media only screen and (max-width : 480px) {
            padding: 0;
        }
    }
    
    .frame-body {
        text-align: center;
        
        .common-warning {
            margin-top: 25px;
        }
    }
    
    .frame-footer {
        display: block;
        text-align: center;
        margin-top: 50px;
    }
    
    .common-sub-title {
        margin-bottom: 0.5em;
        +p {
            margin-top: 0;
            
            +.form-wrapper {
                margin-top: 2em;
            }
        }
    }
    
    // Dashed frame
    &.dashed {
        box-shadow: none;
        border:dashed 1px @border-color;
        
        .frame-header {
            padding-bottom: 0;
        }
    }
    
    // Details frame
    
    &.details {
        .frame-header {
            padding-bottom: 10px;
        }
        .frame-body {
            text-align: left;
        }
    }
    
    @media only screen and (max-width : 480px) {
        box-shadow: none;
        border:none;
        padding: 35px 10px;
    }
}