.side-menu-grey-bg {
	background-color: #F8F8F8;
}
.datas-container {
	main {
		.tabs.with-sorting {
			margin-top: 40px;
			ul {
				display: inline-block;
				li, li:first-child {
					position: relative;
					padding-left: 17px;
					margin-right: 20px;
					transition: .3s all;
					a {
						padding: 20px 9px 20px 20px;
					}
					.count {
						color: #3B3B3B;
						font-weight: bold;
					}
					&:not(.current) {
						opacity: .5;
						.count {
							//font-weight: normal;
							opacity: .8;
						}
					}
					&:hover, .current {
						opacity: 1;
						.count {
							opacity: 1;
						}
					}
					&:before {
						content: "";
						position: absolute;
						height: 17px;
						width: 4px;
						border-radius: 2px;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						background-color: transparent;
					}
					&:after {
						left: 0;
					}
					&.tab-accepted {
						&:before {
							background-color: #6EA400;
						}
					}
					&.tab-rejected {
						&:before {
							background-color: #FF0000;
						}
					}
					&.tab-processing {
						&:before {
							background-color: #CF7C00;
						}
					}
					&.tab-archived {
						&:before {
							background-color: #9B9B9B;
						}
					}
				}
			}
		}
		.sorting {
			display: inline-block;
			float: right;
			padding: 20px 0;
			font-weight: normal;
			font-size: 16px;
			.sort-by {
				font-weight: bold;
				padding-right: 24px;
				position: relative;
				cursor: pointer;
				&:after {
					content: "›";
					position: absolute;
					top: 3px;
					right: 2px;
					transform: rotate(90deg);
				}
				&.on {
					&:after {
						transform: rotate(-90deg) translateY(-3px);
					}
				}
				ul {
					display: none;
					position: absolute;
					right: 0;
					top: 100%;
					margin-top: 10px;
					background-color: #fff;
					border: 1px solid #D8D8D8;
					border-radius: 7px;
					z-index: 2;
					-webkit-box-shadow: 0px 25px 26px 0px rgba(0,0,0,0.16);
					-moz-box-shadow: 0px 25px 26px 0px rgba(0,0,0,0.16);
					box-shadow: 0px 25px 26px 0px rgba(0,0,0,0.16);
					text-align: right;
					padding: 5px 0;
					li {
						padding: 6px 23px;
						margin-right: 0;
					}
				}
			}
		}
		.request-cards-wrapper {
		    margin-top: 34px;
			animation: appear forwards 0.4s  cubic-bezier(0.785, 0.135, 0.15, 0.86);
			&#request-cards-accepted, &#request-cards-rejected {
				display: none;
			}
		}
	}
}
.request-card {
	background-color: #fff;
	border: 1px solid #DCDCDC;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	padding: 35px 48px;
	display: flex;
	align-items: center;
	align-content: flex-end;
	border-radius: 8px;
	color: @dark-blue3;
	position: relative;
	margin: 15px 0;
	cursor: pointer;
	&:before {
		content: "";
		position: absolute;
		background-color: transparent;
		border-radius: 6px;
		width: 6px;
		height: 103px;
		left: -3px;
		top: 50%;
		transform: translateY(-50%);
	}
	&.accepted {
		&:before {
			background-color: #6EA400;
		}
	}
	&.rejected {
		&:before {
			background-color: #FF0000;
		}
	}
	&.processing {
		&:before {
			background-color: #CF7C00;
		}
	}
	.request-title {
		font-weight: bold;
		width: 100%;
		flex-grow: 1;
		font-size: 19px;
	}
	.request-number-wrapper {
		font-weight: bold;
		min-width: 277px;
		flex-grow: 2;
		.request-number {
			background-color: #F5F7FF;
			border: 1px solid #727DB4;
			padding: 28px 57px;
			display: inline-block;
			border-radius: 12px;
		}
	}
	.date-wrapper {
		font-weight: bold;
		min-width: 178px;
		flex-grow: 3;
		position: relative;
		padding-left: 30px;
		.id-font {
			margin-right: 14px;
			display: inline-block;
		}
		&:before {
			content: "";
			position: absolute;
			background-color: #D4D4D4;
			height: 47px;
			width: 1px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		.since-date {
			font-size: 15px;
			color: #646464;
			font-weight: normal;
			margin: 0;
			margin-top: 12px;
			padding-left: 32px;
		}
	}
}
.transfert-list {
	.sort-by {
		ul {
		    min-width: 240px;
		    li {
		    	margin-right: 0 !important;
		    }
		}
	}
}
.transfert-card {
	background-color: #fff;
	border: 1px solid #DCDCDC;
	-webkit-box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: top;
	align-content: flex-start;
	border-radius: 8px;
	color: @dark-blue3;
	position: relative;
	margin: 15px 0;
	border-radius: 8px;
	-webkit-radius: 8px;
	-moz-border-radius: 8px;
	justify-content: space-between;
	&.open-modal {
		cursor: pointer;
	}
	.number-wrapper {
		background-color: #F2F6FF;
		width: 175px;
		text-align: left;
		font-size: 16px;
		font-weight: bold;
		padding-top: 43px;
		padding-left: 54px;
		padding-bottom: 54px;
		border-radius: 8px;
		-webkit-radius: 8px;
		-moz-border-radius: 8px;
		.count {
			display: block;
			font-size: 39px;
		}
	}
	.name-wrapper {
		padding-top: 43px;
		padding-left: 34px;
		//width: 370px;
		.title {
			font-size: 24px;
			font-weight: bold;
			margin: 0;
			margin-bottom: 13px;
		}
		.date {
			font-size: 16px;
			margin: 0;
		}
	}
	.state-wrapper {
		padding-top: 39px;
		margin-left: 45px;
		.state {
			font-size: 16px;
			width: 111px;
			text-align: left;
			display: inline-block;
			padding-left: 18px;
			position: relative;
			.count {
				font-size: 36px;
				display: block;
				margin-bottom: 3px;
			}
			&:before {
				content: "";
			    position: absolute;
			    height: 47px;
			    width: 4px;
			    border-radius: 2px;
			    left: 0;
			    top: 9px;
			    background-color: transparent;
			}
			&.accepted {
				&:before {
					background-color: #6EA400;
				}
			}
			&.rejected {
				&:before {
					background-color: #FF0000;
				}
			}
			&.processing {
				&:before {
					background-color: #CF7C00;
				}
			}
		}
	}
}
.paging {
	margin-top: 10px;
	li {
		border-radius: 50%;
		text-align: center;
		width: 45px;
		height: 45px;
		display: inline-block;
		color: @dark-blue3;
		margin-right: 5px;
		&.current {
			background-color: #fff;
		}
		a {
			font-size: 17px;
			width: 100%;
			line-height: 45px;
		}
	}
}