.certificate {
    position: relative;
    padding: 25px 20px 5px 20px;
    margin: 15px 0;

    box-shadow: 5px 10px 10px 0 rgba(7, 27, 79, 0.1);
    background-color: @beige;
    border: solid 1px #e7e1cd;

    background-image: url('../img/ribbon-pattern.png');
    background-position: top;
    background-repeat: repeat-x;
    background-size: 12px;

    .certificate-image {
        position: absolute;
        top: 0;
        right: 15px;

        transform: translate3d(0,-30%,0);
    }

    .certificate-title {
        padding-bottom: 0.5em;
        display: block;
    }

    label {
        color: @dark-blue;
    }
}