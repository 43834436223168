.certificate-frame {
    position: relative;
    padding: 25px;
    border-radius: 5px;
    border: solid 1px @dark-beige;
    background-color: @beige;

    &::before {
        content: '';
        background-image: url('../img/ui/icons/icon-award.png');
        background-position: center;
        background-size: cover;

        position: absolute;
        
        right: 10px;
        top: -30px;

        width: 50px;
        height: 80px;
        
    }

    .wallet-information-list {
        li {
            i, 
            strong  {
                color: @black;
            }
        }
    }
}