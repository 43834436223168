.login-page {
    h1 {
        text-align: center;
    }
    .goodsid-login-information {
        padding-right: 35px;
        header {
            width: 100%;
            img {
                max-width: 120px;
                display: block;
                margin: 2em auto;
            }
            
        }
        h3 {
            color: @dark-blue;
            margin: 2em 0 1em 0;
        }
        
        p {
            text-align: left;
            a {
                font-weight: @bold;
            }
        }
    }
    
}

.login-frame {
    
    .log-in {
        text-align: center;
        font-size: 0.8em;
        color: @dark-blue;
        
        a {
            text-decoration: underline;
        }
    }
    
    .checkbox {
        label {
            margin-left: 35px;
            color: @grey;
            letter-spacing: 0;
            line-height: 1.2em;
            
            &::before {
                width: 25px;
                height: 25px;
                
                left: -35px;
                top: 0px;
                
                border-color: @dark-beige;
            }
            &::after {
                top: 5px;
                left: -30px;
                
                width: 15px;
                height: 15px;
            }
        }
    }
    
    form {
        padding: 20px 30px 35px 30px;
    }
    
    footer {
        padding: 30px 30px;
        background-color: @light-blue;
        
        color: @dark-blue;
        font-size: 0.8em;
        line-height: 1.5;
    }
}

.flex-wrapper {
    display: flex;
    flex-flow: row wrap;
    
    @media only screen and (max-width: 640px) {
        aside {
            order: 2;
            
            .wallet-login-header {
                margin-top: 0;
            }
        }
        
        .login-frame {
            order: 1;   
        }
    }
    
    
    
}