.side-modal, #transfert-modal {
	transition: .5s all;
	position: fixed;
	//overflow-y: scroll;
	z-index: 30;
	top: 0;
	right: -1088px;
	background-color: #fff;
	height: 100vh;
	width: 1087px;
	border-left: #C4C4C4 1px solid;
	-webkit-box-shadow: 0px 10px 55px 0px rgba(181,181,181,1);
	-moz-box-shadow: 0px 10px 55px 0px rgba(181,181,181,1);
	box-shadow: 0px 10px 55px 0px rgba(181,181,181,1);
	padding-bottom: 160px;
	opacity: 0.2;
	&.on {
		right: 0;
		opacity: 1;
	}
	.scrollbar-inner {
	    height: calc(100vh - 131px);
	}
	&.transfert-detail-modal {
		.scrollbar-inner {
	    	height: 100vh;
	    }
	}
	.close-modal {
		&:before, &:after {
			height: 2px;
			background-color: @dark-blue3;
		}
	}
	.container {
		width: calc(100% - 122px);
		margin: 0 auto;
	}
	.sticky-buttons {
		position: fixed;
		bottom: 0;
		right: 0;
		background-color: #fff;
		border-top: #DFDFDF 1px solid;
		width: 1086px;
		margin-left: -61px;
		z-index: 9;
		box-shadow: 0 -7px 73px -9px rgba(22, 28, 69, 0.12);
		.container {
			padding-top: 26px;
			padding-bottom: 26px;
			.step-buttons {
				float: right;
			}
			div {
				display: inline-block;
				&.btn {
					padding: 30px 54px;
					cursor: pointer;
					transition: all 0.25s ease;
					font-weight: bold;
					font-size: 17px;
				}
				&.back {
					position: relative;
					padding-left: 47px;
					color: @dark-blue3;
					text-decoration: underline;
					&:before {
						position: absolute;
						content: url("../img/ui/icons/transfert-back-arrow.svg");
						top: 50%;
						left: 0;
						transform: translateY(calc(-50% + 3px));
					}
					&:hover {
						opacity: .7;
					}
				}
				&.reject, &.accept, &.confirm {
					&:hover {
						box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.2)
					}
				}
				&.reject {
					border: 1px solid #E8E8E8;
					background-color: #fff;
					color: @dark-blue3;
				}
				&.accept, &.confirm {
					background-color: @dark-blue3;
					color: #fff;
				}
			}
		}
	}
}

#transfert-modal {
	.step-accept, .step-reject, .step-acceptation-done, .step-rejection-done {
		display: none;
	}

	.form-header {
		border-bottom: solid 1px #e0e0e0;
		padding-bottom: 38px;
		margin-top: 78px;
		margin-bottom: 38px;
		.title {
			color: @dark-blue3;
			font-size: 30px;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
		}
		.right {
			float: right;
			font-size: 14px;
			color: @dark-blue3;
			margin-top: 11px;
			li {
				display: inline-block;
				&:not(:last-child) {
					border-right: solid 1px #e0e0e0;
					padding: 0 24px;
					.id-font {
						margin: 0 11px;
					}
				}
				&.status {
					padding-left: 47px;
					position: relative;
					font-weight: bold;
					&:before {
						content: "";
						border-radius: 50%;
						width: 14px;
						height: 14px;
						top: 2px;
						left: 24px;
						position: absolute;
					}
					&.processing {
						&:before {
							background-color: #CF7C00;
						}
					}
					&.accepted, &.confirm {
						&:before {
							background-color: #6EA400;
						}
					}
					&.rejected {
						&:before {
							background-color: #f00;
						}
					}
				}
			}
		}
	}
	form {
		.details-table {
			width: 100%;
			margin-bottom: 15px;
			border: 1px #e0e0e0 solid;
			border-collapse: collapse;
			color: @dark-blue3;
			-webkit-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
			-moz-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
			box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
			thead {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 17px;
				text-align: center;
				th {
					border-bottom: 1px #e0e0e0 solid;
					padding: 34px 106px;
					&:first-child {
						border-right: 1px #e0e0e0 solid;
					}
				}
			}
			tbody {
				text-align: left;
				th {
					font-size: 20px;
					font-weight: normal;
					text-align: left;
					padding-top: 19px;
					padding-bottom: 14px;
				}
				tr {
					text-align: left;
					padding-bottom: 19px;
					&:first-child {
						th {
							padding-top: 51px;
						}
					}
					th, td {
						&:first-child {
							border-right: 1px #e0e0e0 solid;
							padding-right: 73px;
							padding-left: 62px;
						}
						&:last-child {
							background-color: #FBF9F6;
							padding-left: 85px;
							padding-right: 40px;
						}
					}
					td {
						padding-bottom: 19px;
						&:last-child {
							font-weight: bold;
							font-size: 19px;
						}
					}
					&:last-child {
						td {
							padding-bottom: 80px;
						}
					}
				}
				.question {
					float: inherit;
					.infobulle {
						margin-left: 167px;
						&:before, &:after {
							left: 110px !important;
						}
					}
				}
				.got-arrow {
					position: relative;
					&:after {
						position: absolute;
						top: 50%;
						transform: translateY(-66%) translateX(50%);
						right: 0;
						content: url("../img/ui/icons/table-arrow.svg");
					}
				}
				input, .custom-select {
					background-color: #F9F9F9;
					border: #E4DABB 1px solid;
					font-weight: bold;
					border-radius: 13px;
					padding: 26px;
					width: 100%;
					background-image: url("../img/ui/icons/edit.svg");
					background-repeat: no-repeat;
					background-position: 377px 23px;
					color: @dark-blue3;
					&:focus {
						border: @dark-blue3 1px solid;
						background-color: #fff;
						outline: none !important;
					}
					&.valid {
						color: #5F8D00;
					}
					&.error {
						color: #CF7C00;
					}
					&:disabled {
						background-image: none;
					}
					.select-items {
						color: @dark-blue3;
					}
				}
				.download-photo {
					background-color: #fff;
					border: #DFDFDF 1px solid;
					font-weight: bold;
					border-radius: 13px;
					padding: 26px;
					width: 100%;
					font-weight: bold;
					.id-font {
						margin-right: 18px;
						transform: scale(1.1);
					}
				}
				.select-items {
					background-color: #F9F9F9;
					border-radius: 13px;
					-webkit-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
					-moz-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
					box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
					width: calc(100% - 24px);
					margin-left: 12px;
					div {
						padding: 26px;
						border: #E4DABB 1px solid;
						&:not(:first-child) {
							border-top: none;
						}
					}
				}
			}
			.info {
				background-color: #F9F9F9;
			}
		}
	}

	.warranties-frame {
		margin-bottom: 15px;
		-webkit-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
		-moz-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
		box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
	}
	.step-accept, .step-reject {
		.form-header {
			border-bottom: none;
			width: calc(100% - 206px);
			margin-left: 133px;
			.subtitle {
				font-size: 24px;
				letter-spacing: 2px;
				color: @dark-blue3;
			}
			p:not(.title):not(.subtitle) {
				color: @dark-blue3;
				font-size: 18px;
				line-height: 34px;
				margin-top: 43px;
				margin-bottom: 0;
				.icon {
					display: inline-block;
					transform: translateY(5px);
					margin: 0 8px;
				}
			}
			textarea {
				margin-top: 26px;
				height: 281px;
				max-height: initial;
				background-color: #F9F9F9;
			}
		}
		.icon-wrapper {
			background-color: #fff;
			width: 90px;
			height: 90px;
			border: 1px solid #D6D6D6;
			text-align: center;
			border-radius: 50%;
			position: absolute;
		    left: 50%;
		    transform: translateY(-25px);
		    z-index: 1;
			span {
				color: @dark-blue3;
				font-size: 50px;
    			margin-top: 13px;
			}
		}
		.details-recap {
			width: calc(100% - 206px);
			margin-left: 133px;
			margin-bottom: 15px;
			border: 1px #e0e0e0 solid;
			border-collapse: collapse;
			color: @dark-blue3;
			-webkit-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
			-moz-box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
			box-shadow: 0px 8px 23px 0px rgba(224,224,224,1);
			padding: 142px 0px 125px;
			position: relative;
			overflow: hidden;
			&:after {
				content: "t";
				font-family: 'Goods ID';
				position: absolute;
				font-size: 400px;
				right: -108px;
				bottom: -83px;
				color: @dark-blue3;
				opacity: .03;
			}
			table {
				border-collapse: collapse;
				width: 100%;
				tr {
					td {
						padding-top: 10px;
						padding-bottom: 10px;
						&:first-child {
							width: 37%;
							text-align: right;
						}
						&:last-child {
							width: 63%;
							font-weight: bold;
							text-align: left;
							padding-left: 63px;
						}
					}
					&.serial-row {
						td {
							padding-top: 0px;
							padding-bottom: 0px;
						}
					}
					&.certificat-row {
						td {
							padding-top: 25px;
							padding-bottom: 25px;
						}
					}
				}
			}
		}
		textarea {
			width: 100%;
			height: 281px;
			margin-bottom: 15px;
		}
	}
	.bubble {
		padding: 14px 18px;
		margin-left: -16px;
		background-color: #F5F7FF;
		border: 1px solid #727DB4;
		border-radius: 13px;
		display: inline-block;
	}
	.step-acceptation-done, .step-rejection-done {
		.confirmation-message {
			width: calc(100% - 206px);
			margin-left: 133px;
			margin-top: calc(50vh - 65px);
			transform: translateY(-50%);
			color: @dark-blue3;
			.title {
				text-transform: uppercase;
				font-size: 30px;
				margin-bottom: 45px;
			}
			p {
				font-size: 27px;
				letter-spacing: 3px;
				line-height: 63px;
			}
		}
	}
	.step-rejection-done {
		.confirmation-message {
			p {
				font-size: 18px;
				line-height: 34px;
			}
		}
	}
	.events-list {
		&:after {
			display: none;
		}
	}
}
.transfert-detail-modal {
	.container {
		width: 818px !important;
		margin-bottom: 60px !important;
	}
	.form-header {
		.right {
			.id-font {
				margin: 0 5px;
			}
		}
	}
	.transfert-card {
		display: block !important;
		margin: 20px 0;
		-webkit-box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
		.name-wrapper {
			//width: 320px;
		}
		.top-part {
			display: flex;
			border-bottom: 1px solid #DCDCDC;
			justify-content: space-between;
			.number-wrapper {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			.name-wrapper {
				padding-left: 27px;
			}
		}
		.bottom-part {
			padding: 28px 68px;
			.title {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 15px;
			}
			p:not(.title) {
				letter-spacing: 0.5px;
				line-height: 26px;
			}
		}
	}
	.transfert-details {
		background-color: #fff;
		border: 1px solid #DCDCDC;
		-webkit-box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
		border-radius: 8px;
		color: @dark-blue3;
		position: relative;
		padding: 0;
		margin: 15px 0;
		border-radius: 8px;
		-webkit-radius: 8px;
		-moz-border-radius: 8px;
		.top-part {
			padding: 50px 66px 15px 66px;
			width: 100%;
			background-color: #FBFCFF;
			border-radius: 8px;
			-webkit-radius: 8px;
			-moz-border-radius: 8px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			border-bottom: 1px solid #DCDCDC;
			position: relative;
			overflow: hidden;
			z-index: 1;
			&:before {
				content: "t";
			    font-family: 'Goods ID';
			    position: absolute;
			    font-size: 230px;
			    right: -65px;
			    bottom: -77px;
			    color: #061A4F;
			    opacity: 0.03;
			    z-index: -1;
			}
			.infos-wrapper {
				display: flex;
				align-items: top;
				padding-bottom: 45px;
				border-bottom: 1px solid #DCDCDC;
				justify-content: space-between;
				width: 100%;
				.number-wrapper {
					width: 175px;
					text-align: left;
					font-size: 16px;
					font-weight: normal;
					border-radius: 8px;
					-webkit-radius: 8px;
					-moz-border-radius: 8px;
					.count {
						display: block;
						font-size: 39px;
						font-weight: bold;
					}
				}
				.details-wrapper {
					margin-top: 3px;
					p {
						margin: 10px;
				    	margin-top: 16px;
						.label {
							width: 100px;
							text-align: right;
							display: inline-block;
							margin-right: 12px;
						}
						.value {
							font-weight: bold;
							text-transform: uppercase;
						}
					}
				}
			}
			.count-wrapper {
				margin-top: 22px;
				color: #333333;
				font-size: 16px;
				ul {
					li {
						display: inline-block;
						margin-right: 18px;
						span {
							position: relative;
							display: inline-block;
							color: #fff;
							font-weight: bold;
							z-index: 1;
							width: 30px;
							height: 30px;
							text-align: center;
							margin-right: 10px;
							&:before {
								content: "";
								border-radius: 50%;
								position: absolute;
								top: -5px;
								left: 0;
								width: 100%;
								height: 100%;
								z-index: -1;
							}
							&.accepted {
								&:before {
									background-color: #6EA400;
								}
							}
							&.rejected {
								&:before {
									background-color: #FF0000;
								}
							}
							&.processing {
								&:before {
									background-color: #CF7C00;
								}
							}
						}
					}
				}
			}
		}
		.history-part {
			width: 100%;
			padding: 52px 96px 25px;
			.table-wrapper {
				display: none;
			}
			table {
				width: 100%;
				border-collapse: collapse;
				//margin-bottom: 32px;
				margin-bottom: 45px;
				tr {
					td {
						border-bottom: 1px solid #DCDCDC;
						padding-bottom: 16px;
					}
				}
				thead {
					font-size: 16px;
				}
				tbody {
					font-size: 15px;
					tr {
						td {
							padding-top: 12px;
							vertical-align: center;
							height: 70px;
							font-weight: bold;
							&:nth-child(1) {
								width: 49%;
							}
							&:nth-child(2) {
								width: 27.5%;
							}
							&:nth-child(3) {
								width: 23.5%;
							}
							.accepted {
								color: #6EA400;
							}
							.rejected {
								color: #FF0000;
							}
							.processing {
								color: #CF7C00;
							}
							.bubble {
								padding-left: 52px !important;
								padding-right: 52px !important;
								margin-left: 0 !important;
							}
						}
					}
				}
			}
		}
		.inner-slide {
			cursor: pointer;
			font-size: 38px;
			font-weight: bold;
			transform: rotate(90deg) scaleX(1.1);
			transform-origin: center;
		    position: absolute;
		    bottom: 30px;
    		right: 46px;
		    width: 19px;
		    height: 19px;
		    &:hover {
		    	opacity: .8;
		    }
		    span {
		    	transform: translateX(3px) translateY(-17px);
    			display: inline-block;
		    }
		    &.on {
		    	transform: rotate(-90deg) scaleX(1.1);
		    }
		}
	}
}




