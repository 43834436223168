.toggle-overlay {
    cursor: pointer;
}

.notification-overlay {
    position: absolute;
    right: -1px;
    top: 120%;
    
    width: 325px;
   
    border:solid 1px @light-grey;
    border-radius: 2px;
    background-color: #ffffff;
    
    display: none;
    overflow: hidden;
    
    .overlay-header {
        padding: 20px;
        padding-bottom: 10px;
        
        span {
            text-transform: uppercase;
        }
    }
    
    .overlay-body {
        .overlay-item {
            position: relative;
            padding: 20px 20px;
            cursor: pointer;
            
            p, .overlay-date, .overlay-item-description {
                padding-left: 20px;
            }
            
            p {
                font-weight: @medium;
                color: @light-grey;
                font-size: 0.9em;
                margin: 0;
                
                transition: color 0.25s ease;
                
                max-width: 95%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .overlay-date {
                color: @grey;
                font-weight: @normal;
                font-size: 0.8em;
            }
            +.overlay-item {
                // margin-top: 15px;
                // border-top: 1px solid @light-grey;

                &:after {
                    content: '';
                    position: absolute;

                    top: 0;
                    left: 20px;

                    width: calc(100% - 40px);

                    height: 1px;
                    background-color: @light-grey;
                    transition: opacity 0.25s ease;
                    
                }
            }
            
            .overlay-item-illustration {
                position: absolute;
                max-width: 40px;
                
                top: 50%;
                left: -15px;
                
                transform: translate3d(-50%,-50%,0);
                opacity: 0;
                transition: all 0.25s ease;
            }
            
            .overlay-item-description {
                display: block;
                color: @grey;
                font-weight: @normal;
                font-size: 0.8em;
                margin: 5px 0;
                max-width: 95%;
                transition: color 0.25s ease;
            }
            
            .overlay-arrow {
                position: absolute;
                top: 50%;
                right: 20px;
                
                transform: translate3d(-50%,-50%,0);
                opacity: 0;
                transition: all 0.25s cubic-bezier(.645, .045, .355, 1);
            }
            
            &.unread {
                &:before {
                    content: '';
                    position: absolute;
                    
                    top: 50%;
                    left: 20px;
                    
                    width: 10px;
                    height: 10px;
                    
                    background-color: @blue;
                    border-radius: 50%;
                    transition: all 0.25s ease;
                }
            }
            
            &:hover {
                p, .overlay-item-description {
                    color: @dark-blue;
                }
                .overlay-arrow {
                    opacity: 1;
                    transform:translate3d(0,-50%,0);
                }
            }
        }
    }
    
    &.active {
        box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
        animation: overlay-appear 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
        display: block;
    }
}

// Wallet header notification overlay 

.wallet-header {
    .notification-overlay {
        .overlay-item {
            &.unread {
                &:before {
                    background-color: @beige;
                }
            }
            
            &:hover {
                background-color: @beige;

                &:after {
                    display: none;
                }
                // In order to remove the border of the next child
                +.overlay-item {
                    &:after {
                        opacity: 0;
                    }
                }

                &.unread {
                    &:before {
                        background-color: @dark-blue;
                    }
                }
                
                .overlay-item-illustration {
                    transform: translate3d(0,-50%,0);
                    opacity: 1;
                }
            }
        }
    }
}

@keyframes overlay-appear {
    0%{
        opacity: 0;
        transform: scaleY(0.9) translate3d(0,-30px,0);
        box-shadow: 5px 10px 0 0 rgba(7, 27, 79, 0.1);
    }
    100%{
        opacity: 1;
        transform: scaleY(1) translate3d(0,0px,0);
        box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
    }
}