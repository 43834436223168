.button-file {

    border: solid 1px @beige;
    padding: 9px 10px;

    color: @dark-blue;
    font-size: 0.9em;

    img, svg {
        display: inline-block;
        vertical-align: middle;

        &.file-icon {
            margin-right: 10px;
            margin-top: -1px;
        }

        &.download-icon {
            margin-left: 15px;
        }
    }

}