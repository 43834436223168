.common-select {
    appearance: none;
    background: none;
    border: none;
    
    font-weight: @bold;
    color: @dark-blue;
    padding-right: 20px;
    margin-top: 3px;

    .select-icon {
        display: inline-block;
        vertical-align: middle;
        max-width: 30px;
        height: 30px;
    }
    
    &:before, &:after {
        content: '';
        
        position: absolute;
        
        top:50%;
        right: 15px;
        
        width: 10px;
        height: 2px;
        
        background-color: @dark-blue;
        border-radius: 2px;
        
        transform:translate3d(0,-50%,0) rotate(45deg);
    }
    
    &:after {
        transform:translate3d(6px,-50%,0) rotate(-45deg);
    }
    
    option {
        
    }
}