.common-goods-nav {
    display: inline-block;
    vertical-align: middle;

    span {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.9em;
        margin-top: 3px;

        .goods-amount {
            display: inline-block;
            vertical-align: middle;
            
            padding: 3px 6px;
            margin-left: 5px;

            border-radius: 50%;

            font-size: 0.7em;
            font-style: normal;
            font-weight: @bold;

            color: @dark-blue;
            background-color: #cdd1dc;

        }
    }

}