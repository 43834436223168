.common-goods-list {
    width: 100%;
    .goods-item {
        position: relative;
        display: block;
        text-align: left;
        margin: 10px 0;
        
        span, .quantity {
            display: inline-block;
        }
        span {
            width: 80%;
            
            strong {
                color: @grey;
                font-weight: @normal;
            }
            
            img, svg, .tooltip {
                display: inline-block;
                vertical-align: middle;
                margin-top: -2px;
                
                cursor: pointer;
                
                &:hover {
                    .result-card {
                        display: inline-table;
                        animation-delay: 0s;
                    }
                }
            }
        }
        .quantity {
            text-align: right;
            margin-left: -6px;
            width: 20%;
        }
        
        .result-card {
            position: absolute;
            
            top: 0;
            left: 50%;
            
            margin-top: 0;
            background-color: white;
            
            z-index: 100;
            
            transform: translate3d(-50%,-50%,0);
            display: none;
        }            
    } 
    
    .goods-title {
        display: block;
        margin: 20px 0 15px 0;
    }
    
    &.remove-goods {
        span {
            color: @red;
        }
    }  
}
.tooltip-container {
    overflow: visible !important;
    .tooltip {
        min-width: 130px; 
    }
}

@keyframes result-card-hovered {
    0% {
        opacity: 0;
        transform: translate3d(-50%,0,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}