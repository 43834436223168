.wallet {
    .modal-overlay {
        display: table;

        label {
            color: @dark-blue;
        }

        .button-group {
            margin-top: 45px;
            input, a ,span {
                padding: 12px 15px;
            }
        }
    }
}