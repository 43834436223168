.with-bottom-sticky-bar {
	padding-bottom: 180px;
}

.insurance-top-message {
	.message-2cols {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		margin-bottom: 0;
		div {
			width: 50%;
			margin: 0 20px;
			font-size: 16px;
			&:first-child {
				max-width: 436px;
			}
			&:last-child {
				max-width: 474px;
			}
			.picto {
				display: block;
				width: 64px;
				height: 64px;
				margin: 30px auto 10px;
			}
		}
	}
}
.insurance-content {
	padding: 10px 10px 10px;
	.insurance-content-wrapper {
		//background: rgb(230,224,206);
		//background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(230,224,206,1) 100%);
		//padding: 29px 12px 0;
		//padding: 0 12px 0;
		position: relative;
		#insurance-blocks {
			cursor: pointer;
		}
		.top-block {
			background-color: @dark-blue3;
			color: #fff;
			padding: 15px;
			&.on {
				.arrow {
					transform: rotate(90deg) scale(1.4) scaleX(-1.2);
				}
			}
		}
		h3 {
			//margin-bottom: 29px;
			margin-bottom: 0;
			cursor: pointer;
    		font-size: 22px;
			.arrow {
				display: inline-block;
				//transform: rotate(90deg) scale(1.4);
				transform: rotate(90deg) scale(1.4) scaleX(1.2);
				margin-left: 20px;
				font-weight: normal;
				cursor: pointer;
				transition: all .3s;
			}
			&.on {
				.arrow {
					transform: rotate(-90deg) scale(1.4);
				}
			}
		}
		.read-more {
			display: inline-block;
			position: absolute;
			//top: 26px;
			//top: 19px;
			top: 14px;
			right: 12px;
			//background-color: rgba(255,85,85,.2);
			background-color: rgba(232,138,56,.2);
			border-radius: 6px;
			//color: rgba(255,85,85,1);
			color: rgba(232,138,56,1);
			font-size: 16px;
			padding: 8px 12px;
			transition: .3s all;
			&:hover {
				//background-color: rgba(255,85,85,1);
				background-color: rgba(232,138,56,1);
				color: #fff;
			}
		}
		.blocks-wrapper {
			padding: 0 25px;
			margin-top: 25px;
			display: none;
			.button-main {
				margin-top: 35px;
				margin-bottom: 35px;
				font-weight: normal;
				text-transform: uppercase;
				font-size: 16px;
				.id-font2 {
					margin-right: 15px;
				}
			}
		}
		.block {
			display: flex;
			margin-bottom: 15px;
			border: 1px solid #E6E0CE;
			.img {
				background-color: #628DFF;
				width: 145px;
				padding: 25px 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.icon-wrapper {
					background-color: rgba(255,255,255,.1);
					border-radius: 50%;
					color: #fff;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 88px;
					height: 88px;
					.id-font3, .id-font4 {
						font-size: 40px;
					}
				}
			}
			.txt {
				width: calc(100% - 145px);
				background-color: #fff;
				font-size: 18px;
				padding: 27px 24px;
				line-height: 30px;
				text-align: left;
	    		.subtitle {
	    			margin-bottom: 8px;
	    			display: block;
	    		}
	    		li {
    				font-size: 16px;
	    		}
				ul {
					list-style: square;
					padding-left: 20px;
				}
				ol {
					margin-top: 0;
					li {
						padding-left: 10px;
					}
				}
			}
			&:last-child {
				margin-bottom: 10px;
			}
		}
	}
}
.insurance-content-popin {
	.insurance-content-wrapper {
		background-image: linear-gradient(to bottom, #e6e0ce -18%, #ffffff 100%);
		width: 96%;
		margin-left: 2%;
		.top-block {
			background-color: transparent;
			color: @dark-blue3;
		}
		.blocks-wrapper {
			margin-top: 0;
		}
	}
}
.insurance-info-popin {
	max-width: 85%;
	color: @dark-blue3;
	.infobulle {
		overflow: hidden;
	}
	.close {
		cursor: pointer;
	}
}

.insurance-sticky-buttons {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	//padding: 41px 46px;
	padding: 30px 50px;
	z-index: 10000;
	background-color: #fff;
	color: @dark-blue3;
	display: flex;
	justify-content: space-between;
	//align-items: flex-end;
	box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
	&.right {
		justify-content: flex-end;
	}
	.txt {
		max-width: 980px;
		font-size: 15px;
		p {
			margin-top: 0;
			margin-bottom: 15px;
		}
		ul {
			list-style: square;
		    padding-left: 17px;
			li {
				padding-left: 10px;
				margin-bottom: 18px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.btns {
		display: flex;
		align-items: center;
		a {
			text-decoration: underline;
			margin-right: 37px;
		}
		.button-main {
			text-transform: uppercase;
		}
		&.column {
			flex-direction: column;
			a {
				margin-right: 0;
				margin-top: 20px;
				order: 2;
			}
		}
	}
}

.authent-block {
	.block-title {
		text-transform: uppercase;
	    font-weight: bold;
	    font-size: 16px;
	    letter-spacing: 1px;
	    //margin-top: 18px;
	    //margin-top: 6px;
	    margin-top: 11px;
	    //margin-bottom: 30px;
	    margin-bottom: 20px;
	    .id-font2 {
	    	display: inline-block;
	    	transform: scale(1.2);
    		margin-right: 16px;
	    }
	}
	.warranty-insurance {
		float: right;
    	width: 627px;
    	background-color: #fff;
    	border-radius: 5px;
    	//margin-top: 15px;
    	margin-top: 14px;
    	padding-bottom: 16px !important;
    	.block {
    		border: 1px solid #E6E0CE;
    		padding: 26px 24px;
    		margin-top: 16px;
    		ul {
    			font-size: 16px;
    			list-style: square;
    			padding-left: 22px;
    			b {
    				margin-left: 29px;
    			}
    			li {
    				margin-bottom: 18px;
    				&:last-child {
    					margin-bottom: 0;
    				}
    			}
    		}
    	}
    	.block-subtitle {
    		display: inline-block;
    		font-weight: bold;
    		font-size: 17px;
    		margin-top: 0;
    		margin-bottom: 30px;
    		.id-font2 {
    			display: inline-block;
    			margin-right: 10px;
    		}
    	}
    	.info.orange {
    		margin-left: 7px;
    		.icon {
    			transform: translateY(-3px);
    		}
    	}
	}
}

// Popin
.certificat-insurance-info-popin {
	padding: 0;
	padding-top: 38px;
	outline: rgba(0,0,0,.4) 100vw solid;
}
.info-popin-hover {
	color: @dark-blue3;
	.top-block {
		text-align: center;
	}
	.infobulle-insurance {
		overflow: auto;
		.top-block {
			cursor: normal;
		}
		.blocks-wrapper {
			text-align: center;
	    	display: block !important;
	    }
	}
	img {
		max-width: 75%;
		display: block;
		margin: auto;
	}
}
.infobulle {
	p {
		font-size: 16px !important;
		margin: 6px 0;
	}
	ul {
		list-style: square;
		padding-left: 18px;
		line-height: 1.1;
		li {
			margin-bottom: 10px;
		}
	}
}
.insurance-modal {
	position: relative;
	width: 100%;
	min-width: 1104px;
	margin: 10px 0;
	padding: 30px 0 0 !important;

    .blocks-wrapper {
    	display: block !important;
    }
    .close-modal {
    	z-index: 10;
   			&:before, &:after {
   				display: none;
   			}
    }
    .step {
    	position: relative;
    	height: 90vh;
    	overflow: auto;
    }
    &.step-2 {
    	display: none;
    }
	.timeline-wrapper {
		width: 100%;
		text-align: center;
	}
	.timeline {
			display: flex;
			align-content: center;
			text-align: center;
			justify-content: center;
			> div {
				width: 243px;
				height: 4px;
				background-color: #E6E0CE;
				margin: 0 4px;
				&.complete {
					background-color: @dark-blue3;
				}
			}
	}
	.step-number {
		font-weight: bold;
		margin: 34px 0 9px;
		color: @dark-blue3;
	}
	h1 {
		font-weight: normal;
		font-size: 39px;
		color: @dark-blue3;
		margin-bottom: 12px;
	}
	p {
		font-size: 18px;
		color: @dark-blue3;
	}
	// step 1
	.underline-p {
		position: relative;
		margin-bottom: 56px;
		&:after {
			position: absolute;
			content: "";
			height: 4px;
			width: 34px;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			background-color: @dark-blue3;
		}
	}
	.steps-desc {
		display: flex;
		text-align: center;
		justify-content: space-between;
		width: 100%;
		max-width: 828px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
		.wrapper {
			width: calc(33% - 30px);
			color: @dark-blue3;
			font-size: 16px;
			p {
				font-size: 16px;
			}
			a {
				text-decoration: underline;
				color: rgba(98,141,255,1);
				display: inline;
			}
		}
		> div {
			text-align: center;
		}
		.number {
			background-color: rgba(98,141,255,.1);
			color: rgba(98,141,255,1);
			display: flex;
			align-items: center;
			align-content: center;
			font-weight: bold;
			display: inline-block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			line-height: 40px;
		}
		.desc {
			display: block !important;
		}
		.info {
		    border: #cbced5 1px solid;
		    color: #cbced5;
		    -webkit-transform: translateY(2px);
		    transform: translateY(2px);
		    width: 17px;
		    height: 17px;
		    text-transform: none;
		    margin-left: 10px;
		    margin-top: -1px;
		    &.orange {
			    //float: right !important;
			}
		    .icon {
		    	-webkit-transform: translateY(-2px);
		    	transform: translateY(-2px);
		    }
		}
	}
	.insurance-content {
		color: @dark-blue3;
		padding-bottom: 125px;
		h3 {
			cursor: initial;
			display: inline-block;
    		transform: translateY(-14px);
    		margin: 0 !important;
    		transform: none;
    	}
	}
	.popin-btns {
		//box-shadow: 0px -3px 24px 0px rgba(0, 0, 0, 0.05);
		box-shadow: 0px -3px 44px 0px rgba(0, 0, 0, 0.2);
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 17px 32px;
		display: flex;
		justify-content: flex-end;
		background-color: #fff;
		z-index: 9;
		.btns-wrapper {
			display: flex;
			align-content: center;
			align-items: center;
			a {
				text-decoration: underline;
			}
			.button-main {
				margin-left: 43px;
				text-transform: uppercase;
				font-weight: normal;
			}
		}
	}
	// step 2
	.form {
		width: 100%;
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
		label {
			margin-top: 34px;
		}
		.info {
			border: solid 1px #A9A9A9;
			transform: translateY(0px);
			margin-left: 10px;
			.icon {
				-webkit-transform: translateY(0px);
    			transform: translateY(0px);
				color: #A9A9A9;
			}
			.infobulle {
				margin-left: 120px;
				&:before, &:after {
					left: calc(100% - 392px) !important;
				}
			}
		}
		.upload-close {
			margin-bottom: 75px;
		}
		.upload-img {
			width: 100%;
			margin-top: 0;
		}
		.big-upload-btn {
			width: 100%;
			height: 187px;
			margin-top: 0;
			background-color: white;
			border: dashed 1px #CFCFE8;
			.id-font2 {
				color: #628DFF;
				margin: 47px 0 26px;
				font-size: 35px;
				display: block;
				transform: none;
			}
			.desc {
				font-size: 16px;
				color: @dark-blue3;
				display: block;
				font-weight: normal;
				margin-bottom: 0;
				.drop {
					display: inline;
				}
				.choose {
					text-decoration: underline;
					color: #628DFF;
					display: inline;
					transform: none;
					margin-left: 0;
				}
			}
			.formats {
				font-weight: normal;
				color: #40545D;
				opacity: .5;
				display: block;
				margin-bottom: 35px;
				font-size: 13px;
				margin-top: 10px;
			}
		}
		.checkbox-wrapper {
			margin-bottom: 160px;
			input {
				top: 0;
			}
			label {
				padding-left: 40px;
				font-size: 16px;
				text-transform: none;
				margin-top: 0;
				&:before {
					border-color: #E7E1CD;
				}
				&:before, &:after {
					transform: translateY(-20px);
				}
			}
		}
	}
}


.info.orange {
	transform: translateY(2px);
    width: 17px;
    height: 17px;
    text-transform: none;
    margin-left: 0;
    margin-top: -1px;
	border: 1px solid #e88a38 !important;
	float: none;
	.icon {
		color: #e88a38 !important;
	}
	&:hover {
		background-color: #e88a38;
		.icon {
			color: #fff !important;
		}
	}
}

@media only screen and (max-width: 1190px) {
	.authent-block {
		.warranty-insurance {
			order: 4;
			margin-top: 0;
			width: 100%;
			border-radius: 0;
			padding-bottom: 25px !important;
			li {
				font-size: 14px;
			}
		}
	}
}
@media only screen and (max-width: 1103px) {
	.insurance-modal {
		width: 100%;
		//min-width: 100%;
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
		margin: 0 !important;
		margin-left: 5px !important;
		.form {
			padding-right: 9px;
			padding-left: 9px;
		}
	}

}
@media only screen and (min-width: 769px) {
	.insurance-content {
		.block {
			.subtitle {
				.id-font3, .id-font4 {
					display: none;
				}
			}
		}
	}
	.insurance-modal {
		h1 {
			br {
				display: none;
			}
		}
		.steps-desc .desc {
			br {
				display: none;
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	/*.insurance-content-popin .insurance-content-wrapper .top-block {
	    background-color: #061A4F;
	    color: #fff;
	    padding: 15px;
	    margin-bottom: 15px;
	}*/
	.insurance-modal .form .info .infobulle {
	    margin-left: -190px;
	    max-width: calc(100vw - 50px);
	    left: 0px;
	    transform: translateY(-100%);
	    &:after, &:before {
	    	display: none;
	    }
	}
	.top-box {
		.message-2cols {
			display: block;
			> div {
				display: block;
				width: 100% !important;
				font-size: 15px;
				margin-left: 0;
				max-width: 100% !important;
			}
		}
	}
	.insurance-modal p {
		font-size: 15px;
		padding-left: 8px;
		padding-right: 8px;
	}
	.checkbox-wrapper {
		margin-bottom: 160px;
	}
	.insurance-modal {
		//max-height: calc(100vh - 36px);
		max-height: 90vh;
    	overflow: hidden;
		h1 {
			line-height: 1;
		}
		.close-modal {
			top: -2px;
   			right: 22px;
		}
		.timeline-wrapper {
			margin-top: 20px;
			.timeline {
				> div {
					width: 50%;
				}
			}
		}
		.form .upload-close {
		    margin-bottom: 45px;
		}
		.steps-desc {
			display: block !important;
			margin-bottom: 20px;
			.wrapper {
				width: 100%;
				padding: 20px;
				p {
					font-size: 15px;
				}
			}
			.desc {
				display: flex;
				flex-direction: column;
    			align-items: center;
				.info {
					order: 2;
					float: none;
					margin-top: 5px;
				}
			}
		}
		&.step-1 {
			padding: 20px;
			.insurance-content {
				margin-left: 0 !important;
				margin-right: 0 !important;
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}
		&.step-2 {
			padding: 20px;
			padding-bottom: 215px !important;
		}
		form {
			.big-upload-btn {
				.drop {
					display: none !important;
				}
			}
		}
	}
	.insurance-info-popin {
		max-width: 98%;
	}
	.insurance-content {
		padding: 10px 10px;
		margin-left: 10px !important;
	    margin-right: 10px !important;
	    width: calc(100% - 20px);
		.insurance-content-wrapper {
			text-align: center;
			//background: #fff;
			padding-top: 0;
			.top-block {
				padding: 15px;
			}
			h3 {
				font-size: 17px;
				margin-bottom: 5px;
			}
			.read-more {
				position: relative;
				top: 0;
				right: 0;
				//padding: 2px 20px;
				padding-left: 20px;
				padding-right: 20px;
				//margin-bottom: 20px;
				margin-top: 5px;
			}
			.blocks-wrapper {
				padding-left: 10px;
				padding-right: 10px;
			}
			.block {
				text-align: left;
				font-size: 15px;
				.img {
					display: none !important;
				}
				.txt {
					width: 100% !important;
					line-height: normal;
					padding: 23px 18px;
					.subtitle {
						font-size: 18px;
						margin-bottom: 13px;
						display: block;
						position: relative;
						padding-left: 30px;
						.id-font3, .id-font4 {
							display: inline-block;
							color: #628DFF;
							margin-right: 10px;
							transform: scale(1.1);
							position: absolute;
							top: 2px;
							left: 0;
						}
					}
					ul, ol, p {
						font-size: 15px;
						li {
							margin-bottom: 10px;
							font-size: 15px;
						}
					}
				}
			}
			.button-main {
				display: flex;
				font-size: 16px;
				width: 100%;
				align-items: center;
				.id-font2 {

				}
			}
		}
	}
	.insurance-sticky-buttons {
		//padding: 16px 18px;
		padding: 10px;
		flex-direction: column;
		.txt {
			//display: none;
			font-size: 14px;
			p {
				margin-top:5px;
				margin-bottom:10px;
			}
			ul {
				margin-bottom: 15px;
				li {
					margin-bottom: 10px;
					padding-left: 2px;
				}
			}
		}
		.btns {
			width: 100%;
			display: flex;
			flex-direction: column;
			text-align: center;
			.button-main {
				order: 1;
				width: 100%;
			    font-size: 11px;
			    font-weight: normal;
			}
			a {
				order: 2;
				width: 100%;
				margin-right: 0;
				margin-top: 15px !important;
				margin-bottom: 8px;
			    font-size: 13px;
			}
			.button-main, a {
			}
		}
	}
}


