.wallet-goods-list {
    .goods-list {
        width: 100%;
        .good-item {
            display: inline-block;
            vertical-align: top;
            
            width: 47.5%;
            margin-right: 2.5%;
            margin-top: 30px;
            
            .wallet-frame;
            
            &:nth-child(1), &:nth-child(2) {
                margin-top: 0;
            }
            
            &:nth-child(2n) {
                margin-left: -6px;
            }
            
            //We wanna center a good item if its the only child
            &:only-child {
                margin: auto;
                display: block;
            }
            
            .good-header {
                .brand-copy, .registered-by {
                    display: inline-block;
                    vertical-align: middle;
                    
                    width: 50%;
                    text-align: left;
                    
                    padding: 15px 15px;
                }
                
                .brand-copy {
                    text-transform: uppercase;
                    font-size: 0.8em;
                    font-weight: @bold;
                    letter-spacing: 1.8px;
                }
            }
            
            .good-body {
                text-align: center;
                margin-bottom: 25px;
                .good-illustration {
                    margin: 25px 0;
                }
            }
            .good-footer {
                background-color: #f0ead5;
                text-align: center;
                padding: 20px;
            }

            @media only screen and (max-width : 940px) {
                width: 100%;

                &:nth-child(2n) {
                    margin-left: 0;
                }

                +.good-item {
                    margin-top: 30px;
                }
            }
        }
    }
}

.wallet {
    
    .good-item {
        .registered-by {
            margin-left: -6px;
            text-align: right;
            
            font-size: 0.8em;
            color: @dark-blue;
            
            display: inline-block;
            vertical-align: middle;
            
            i {
                display: inline-block;
                vertical-align: middle;
                
                padding: 2px 3px;
                
                margin-right: 5px;
                margin-top: -2px;
                background-color: @dark-blue;
                
                font-style: normal;
                font-weight: @bold;
                color: white;
                border-radius: 2px;
            }
        }
        .good-details {
            .good-name, .good-collection, .good-serial {
                display: block;
            }
            
            .good-name {
                color: @black;
                font-weight: @light;
            }
            .good-collection {
                text-transform: uppercase;
                font-weight: @bold;
                letter-spacing: 1.6px;
                margin: 3px 0;
            }
            
            .good-serial {
                color: @grey;
                font-weight: @normal;
                font-size: 0.9em;
            }
            
        }
    }
}