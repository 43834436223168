.table {
	width: 100%;
	text-align: left;
	vertical-align: middle;
	border-collapse:collapse;
	margin: 50px 0 20px;
	width: calc(100% + 13px);
	margin-left: -13px;
	.name-col {
		width: 380px;
	}
	thead {
		color: #CDD1DC;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 13px;
		tr {
			height: 35px;
			.quantity-col {
				text-align: center;
			}
			th:first-child {
				padding-left: 13px;
			}
		}
	}
	tbody {
		color: @dark-blue3;
		th {
			padding-left: 13px;
		}
		tr {
			height: 46px;
			.quantity-col {
				text-align: center;
				span {
					background-color: #F3F3F3;
					border-radius: 14px;
					padding: 5px 12px;
					font-weight: bold;
					display: inline-block;
				}
			}
			&:nth-child(2n) {
				background-color: #F8FAFF;
				.quantity-col {
					span {
						background-color: #fff;
					}
				}
			}
		}
	}
}