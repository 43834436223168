.accept-transfert {
    .wallet-frame {
        margin-top: 50px;
        
        @media only screen and (max-width : 640px) {
            margin-top: 0;
        }
    }
}

.common-wrapper {
    &.wallet-wrapper {
        &.accept-transfert {
            @media only screen and (max-width : 640px) {
                margin-top: 60px !important;
            }
        }
    }
}

.accept-transfert-header {
    background-color: @dark-blue;
    color: white;
    
    padding: 30px 60px;
    
    h1 {
        font-size: 1.2em;
        line-height: 1.25em;
        letter-spacing: 1.7px;
        text-transform: uppercase;
    }
    p {
        font-size: 1em;
    }
    
    .button-group {
        text-align: left;
        
        .button-bold {
            color: white;
            
            +.button-bold {
                margin-left: 15px;
                
                @media only screen and (max-width : 640px) {
                    margin-left: 0;
                    margin-top: 15px !important;
                }
            }
        }
        .bordered {
            background-color: @dark-beige;
            color: @dark-blue;
        }
    }
    
    @media only screen and (max-width : 640px) {
        padding: 30px;
    }
}

.accept-transfert {
    .good-information {
        
        padding: 30px 60px 50px 60px;
        
        .brand-copy {
            text-align: left;
            text-transform: uppercase;
            font-size: 1.8em;
            font-weight: @bold;
            letter-spacing: 1.8px;
            margin-bottom: 0.3em;
        }
        .good-collection {
            text-transform: uppercase;
            font-weight: @bold;
            letter-spacing: 1.6px;
            margin: 0 0 0.5em 0;
        }
        .good-name {
            color: @black;
            font-weight: @light;
        }
        
        .ref {
            font-size: 0.8em;
            color: black;
        }
        
        .goodsid {
            display: block;
            width: 100%;
            padding: 10px 15px;
            border-radius: 2px;
            letter-spacing: 0.1em;
            background-color: #f2f3f6;
            text-align: center;
            color: @dark-blue;
            font-size: 1.5em;
            font-weight: @normal;
            margin: 0.5em 0;
        }
        
        .good-details {
            span {
                display: block;
            }
        }
        
        @media only screen and (max-width : 640px) {
            padding: 30px 30px 50px 30px;
        }
    }
    
    .block-photos {
        margin-top: 18px;
        .common-small-bold-grey {
            margin-bottom: 1em;
            display: block;
            color: @light-grey;
        }
    }
}

.info-icon {
    margin-left: 5px;
    margin-top: -3px;
    display: inline-block;
    vertical-align: middle;
    z-index: 11;
    cursor: pointer;

    &:hover {
        + .info-overlay {
            display: block;
        }
    }

    + .info-overlay {
        display: none;

        position: absolute;
        margin-top: 10px;

        padding: 25px 30px;
        border:solid 1px rgba(7, 27, 79, 0.2);
        background-color: white;
        text-align: center;
        z-index: 10;

        @media only screen and (max-width : 640px) {
            left: 10%;
            max-width: 80%;
        }

        .info-image {
            margin: 25px auto;
            width: 50%;

            img {
                max-width: 100%;
            }
        }
    }
}

.serial-number-information {
    max-width: 80%;
    margin: 50px auto;
    
    color: @dark-blue;
    
    .image-frame {
        width: 100%;
        padding: 25px;
        
        background-color: white; 
        border: 1px solid @dark-beige;
        
        text-align: center;
        
    }
    
    @media only screen and (max-width : 640px) {
        max-width: 100%;
    }
}
