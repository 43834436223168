.title-wrapper {
    display: block;
    padding: 0 0 50px 0;

    text-align: center;

    span {
        font-size: 2em;
        font-weight: @bold;
        color: @dark-blue;
        strong {
            font-weight: @light;
            display: block;
        }
    }

    img, svg {
        display: block;
        margin: auto;
    } 

    &.red {
        span {
            color: @red;

            strong {
                color: @dark-blue;
            }
        }
    }

}