.row {
    &.shadow-boxes {
        max-width: 70%;
        margin: auto;
        .box {
            background: white;
            width: 40%;
            margin: 10px;
            padding: 15px 35px;
            border-radius: 4px;

            p {
                font-weight: @bold;
                margin: 0 0 0.5rem 0;
            }

            svg {
                width: 45px;
                height: 45px;
            }

            transition: all 0.25s ease;

            &:hover {
                box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.1);
            }

        }

        @media only screen and (max-width: 640px) {
            max-width: 100%;

            .box {
                width: 100%;

                +.box {
                    margin-top: 15px !important;
                }
            }
        }
    }
}

.wallet-login {
    .wallet-login-list {
        margin-top: 35px;
        padding: 10px;
        li {
            padding-left: 50px;
            position: relative;
            margin-bottom: 30px;

            .common-font {
                color: @black;
            }
        }
        svg {
            position: absolute;
            left: 0;
            width: 40px;
            height: 40px;

            top: 50%;
            transform: translate3d(0,-50%,0);

            &.small {
                width: 30px;
                height: 30px;
                margin-left: 5px;
            }
        }
    }

    .wallet-login-header {
        margin-top: 75px;
        p {
            color: @black !important;
            line-height: 25px;
        }
        img {
            display: inline-block !important;
            vertical-align: middle !important;
            margin: 0 10px 0 0 !important;

            max-width: 100px !important;
        }
    }
}


.wallet-transfert {
    .wallet-information-list {
        padding-left: 0 !important;
        li {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            i {
                text-align: left;
                color: @grey;
            }

            strong {
                font-weight: @normal;
                position: relative;
            }
        }
    }
}
@media screen and (max-width: 730px) {
    .block-guarantee {
        li {
            display: block;
            margin-bottom: 15px;
            i, strong {
                width: auto;
                display: block;
                text-align: left;
            }
            strong {
                position: initial;
                margin: 0;
            }
        }
    }
}

.wallet-list-wrapper {
    max-width: 1040px;
    width: 100%;
    overflow: auto;
}
@media screen and (max-width: 664px) {
    .main-header.wallet-header .profile-wrapper .common-squared-icon .notification-overlay {
        left: 0;
    }
}


