.results-wrapper {
    margin-bottom: 100px;
    .results-number {
        .common-appearance;
    }
}

.result-card {
    display: table;
    width: 100%;
    margin: 15px 0;
    border:solid 1px rgba(7, 27, 79, 0.2);
    border-radius: 2px;
    
    .common-appearance;
    
    .card-image, .card-body {
        display: table-cell;
        vertical-align: middle;
    }
    
    .card-image {
        max-width:150px;
        min-width: 150px;
        padding-left: 15px;
    }
    .card-body {
        
        padding: 15px 0 0 15px;
        
        .card-title {
            color: @black;
            font-weight: @bold;
            line-height: 1.29em;
            text-transform: uppercase;
            
            strong {
                text-transform: initial;
            }
        }
        .card-resume {
            color: @black;
            font-weight: @normal;
            padding-right: 15px;
        }
        
        .reference {
            color: @grey;
            display: block;
            margin: 15px 0;
        }
        
        .goodsid {
            display: block;
            width: 100%;
            padding: 10px 15px;
            border-radius: 2px;
            letter-spacing: 0.1em;
            
            background-color: @beige;
            text-align: center;
            
            color: @black;
            font-size: 1.5em;
            font-weight: @light;
            
        }
    }
    
    .card-arrow {
        position: absolute;
        top: 25px;
        right: 15px;
        
        transform: translate3d(-50%,-50%,0);
        opacity: 0;
        transition: all 0.25s cubic-bezier(.645, .045, .355, 1);

        * {
            stroke-width: 2;
        }
    }
    
    transition: box-shadow 0.25s ease;
    cursor: pointer;
    &:hover {
        box-shadow: 0 6px 20px rgba(7, 27, 79, 0.2);

        .card-arrow {
            opacity: 1;
            transform:translate3d(0,-50%,0);
        }
    }
    
    @media only screen and (max-width : 480px) {
        .card-image {
            position: absolute;
            min-width: auto;
            max-width: 80px;
            top: 20%;
        }
        .card-body {
            display: block;
            padding: 0;
            
            .card-resume, .reference, .card-title {
                padding-left: 40%;
                font-size: 0.9em;
            }
            .card-title {
                display: block;
                padding-top: 15px;
            }
            .goodsid {
                font-size: 1.2em;
            }
        }
    }
}